import React, { FC, ReactElement, useEffect } from 'react'
import { useStyles } from './styles'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'
import { Button } from '@/components/controls/Button'
import { FormattedMessage } from 'react-intl'
import ClipLoader from 'react-spinners/ClipLoader'
import {
    actionsMagicLinks,
    getErrorOccured,
    getErrorText,
    getLoading,
    getRedirectUrl,
    getSuccess,
} from '@/core/store/magicLinks'
import { actionsAuth, getUserProfile } from '@/core/store/auth'
import { UserRole } from '@/core/types/auth/authTypes'

const RegistrationCommitContainer: FC = (): ReactElement => {
    const styles = useStyles()
    const translate = useTranslate()
    const history = useHistory()
    const errorOccured = useSelector(getErrorOccured)
    const errorText = useSelector(getErrorText)
    const redirectUrl = useSelector(getRedirectUrl)
    const loading = useSelector(getLoading)
    const success = useSelector(getSuccess)
    const userProfile = useSelector(getUserProfile)
    const dispatch = useDispatch()
    const { token } = useParams<{ token: string }>()

    useEffect(() => {
        dispatch(actionsMagicLinks.verifyRegistrationLink(token))
    }, [])

    useEffect(() => {
        if (success) {
            dispatch(actionsAuth.getUserProfile())
        }
    }, [success])

    useEffect(() => {
        console.log(userProfile)
        if (userProfile !== null && userProfile.role !== UserRole.Default) {
            history.push(redirectUrl || '/')
        }
    }, [userProfile, redirectUrl])

    // const sendLinkAgainClicked = () => {
    //     console.log('Send link again')
    // }

    const continueWithoutLoginClicked = () => {
        history.push('/')
    }

    return (
        <div className={styles.centerDiv}>
            {loading && (
                <>
                    <Typography variant={'h1'} align={'center'}>
                        <FormattedMessage id="LinkVeryfyingText" />
                    </Typography>
                    <div className={styles.activityIndicator}>
                        <ClipLoader color={'white'} />
                    </div>
                </>
            )}
            {errorOccured && (
                <>
                    <Typography variant={'h1'} align={'center'}>
                        {errorText}
                    </Typography>
                    {/* <Button
                        title={translate('#SendLinkAgainText')}
                        style={{ marginTop: 10 }}
                        onClick={sendLinkAgainClicked}
                    /> */}
                    <Button
                        title={translate('#ContinueWithoutRegistrationText')}
                        style={{ marginTop: 10 }}
                        onClick={continueWithoutLoginClicked}
                    />
                </>
            )}
        </div>
    )
}

export default RegistrationCommitContainer
