import React, { useMemo } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { actionsGeneral } from '@/core/store/general'
import { ModalTypes } from '@/core/constants/modal'
import { useStyles } from '../styles'
import { useDispatch } from 'react-redux'

const Marker = ({ slugName, label, generalColor, color, isMain, isOriginMarker }: any) => {
    const dispatch = useDispatch()

    const styles = useStyles()
    const history = useHistory()

    const colorMapper = useMemo(() => (isOriginMarker ? '#9B59B6' : generalColor || color), [])

    return (
        <div
            className={cx(styles.marker, { [styles.originMarker]: isOriginMarker })}
            style={{
                border: `5px solid ${colorMapper}`,
            }}
            onClick={() => {
                if (isMain) {
                    dispatch(actionsGeneral.showModal(ModalTypes.STORY_MODAL, slugName))
                } else if (!isOriginMarker) {
                    history.push(`/story/${slugName}`)
                }
            }}
        >
            {label || (
                <span
                    className={cx(styles.markerDot, { [styles.originDot]: isOriginMarker })}
                    style={{
                        backgroundColor: `${colorMapper}`,
                    }}
                />
            )}
            <div
                className={styles.arrowDown}
                style={{
                    borderTop: `15px solid ${colorMapper}`,
                }}
            />
        </div>
    )
}

export default Marker
