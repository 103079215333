import { defaultUserPosition } from '@/core/constants/googleMaps'

export const requestGeolocation = (cb: any) =>
    navigator.geolocation.getCurrentPosition(
        (pos: any) => {
            const origin = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
                timestamp: pos.timestamp,
            }
            cb(origin)
        },
        (error: GeolocationPositionError) => {
            console.log('error: ', error)
            cb(defaultUserPosition)
        },
        { enableHighAccuracy: true, maximumAge: 0 },
    )
