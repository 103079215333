import { ReturnAnyActions } from '../../types/types'
import { actionsInterests } from './actions'

export const initialState = {
    interests: [] as Array<any>,
    loading: false,
    error: null as string | null,
}

export type State = typeof initialState

export type GeneralActions = ReturnAnyActions<typeof actionsInterests>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/SET_INTERESTS_DATA':
            return {
                ...state,
                interests: action.payload,
            }
        case '@/TOGGLE_INTERESTS':
            return {
                ...state,
                interests: state.interests.map((item: any) => {
                    return {
                        ...item,
                        subscribed: item.id === action.payload ? !item.subscribed : item.subscribed,
                    }
                }),
            }
        case '@/START_LOADING_INTERESTS_DATA':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_LOADING_INTERESTS_DATA':
            return {
                ...state,
                loading: false,
            }
        case '@/SET_INTERESTS_DATA_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}
