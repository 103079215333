import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'

import { Dialog, IconButton, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Spinner from '@/components/controls/Spinner'
import CloseIcon from '@/assets/close.svg'
import CollectionLinks from '@/components/CollectionLinks'

import { actionsStories, getStory } from '@/core/store/stories'

import { useStyles } from './styles'
import TagsWrapper from '../TagsWrapper'

const StoryModal = () => {
    const { hideModal, props } = useGlobalModalContext()
    const styles = useStyles()
    const dispatch = useDispatch()
    const story: any = useSelector(getStory)

    useEffect(() => {
        dispatch(actionsStories.getStory(props))
        return () => {
            dispatch(actionsStories.clearStory())
        }
    }, [])

    return (
        <Dialog
            onClose={hideModal}
            open={true}
            maxWidth="sm"
            scroll="body"
            disableEnforceFocus
            disableBackdropClick
            disablePortal={true}
            classes={{
                root: styles.root,
            }}
            BackdropProps={{
                classes: { root: styles.root },
            }}
            style={{ position: 'absolute' }}
            aria-labelledby="customized-dialog-title"
            aria-describedby="customized-dialog-title"
        >
            <div className={styles.headerContainer}>
                {story && <h1>{story.name}</h1>}
                <IconButton aria-label="close" className={styles.closeButton} onClick={hideModal}>
                    <img src={CloseIcon} alt="close" />
                </IconButton>
            </div>
            {story ? (
                <>
                    <div className={styles.linksContaier}>
                        {!!story.collections?.length && (
                            <CollectionLinks collections={story.collections} onClick={hideModal} />
                        )}
                        {!!story.interests?.length && (
                            <TagsWrapper tags={story.interests} onClick={hideModal} />
                        )}
                    </div>
                    <div className={styles.buttonContainer}>
                        <Link to={`/story/${props}`}>
                            <Button className={styles.openButton} onClick={hideModal}>
                                <FormattedMessage id="OpenStoryText" />
                            </Button>
                        </Link>
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </Dialog>
    )
}

export default StoryModal
