import { ReturnAnyActions } from '../../types/types'
import { actionsCollection } from './actions'
import { reducerLikesAndSubscriptionMapper } from '@/core/helpers/likesMapper'
import { reducerSubscribeMapper } from '@/core/helpers/subscribeMapper'
import { newCollectionsPreviewTypes } from '@/core/types/CollectionsTypes'

export const initialState = {
    newsCollections: {
        loading: false as boolean,
        loaded: false as boolean,
        error: null as string | null,
        data: [] as Array<newCollectionsPreviewTypes>,
    },
    nearestCollections: {
        loading: false as boolean,
        loaded: false as boolean,
        error: null as string | null,
        data: [] as Array<newCollectionsPreviewTypes>,
    },
    authorCollections: {
        loading: false as boolean,
        loaded: false as boolean,
        error: null as string | null,
        data: [] as Array<newCollectionsPreviewTypes>,
    },
    relatedCollections: {
        loading: false as boolean,
        loaded: false as boolean,
        error: null as string | null,
        data: [] as Array<newCollectionsPreviewTypes>,
    },
    collection: null as newCollectionsPreviewTypes | null,
    collectionFetchError: null as string | null,
}

export type State = typeof initialState

export type GeneralActions = ReturnAnyActions<typeof actionsCollection>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/START_COLLECTION_LOADING':
            return {
                ...state,
                [action.payload.collectionName]: {
                    ...state[action.payload.collectionName],
                    loading: true,
                },
            }
        case '@/STOP_COLLECTION_LOADING':
            return {
                ...state,
                [action.payload.collectionName]: {
                    ...state[action.payload.collectionName],
                    loading: false,
                },
            }
        case '@/ERROR_COLLECTION_LOADING':
            return {
                ...state,
                [action.payload.collectionName]: {
                    ...state[action.payload.collectionName],
                    error: action.payload.error,
                },
            }
        case '@/SET_COLLECTIONS':
            return {
                ...state,
                [action.payload.collectionName]: {
                    ...state[action.payload.collectionName],
                    data: [...state[action.payload.collectionName].data, ...action.payload.data],
                    loaded: !action.payload.data.length,
                },
            }

        case '@/SET_COLLECTION':
            return {
                ...state,
                collection: action.payload,
            }
        case '@/ERROR_FETCHING_SINGLE_COLLECTION':
            return {
                ...state,
                collectionFetchError: action.payload,
            }
        case '@/SET_LIKED_COLLECTION':
            return {
                ...state,
                newsCollections: reducerLikesAndSubscriptionMapper(
                    state.newsCollections,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                nearestCollections: reducerLikesAndSubscriptionMapper(
                    state.nearestCollections,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                authorCollections: reducerLikesAndSubscriptionMapper(
                    state.authorCollections,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                relatedCollections: reducerLikesAndSubscriptionMapper(
                    state.relatedCollections,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                collection:
                    state?.collection?.id === action.payload.id
                        ? {
                              ...state.collection,
                              liked: action.payload.like,
                          }
                        : state.collection,
            }
        case '@/SET_SUBSCRIBED_AUTHOR':
            return {
                ...state,
                newsCollections: reducerSubscribeMapper(
                    state?.newsCollections,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                nearestCollections: reducerSubscribeMapper(
                    state?.nearestCollections,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                authorCollections: reducerSubscribeMapper(
                    state?.authorCollections,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                relatedCollections: reducerSubscribeMapper(
                    state?.relatedCollections,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                collection:
                    state?.collection?.author?.id === action.payload.id
                        ? {
                              ...state.collection,
                              author: {
                                  ...state.collection.author,
                                  subscribed: action.payload.subscribe,
                              },
                          }
                        : state.collection,
            }

        case '@/SET_BOUGHT_COLLECTION':
            return {
                ...state,

                newsCollections: reducerLikesAndSubscriptionMapper(
                    state.newsCollections,
                    action.payload.id,
                    true,
                    'purchased',
                ),
                nearestCollections: reducerLikesAndSubscriptionMapper(
                    state.nearestCollections,
                    action.payload.id,
                    true,
                    'purchased',
                ),
                authorCollections: reducerLikesAndSubscriptionMapper(
                    state.authorCollections,
                    action.payload.id,
                    true,
                    'purchased',
                ),
                relatedCollections: reducerLikesAndSubscriptionMapper(
                    state.relatedCollections,
                    action.payload.id,
                    true,
                    'purchased',
                ),
                collection: state?.collection
                    ? {
                          ...state.collection,
                          purchased: true,
                      }
                    : state.collection,
            }
        case '@/CLEAR_COLLECTIONS_DATA':
            return {
                ...state,
                [action.payload]: {
                    ...initialState[action.payload],
                },
            }
        case '@/CLEAR_COLLECTION':
            return {
                ...state,
                collection: null,
            }
        default:
            return state
    }
}
