import colors from '@/core/theme/colors'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        darkContainer: {
            display: 'flex',
            flexDirection: 'column',
            background: colors.darkGrey,
            flexWrap: 'wrap',
            color: colors.white,
            width: '100%',
            marginBottom: '24px',
            padding: '24px',
            borderRadius: '24px',
            boxShadow: '0px 4px 13px rgb(0 0 0 / 8%)',
            gap: '16px',
        },
        noMargin: {
            margin: 0,
        },
        userInfoWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
        },
        photoUser: {
            width: '80px',
            height: '80px',
            borderRadius: '50%',
        },
        authorInfoWrapper: {
            display: 'flex',
            gap: '16px',
        },
        authorAdditionalInfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        link: {
            color: colors.white,
        },
    }),
)
