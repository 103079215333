import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Carousel } from 'react-responsive-carousel'
import { Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { WizardContentPropsType } from './types'
import SlidePicture1 from '@/assets/startpage_slide1.gif'
import SlidePicture2 from '@/assets/startpage_slide2.gif'
import SlidePicture3 from '@/assets/startpage_slide3.gif'
import SlidePicture4 from '@/assets/startpage_slide4.gif'

const WizardContent: FC<WizardContentPropsType> = (props: WizardContentPropsType) => {
    const styles = useStyles()
    const [currentSlide, setCurrentSlide] = useState(0)

    const next = () => {
        currentSlide < 3
            ? setCurrentSlide(prevState => prevState + 1)
            : props.handleSkipClick?.call(this)
    }
    const onCarouselChange = (index: any) => {
        setCurrentSlide(index)
    }

    return (
        <>
            <Carousel
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                autoPlay={false}
                selectedItem={currentSlide}
                onChange={onCarouselChange}
            >
                <div>
                    <img style={{ objectFit: 'contain' }} src={SlidePicture1} />
                    <Typography variant="h1" className={styles.carouselText}>
                        <FormattedMessage id="StartPageWizard1Text" />
                    </Typography>
                </div>
                <div>
                    <img src={SlidePicture2} />
                    <Typography variant="h1" className={styles.carouselText}>
                        <FormattedMessage id="StartPageWizard2Text" />
                    </Typography>
                </div>
                <div>
                    <img src={SlidePicture3} />
                    <Typography variant="h1" className={styles.carouselText}>
                        <FormattedMessage id="StartPageWizard3Text" />
                    </Typography>
                </div>
                <div>
                    <img src={SlidePicture4} />
                    <Typography variant="h1" className={styles.carouselText}>
                        <FormattedMessage id="StartPageWizard4Text" />
                    </Typography>
                </div>
            </Carousel>
            <div style={{ width: '100%', paddingLeft: 24, paddingRight: 24, marginBottom: 16 }}>
                <div
                    style={{
                        width: '35%',
                        display: 'inline-block',
                        float: 'left',
                        textAlign: 'left',
                    }}
                    onClick={props.handleSkipClick}
                >
                    <Typography variant="body1" className={styles.greenLink}>
                        <FormattedMessage id="SkipText" />
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'inline-block',
                        width: '30%',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                >
                    <ul className={styles.carouselIndicatorOl}>
                        <li
                            className={
                                currentSlide === 0
                                    ? styles.carouselIndicatorSelected
                                    : styles.carouselIndicator
                            }
                        />
                        <li
                            className={
                                currentSlide === 1
                                    ? styles.carouselIndicatorSelected
                                    : styles.carouselIndicator
                            }
                        />
                        <li
                            className={
                                currentSlide === 2
                                    ? styles.carouselIndicatorSelected
                                    : styles.carouselIndicator
                            }
                        />
                        <li
                            className={
                                currentSlide === 3
                                    ? styles.carouselIndicatorSelected
                                    : styles.carouselIndicator
                            }
                        />
                    </ul>
                </div>
                <div
                    style={{
                        width: '35%',
                        display: 'inline-block',
                        float: 'right',
                        textAlign: 'right',
                    }}
                    onClick={next}
                >
                    <Typography variant="body1" className={styles.greenLink}>
                        <FormattedMessage id="NextText" />
                    </Typography>
                </div>
            </div>
            <Typography
                variant="body1"
                className={styles.greenLink}
                style={{
                    width: '100%',
                    marginTop: 16,
                    marginBottom: 8,
                    textAlign: 'center',
                    bottom: 0,
                }}
                onClick={props.handleAlreadyRegisteredClick}
            >
                <FormattedMessage id="AlreadyRegisteredText" />
            </Typography>
        </>
    )
}

export { WizardContent }
