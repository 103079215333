import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getAuthorState = (state: AppState): typeof initialState => state.authorReducer

export const getAuthorData = createSelector(getAuthorState, (state: any) => state.authorData)
export const getAuthorDataFetchError = createSelector(getAuthorState, (state: any) => state.error)
export const getStartPageAuthors = createSelector(
    getAuthorState,
    (state: any) => state.startPageAuthors,
)
