import React, {
    FC,
    ChangeEvent,
    KeyboardEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '@/core/hooks/useTranslate'

import Search from '@material-ui/icons/Search'

import { getDataLanguagesCodes } from '@/core/store/language'
import { actionsSearch, getSearchValue } from '@/core/store/search'
import { actionsMap } from '@/core/store/map'

import { useStyles } from './styles'

const SearchForm: FC = (): ReactElement => {
    const translate = useTranslate()
    const dispatch = useDispatch()
    const inputRef = useRef<any>(null)
    const location = useLocation()
    const history = useHistory()
    const styles = useStyles()
    const isSearchPage = useRouteMatch('/search')?.isExact

    const [inputValue, setInputValue] = useState('')

    const languages = useSelector(getDataLanguagesCodes)
    const searchValue = useSelector(getSearchValue)

    useEffect(() => {
        const querySearchValue =
            new URLSearchParams(decodeURIComponent(location.search)).get('q') || ''

        setInputValue(querySearchValue)

        if (querySearchValue !== searchValue && isSearchPage) {
            dispatch(
                actionsSearch.getSearchDataAsync({
                    languages,
                    limit: 10,
                    page: 0,
                    searchFragment: querySearchValue,
                }),
            )
        }
    }, [location.search, isSearchPage])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSearchValue = e.target.value

        setInputValue(newSearchValue)
    }

    const performSearch = () => {
        if (!inputValue) return
        const query = new URLSearchParams({ q: encodeURIComponent(inputValue) })

        if (inputValue !== searchValue) {
            dispatch(actionsSearch.clearSearchDataApp())
            dispatch(actionsMap.setMarkersByIds({ target: 'searchResultMarkers', data: [] }))
        }
        history.push(`/search?${query.toString()}`)
        inputRef?.current?.blur()
    }

    return (
        <div className={styles.searchContainer}>
            <input
                ref={inputRef}
                className={styles.searchInput}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                    e.keyCode === 13 && performSearch()
                }
                placeholder={translate('#SearchRevealPlaceholder')}
            />
            <Search className={styles.searchIcon} onClick={performSearch} />
        </div>
    )
}

export default SearchForm
