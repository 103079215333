import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    clusterMarker: {
        display: 'flex',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        position: 'relative',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        color: '#000',
        transform: 'translate(-50%, -100%)',
    },
    marker: {
        display: 'flex',
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        position: 'absolute',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        color: '#000',
        transform: 'translate(-50%, -100%)',
    },
    originMarker: {
        width: '30px',
        height: '30px',
        zIndex: 1,
    },
    markerDot: {
        width: '2px',
        height: '2px',
        borderRadius: '50%',
    },
    originDot: {
        width: '2px',
        height: '2px',
    },
    arrowDown: {
        width: 0,
        height: 0,
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        position: 'absolute',
        bottom: '-15px',
    },
}))
