export const actionsFavourites = {
    // FETCH Favourites Stories
    getFavouritesStories: (data: any) =>
        ({
            type: '@/GET_FAVOURITES_STORIES',
            payload: data,
        } as const),
    startLoadingStories: () =>
        ({
            type: '@/START_LOADING_FAVOURITES_STORIES',
        } as const),

    stopLoadingStories: () =>
        ({
            type: '@/STOP_LOADING_FAVOURITES_STORIES',
        } as const),
    setStoriesError: (payload: any) =>
        ({
            type: '@/SET_FAVOURITES_STORIES_ERROR',
            payload,
        } as const),
    setFavouritesStories: (data: any) =>
        ({
            type: '@/SET_FAVOURITES_STORIES',
            payload: data,
        } as const),

    // FETCH Favourites Collections
    getFavouritesCollections: (data: any) =>
        ({
            type: '@/GET_FAVOURITES_COLLECTIONS',
            payload: data,
        } as const),
    startLoadingCollections: () =>
        ({
            type: '@/START_LOADING_FAVOURITES_COLLECTIONS',
        } as const),

    stopLoadingCollections: () =>
        ({
            type: '@/STOP_LOADING_FAVOURITES_COLLECTIONS',
        } as const),
    setCollectionsError: (payload: any) =>
        ({
            type: '@/SET_FAVOURITES_COLLECTIONS_ERROR',
            payload,
        } as const),
    setFavouritesCollections: (data: any) =>
        ({
            type: '@/SET_FAVOURITES_COLLECTIONS',
            payload: data,
        } as const),

    setFavouritesLikedCollection: (id: number, like: boolean) =>
        ({
            type: '@/SET_FAVOURITES_LIKED_COLLECTION',
            payload: { id, like },
        } as const),
    setFavouritesLikedStory: (id: number, like: boolean) =>
        ({
            type: '@/SET_FAVOURITES_LIKED_STORY',
            payload: { id, like },
        } as const),
    setSubscribedAuthor: (id: string, subscribe: boolean) =>
        ({
            type: '@/SET_SUBSCRIBED_AUTHOR',
            payload: { id, subscribe },
        } as const),
}
