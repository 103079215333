import colors from '@/core/theme/colors'
import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        header: {
            color: colors.secondaryText,
        },
    }),
)
