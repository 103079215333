import colors from '@/core/theme/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
    loader: {
        display: 'flex',
        width: spacing(50),
        height: spacing(50),
        position: 'absolute',
        left: '50%',
        top: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1px',
        transform: 'translateX(-50%) translateY(-50%)',
        zIndex: 1,
        '&::after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            margin: '1px',
            borderRadius: '50%',
            border: `${spacing(1)}px solid black`,
            borderColor: `${colors.deepGreen} transparent ${colors.deepGreen} transparent`,
            animation: `$lds-dual-ring 1.2s linear infinite`,
        },
    },
    '@keyframes lds-dual-ring': {
        '0%': {
            transform: 'rotate(0deg) scale(1)',
        },
        '50%': {
            transform: 'rotate(180deg) scale(0.75)',
        },
        '100%': {
            transform: 'rotate(360deg) scale(1)',
        },
    },
}))
