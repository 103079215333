import { SearchResponseTypes, StateSearchItemType } from '@/core/types/types'

type GroupedSearchResultType = {
    collections: Array<SearchResponseTypes<StateSearchItemType>>
    stories: Array<SearchResponseTypes<StateSearchItemType>>
}

export const groupSearchResultData = (
    searchData: Array<SearchResponseTypes<StateSearchItemType>>,
): GroupedSearchResultType =>
    searchData.reduce(
        (acc: GroupedSearchResultType, item: SearchResponseTypes<StateSearchItemType>) => {
            if (item.searchItemType.toLocaleLowerCase() === 'collection') acc.collections.push(item)
            if (item.searchItemType.toLocaleLowerCase() === 'story') acc.stories.push(item)

            return acc
        },
        {
            collections: [] as Array<SearchResponseTypes<StateSearchItemType>>,
            stories: [] as Array<SearchResponseTypes<StateSearchItemType>>,
        },
    )
