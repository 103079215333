import React, { FC } from 'react'
import { useStyles } from './styles'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { CollectionType } from '@/core/types/stories/storiesTypes'

interface CollectionLinksProps {
    collections: CollectionType[]
    darkMode?: boolean
    onClick?: () => void
}

const CollectionLinks: FC<CollectionLinksProps> = (props: CollectionLinksProps) => {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            {props.collections.map((item: any) => {
                return (
                    <Link
                        key={item.id}
                        to={`/collection/${item.id}`}
                        className={clsx(styles.tagsButton, styles.collectionButton, {
                            [styles.darkMode]: props.darkMode,
                        })}
                        onClick={props.onClick}
                    >
                        {item.name}
                    </Link>
                )
            })}
        </div>
    )
}

export default CollectionLinks
