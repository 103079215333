import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router as ReactRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import ReactGA from 'react-ga'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import GlobalModal from '@/containers/GlobalModal'
import Router from '@/Router'

import { actionsAuth } from '@/core/store/auth'
import { actionsInterests } from '@/core/store/interests'
import { actionsMap } from '@/core/store/map'
import {
    actionsLanguage,
    getInterfaceLanguageCode,
    getInterfaceLanguageData,
} from '@/core/store/language'

import { requestGeolocation } from '@/core/helpers/requestGeolocation'
import { defaultUserPosition } from '@/core/constants/googleMaps'

import { materialTheme } from '@/core/theme/materialTheme'

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL })

const AppContainer = (): ReactElement => {
    const dispatch = useDispatch()
    const activeLanguageCode = useSelector(getInterfaceLanguageCode)
    const activeLanguageData = useSelector(getInterfaceLanguageData)

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
        dispatch(actionsAuth.checkToken())
        dispatch(actionsLanguage.initLanguage())
        dispatch(actionsInterests.getInterestsData())

        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(result => {
                if (result.state === 'granted') {
                    requestGeolocation((origin: any) =>
                        dispatch(actionsMap.setUserPosition(origin)),
                    )
                }
                if (result.state === 'denied') {
                    dispatch(actionsMap.setUserPosition(defaultUserPosition))
                }
                if (result.state === 'prompt') {
                    dispatch(actionsMap.setUserPositionRequested(false))
                }
            })
        }
    }, [])

    return (
        <ReactRouter history={history}>
            <IntlProvider
                messages={activeLanguageData}
                locale={activeLanguageCode}
                defaultLocale="en"
            >
                <ThemeProvider theme={materialTheme}>
                    <GlobalModal>
                        <CssBaseline />
                        <Router />
                    </GlobalModal>
                </ThemeProvider>
            </IntlProvider>
        </ReactRouter>
    )
}

export default AppContainer
