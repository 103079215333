import React, { Component, ErrorInfo, ReactNode } from 'react'

import ReportIcon from '@material-ui/icons/Report'

interface Props {
    children?: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Catched error:', error, errorInfo)
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '30px 16px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#FF5757',
                            fontSize: '150px',
                        }}
                    >
                        <ReportIcon fontSize="inherit" />
                    </div>
                    <h1 style={{ color: '#1A1D1E', marginBottom: 0 }}>Something went wrong.</h1>
                    <h2 style={{ color: '#1A1D1E', margin: 0 }}>We are working on the issue.</h2>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
