import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { actionsFavourites, getFavouritesStories } from '@/core/store/favourites'
import Spinner from '@/components/controls/Spinner'
import StoriesList from '@/components/StoriesList'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const StoriesTab = () => {
    const dispatch = useDispatch()
    const storiesData = useSelector(getFavouritesStories, shallowEqual)

    useEffect(() => {
        dispatch(actionsFavourites.getFavouritesStories({ offset: 0, limit: 999 }))
    }, [])

    return (
        <div>
            {!storiesData && <Spinner />}
            {storiesData && storiesData.length === 0 && (
                <Typography>
                    <FormattedMessage id="NoFavouritesText" />
                </Typography>
            )}
            {storiesData && storiesData.length > 0 && <StoriesList stories={storiesData} />}
        </div>
    )
}

export default StoriesTab
