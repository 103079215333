import React, { FC, ReactElement } from 'react'
import NotificationsIcon from '@material-ui/icons/Notifications'

import NoPhoto from '@/assets/no_photo.png'
import { CheckBox, useCheckbox } from '@/components/controls/Checkbox'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'

import { useStyles } from './styles'

type AuthorFollowCardProps = {
    photoUrl: string
    authorName: string
    id: string
    subscribed: boolean
    hideModal: () => void
}

const AuthorFollowCard: FC<AuthorFollowCardProps> = ({
    photoUrl,
    authorName,
    id,
    subscribed,
    hideModal,
}: AuthorFollowCardProps): ReactElement => {
    const styles = useStyles()
    const subscribe = useCheckbox(subscribed)

    return (
        <div className={styles.root}>
            <Link
                to={`/author/${id}`}
                onClick={() => {
                    hideModal()
                }}
                className={styles.link}
            >
                <div className={styles.authorBlock}>
                    <img src={photoUrl || NoPhoto} alt="photo" className={styles.photoUser} />
                    <Typography variant="body1" color="primary">
                        {authorName}
                    </Typography>
                </div>
            </Link>

            <CheckBox
                value={subscribe.Value}
                onChange={subscribe.handleCheckboxChange}
                checked={subscribe.Value}
                icon={<NotificationsIcon />}
                checkedIcon={<NotificationsIcon />}
                color="secondary"
            />
        </div>
    )
}

export { AuthorFollowCard }
