import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import colors from '@/core/theme/colors'

type Props = {
    image: string
    checked?: boolean
    subscribed?: boolean
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
    createStyles({
        tag: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '96px',
            minWidth: '96px',
            height: '96px',
            backgroundImage: ({ image, checked }) =>
                `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${
                    checked
                        ? 'linear-gradient(50.21deg, rgba(63, 220, 127, 0.8) 13.74%, rgba(63, 220, 127, 0.19) 88.22%),'
                        : ''
                } url("${image}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '24px',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '16px',
            color: colors.white,
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            border: ({ subscribed }) => (subscribed ? `4px solid ${colors.green}` : 'none'),
            '&:hover': {
                backgroundImage: ({ image }) =>
                    `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(50.21deg, rgba(63, 220, 127, 0.8) 13.74%, rgba(63, 220, 127, 0.19) 88.22%), url("${image}")`,
            },
        },
    }),
)
