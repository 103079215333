import React, { ReactElement, FC, ChangeEvent, useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslate } from '@/core/hooks/useTranslate'

import { Fade, IconButton, Paper, Popper, Typography } from '@material-ui/core'
import {
    Favorite,
    Notifications as NotificationsIcon,
    Reply as ReplyIcon,
    FavoriteBorderOutlined,
    NotificationsOutlined,
} from '@material-ui/icons'
import { CheckBox } from '@/components/controls/Checkbox/Checkbox'

import { AuthorInfo } from '@/components/AuthorInfo/AuthorInfo'

import { actionsCollection } from '@/core/store/collection'
import { actionsStories } from '@/core/store/stories'
import { actionsAuthor } from '@/core/store/author'

import CopyToClipboard from 'react-copy-to-clipboard'

import { Props } from './types'

import { useStyles } from './styles'

const ActionButtons: FC<Props> = ({
    photoUrl,
    authorName,
    authorId,
    type,
    liked,
    id,
    slugName,
    subscribed,
    lightMode,
}: Props): ReactElement => {
    const dispatch = useDispatch()
    const styles = useStyles()
    const translate = useTranslate()

    const [copied, setCopied] = useState(false)
    const anchorRef = useRef(null)

    const likeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (type === 'collection') {
            dispatch(actionsCollection.likeCollection(id, e.target.checked))
            return false
        }
        if (type === 'story') {
            dispatch(actionsStories.likeStory(id, e.target.checked))
            return false
        }
    }

    const handleCopy = useCallback(() => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }, [])

    const subscribeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(actionsAuthor.subscribeAuthor(authorId, e.target.checked))
    }
    return (
        <div className={styles.root}>
            <AuthorInfo authorName={authorName} photoUrl={photoUrl} id={authorId} />
            <div className={styles.buttonsWrapper}>
                <CopyToClipboard
                    text={`${window.location.protocol}//${window.location.hostname}/${type}/${slugName}`}
                    onCopy={handleCopy}
                >
                    <IconButton ref={anchorRef}>
                        <ReplyIcon color={lightMode ? 'primary' : 'secondary'} />
                    </IconButton>
                </CopyToClipboard>
                <Popper
                    open={copied}
                    anchorEl={anchorRef.current}
                    placement={'top'}
                    transition
                    disablePortal
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={250}>
                            <Paper className={styles.popover}>
                                <Typography>{translate('#LinkCopiedText')}</Typography>
                            </Paper>
                        </Fade>
                    )}
                </Popper>

                <CheckBox
                    onChange={likeHandler}
                    checked={liked}
                    icon={<FavoriteBorderOutlined />}
                    checkedIcon={<Favorite />}
                    className={lightMode ? styles.lightBackGround : styles.darkBackGround}
                    color={'primary'}
                />

                <CheckBox
                    onChange={subscribeHandler}
                    checked={subscribed}
                    icon={<NotificationsOutlined />}
                    checkedIcon={<NotificationsIcon />}
                    className={lightMode ? styles.lightBackGround : styles.darkBackGround}
                    color={'secondary'}
                />
            </div>
        </div>
    )
}

export { ActionButtons }
