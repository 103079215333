import { StartPageAuthorType } from '@/core/types/AuthorsTypes'
import { ReturnAnyActions } from '../../types/types'
import { actionsAuthor } from './actions'

export const initialState = {
    startPageAuthors: {
        loading: false as boolean,
        loaded: false as boolean,
        error: null as string | null,
        data: [] as Array<StartPageAuthorType>,
    },
    authorData: null as StartPageAuthorType | null,
    loading: false,
    error: null as string | null,
}

export type State = typeof initialState

export type GeneralActions = ReturnAnyActions<typeof actionsAuthor>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/START_AUTHORS_LOADING':
            return {
                ...state,
                [action.payload.authorCollectionName]: {
                    ...state[action.payload.authorCollectionName],
                    loading: true,
                },
            }
        case '@/STOP_AUTHORS_LOADING':
            return {
                ...state,
                [action.payload.authorCollectionName]: {
                    ...state[action.payload.authorCollectionName],
                    loading: false,
                },
            }
        case '@/ERROR_AUTHORS_LOADING':
            return {
                ...state,
                [action.payload.authorCollectionName]: {
                    ...state[action.payload.authorCollectionName],
                    error: action.payload.error,
                },
            }
        case '@/SET_AUTHORS':
            return {
                ...state,
                [action.payload.authorCollectionName]: {
                    ...state[action.payload.authorCollectionName],
                    data: [
                        ...state[action.payload.authorCollectionName].data,
                        ...action.payload.data,
                    ],
                    loaded: !action.payload.data.length,
                },
            }

        case '@/START_LOADING_AUTHOR_DATA':
            return {
                ...state,
                loading: true,
            }
        case '@/SET_AUTHOR_DATA':
            return {
                ...state,
                authorData: action.payload,
            }
        case '@/STOP_LOADING_AUTHOR_DATA':
            return {
                ...state,
                loading: false,
            }
        case '@/SET_AUTHOR_DATA_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        case '@/CLEAR_AUTHOR_DATA':
            return {
                ...initialState,
            }
        default:
            return state
    }
}
