import { BaseAPI } from '../index'

export class SearchAPI<T> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchSearchDataInReveal() {
        try {
            const res = await this.requestService.post(`/search`, this.data)

            return res.items
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
