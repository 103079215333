import React from 'react'
import { useSubscriptionModal } from '@/components/SubscriptionsModal/subscriptionModalHook'
import { useMediaQuery } from 'react-responsive'

import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FormattedMessage } from 'react-intl'
import { AuthorFollowCard } from '@/components/AuthorFollowCard'

import { useStyles } from './styles'

const SubscriptionsModal = () => {
    const styles = useStyles()
    const { handleModalToggle, subscriptions } = useSubscriptionModal()
    const isMobile = useMediaQuery({ maxWidth: 767 })

    return (
        <Dialog
            onClose={handleModalToggle}
            open={true}
            disableEnforceFocus
            disablePortal
            classes={{ paper: isMobile ? styles.paperMobile : styles.paper, root: styles.root }}
            BackdropProps={{
                classes: { root: styles.root },
            }}
            style={{ position: isMobile ? 'fixed' : 'absolute' }}
            maxWidth="md"
        >
            <CloseIcon className={styles.closeButton} onClick={handleModalToggle} />
            <DialogTitle id="customized-dialog-title">
                <Typography variant="h4" color="primary">
                    <FormattedMessage id="SubscriptionsText" />
                </Typography>
            </DialogTitle>
            <DialogContent className={styles.contentContainer}>
                {subscriptions.length ? (
                    subscriptions?.map(({ avatar, id, name, subscribed }) => {
                        return (
                            <AuthorFollowCard
                                key={id}
                                id={id}
                                photoUrl={avatar}
                                authorName={name}
                                subscribed={subscribed}
                                hideModal={handleModalToggle}
                            />
                        )
                    })
                ) : (
                    <Typography>
                        <FormattedMessage id="NoSubscriptionsText" />
                    </Typography>
                )}
            </DialogContent>
        </Dialog>
    )
}

export { SubscriptionsModal }
