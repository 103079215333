export const actionsGeneral = {
    appInit: () =>
        ({
            type: '@/APP_INIT',
        } as const),
    locationChange: () =>
        ({
            type: '@/LOCATION_CHANGE',
        } as const),
    setAppInstallRequestedFlag: (payload: boolean) =>
        ({
            type: '@/SET_APP_INSTALL_FLAG',
            payload,
        } as const),

    setContinueAnonymouslyFlag: (payload: boolean) =>
        ({
            type: '@/SET_CONTINUE_ANONYMOUSLY_FLAG',
            payload,
        } as const),
    showModal: (type: string, props: Record<string, unknown>) =>
        ({
            type: '@/SHOW_MODAL',
            payload: { type, props },
        } as const),
    hideModal: () =>
        ({
            type: '@/HIDE_MODAL',
        } as const),
    setMapView: (payload: boolean) =>
        ({
            type: '@/SET_MAP_VIEW',
            payload,
        } as const),
    logOutUserResponseSuccess: (payload: unknown) =>
        ({
            type: '@/LOG_OUT_USER_RESPONSE_SUCCESS',
            payload,
        } as const),
}
