import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getAuthState = (state: AppState): typeof initialState => state.authReducer

export const getUserProfile = createSelector(getAuthState, state => state.profile)
export const isTokenExists = createSelector(getAuthState, state => state.tokenExists)
export const isTokenChecked = createSelector(getAuthState, state => state.tokenChecked)
export const errorText = createSelector(getAuthState, state => state.error)
