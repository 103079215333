import React from 'react'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import TabPanel from '@/components/controls/TabPanel'
import CollectionTab from '@/components/FavouritesModal/CollectionTab'
import StoriesTab from '@/components/FavouritesModal/StoriesTab'
import { useStyles } from './styles'
import { useTranslate } from '@/core/hooks/useTranslate'

const a11yProps = (index: any) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const TabWrapper = () => {
    const styles = useStyles()
    const [value, setValue] = React.useState(0)
    const translate = useTranslate()

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue)
    }
    return (
        <div className={styles.root}>
            <AppBar position="static" color="default" className={styles.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab
                        className={styles.tab}
                        label={translate('#CollectionsText')}
                        {...a11yProps(0)}
                    />
                    <Tab
                        className={styles.tab}
                        label={translate('#StorySetLabel')}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <CollectionTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StoriesTab />
            </TabPanel>
        </div>
    )
}

export default TabWrapper
