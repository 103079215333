import React from 'react'

import { generateSlugName } from '@/core/helpers/generateSlugName'

import { useStyles } from './styles'

type StyledTagProps = {
    id: number
    name: string
    image: string
    checked?: boolean
    subscribed?: boolean
    onClick: (slugName: string) => void
}

const StyledTag = ({ id, name, image, checked, subscribed, onClick }: StyledTagProps) => {
    const styles = useStyles({ image, checked, subscribed })
    const onTagClick = () => onClick(generateSlugName(name))

    return (
        <div className={styles.tag} onClick={onTagClick}>
            {name}
        </div>
    )
}

export { StyledTag }
