import { useDispatch, useSelector } from 'react-redux'
import { actionsSubscriptions, getAuthorsSubscriptionsState } from '@/core/store/subscriptions'
import { useEffect } from 'react'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'

const useSubscriptionModal = () => {
    const dispatch = useDispatch()
    const { hideModal } = useGlobalModalContext()

    useEffect(() => {
        dispatch(actionsSubscriptions.getAuthorsSubscriptions())
    }, [dispatch])

    const handleModalToggle = () => {
        hideModal()
    }
    const subscriptions = useSelector(getAuthorsSubscriptionsState)

    return {
        subscriptions,
        handleModalToggle,
    }
}

export { useSubscriptionModal }
