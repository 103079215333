import React, { ChangeEvent, FC } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { InputLabel } from '@material-ui/core'
import { TextInputStyles, ExploreInputStyles, useStyles } from './styles'

export type Props = {
    type?: string
    placeholder?: string
    name?: string
    value: string
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void
    error?: string
    defaultValue?: string
}
const TextInput: FC<Props> = ({
    type,
    placeholder,
    value,
    handleInputChange,
    name,
    error,
    defaultValue,
}: Props) => {
    const styles = useStyles()
    return (
        <FormControl className={styles.margin}>
            <InputLabel shrink htmlFor="bootstrap-input">
                {name}
            </InputLabel>
            {type !== 'light' && (
                <TextInputStyles
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={handleInputChange}
                    defaultValue={defaultValue}
                />
            )}
            {type === 'light' && (
                <ExploreInputStyles
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={handleInputChange}
                    defaultValue={defaultValue}
                />
            )}
            {error && <div className={styles.errorText}>{error}</div>}
        </FormControl>
    )
}

export { TextInput }
