import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslate } from '@/core/hooks/useTranslate'

import { ActionButtons } from '@/components/ActionsButtons'
import LocationIcon from '@material-ui/icons/LocationOn'

import { Props } from '../types'
import { COLLECTION_TYPE_ICON_MAP, COLLLECTION_TYPE_TITLE_MAP } from '@/core/constants/collection'

import { useStyles } from './styles'

const FreeContent = (props: Props): ReactElement => {
    const styles = useStyles({ image: props.picture })
    const history = useHistory()
    const translate = useTranslate()

    const onClick = () => {
        history.push(`/${props.type}/${props.slugName}`)
        props?.onCardClick?.()
    }

    const CollectionTypeIcon = COLLECTION_TYPE_ICON_MAP[props.collectionType || 0]
    const translatedTitles = COLLLECTION_TYPE_TITLE_MAP.map(title => translate(title))
    const collectionTypeTitle = translatedTitles[props.collectionType || 0]

    return (
        <div className={styles.card}>
            <div onClick={onClick} className={styles.headerContainer}>
                <div className={styles.topHeaderContainer}>
                    <div>
                        <h1 className={styles.title}>{props.title}</h1>
                        {!!props.address && (
                            <p className={styles.location}>
                                <LocationIcon /> {props.address.split(',').slice(-2).join(', ')}
                            </p>
                        )}
                    </div>
                </div>
                {props.type === 'collection' && props.collectionType !== undefined && (
                    <div className={styles.bottomHeaderContainer}>
                        {!!CollectionTypeIcon && (
                            <CollectionTypeIcon className={styles.collectionIcon} />
                        )}
                        {collectionTypeTitle ? `${collectionTypeTitle}, ` : ''}
                        {props.storyCount} {translate('#StoriesLabel')}
                    </div>
                )}
            </div>

            {!props.hideContent && <div className={styles.content}>{props.children}</div>}

            {!props.hideActionButtons && (
                <div className={styles.content}>
                    <ActionButtons
                        photoUrl={props.photoAuthorUrl}
                        authorName={props.authorName}
                        authorId={props.authorId}
                        type={props.type}
                        id={props.id}
                        slugName={props.slugName}
                        liked={props.liked}
                        subscribed={props.subscribed}
                    />
                </div>
            )}
        </div>
    )
}

export default FreeContent
