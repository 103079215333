import { RequestDataByAuthor, requestsScrollingData } from '@/core/types/types'
import {
    RelatedStoriesActionType,
    SingleStoryDataType,
    StoriesTypes,
} from '@/core/types/stories/storiesTypes'

export const actionsLoadingStories = {
    startLoading: (storyType: StoriesTypes, payloadData?: any) => () =>
        ({
            type: '@/START_LOADING_STORIES',
            payload: { storyType, payloadData },
        } as const),
    stopLoading: (storyType: StoriesTypes) => () =>
        ({
            type: '@/STOP_LOADING_STORIES',
            payload: { storyType },
        } as const),
    errorLoading: (storyType: StoriesTypes) => (payload: string) =>
        ({
            type: '@/SET_STORIES_LOADING_ERROR',
            payload: { storyType, error: payload },
        } as const),
    responseSuccess: (storyType: StoriesTypes) => (data: Array<SingleStoryDataType>) =>
        ({
            type: '@/ADD_STORIES',
            payload: { storyType, data },
        } as const),
}

export const actionsStories = {
    startLoadingStories: (storyType: StoriesTypes, payloadData?: any) =>
        ({
            type: '@/START_LOADING_STORIES',
            payload: { storyType, payloadData },
        } as const),
    stopLoadingStories: (storyType: StoriesTypes) =>
        ({
            type: '@/STOP_LOADING_STORIES',
            payload: { storyType },
        } as const),
    setStoriesLoadingError: (storyType: StoriesTypes, payload: string) =>
        ({
            type: '@/SET_STORIES_LOADING_ERROR',
            payload: { storyType, error: payload },
        } as const),
    addStories: (storyType: StoriesTypes, payload: Array<SingleStoryDataType>) =>
        ({
            type: '@/ADD_STORIES',
            payload: { storyType, data: payload },
        } as const),

    // GET ALL STORIES
    getAllStoriesAsync: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_ALL_STORIES_ASYNC',
            payload,
        } as const),

    // GET STORY
    getStory: (value: string | number) =>
        ({
            type: '@/GET_STORY',
            payload: value,
        } as const),
    setStory: (data: any) =>
        ({
            type: '@/SET_STORY',
            payload: data,
        } as const),
    errorFetchingSingleStory: (payload: string) =>
        ({
            type: '@/ERROR_FETCHING_SINGLE_STORY',
            payload,
        } as const),

    // GET STORIES BY AUTHOR
    getStoriesByAuthor: (payload: RequestDataByAuthor) =>
        ({
            type: '@/STORIES/GET_STORIES_BY_AUTHOR',
            payload,
        } as const),

    // GET STORIES BY COLLECTION
    getStoriesByCollection: (data: any) =>
        ({
            type: '@/STORIES/GET_STORIES_BY_COLLECTION',
            payload: data,
        } as const),

    // LIKE STORY
    likeStory: (id: number, like: boolean) =>
        ({
            type: '@/LIKE_STORY',
            payload: { id, like },
        } as const),
    setLikedStory: (id: number, like: boolean) =>
        ({
            type: '@/SET_LIKED_STORY',
            payload: { id, like },
        } as const),

    // GET NEAREST STORIES
    getNearestStories: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_NEAREST_STORIES',
            payload,
        } as const),

    // GET NEAREST STORIES WITH SEARCH
    getNearestStoriesWithChangeSearchFragment: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_NEATEST_STORIES_WITH_CHANGE_SEARCH_FRAGMENT',
            payload: payload,
        } as const),

    // GET RECENT STORIES
    getRecentStories: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_RECENT_STORIES_',
            payload: payload,
        } as const),

    // GET RECENT STORIES WITH SEARCH
    getRecentStoriesWithChangeSearchFragment: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_RECENT_STORIES_WITH_CHANGE_SEARCH_FRAGMENT',
            payload: payload,
        } as const),

    // GET RELATED STORIES
    getRelatedStories: (payload: RelatedStoriesActionType) =>
        ({
            type: '@/GET_RELATED_STORIES',
            payload: payload,
        } as const),

    // OTHER
    clearStories: (storyType: StoriesTypes) =>
        ({
            type: '@/CLEAR_STORIES',
            payload: storyType,
        } as const),
    clearStory: () =>
        ({
            type: '@/CLEAR_STORY',
        } as const),
    setSubscribedAuthor: (id: string, subscribe: boolean) =>
        ({
            type: '@/SET_SUBSCRIBED_AUTHOR',
            payload: { id, subscribe },
        } as const),
}
