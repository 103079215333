import { ReturnAnyActions } from '../../types/types'
import { actionsFavourites } from './actions'
import { likesAndSubscriptionMapper } from '@/core/helpers/likesMapper'
import { subscribeMapper } from '@/core/helpers/subscribeMapper'

export const initialState = {
    favourites: [],
    favouritesCollection: [],
    favouritesStories: [],

    collectionsLoading: false,
    collectionsError: null as string | null,

    storiesLoading: false,
    storiesError: null as string | null,
}

export type State = typeof initialState

export type GeneralActions = ReturnAnyActions<typeof actionsFavourites>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/SET_SUBSCRIBED_AUTHOR':
            return {
                ...state,
                favouritesStories: state.favouritesStories
                    ? subscribeMapper(
                          state?.favouritesStories,
                          action.payload.id,
                          action.payload.subscribe,
                      )
                    : [],
                favouritesCollection: state.favouritesCollection
                    ? subscribeMapper(
                          state?.favouritesCollection,
                          action.payload.id,
                          action.payload.subscribe,
                      )
                    : [],
            }
        case '@/START_LOADING_FAVOURITES_STORIES':
            return {
                ...state,
                storiesLoading: true,
            }
        case '@/STOP_LOADING_FAVOURITES_STORIES':
            return {
                ...state,
                storiesLoading: false,
            }
        case '@/SET_FAVOURITES_STORIES_ERROR':
            return {
                ...state,
                storiesError: action.payload,
            }
        case '@/SET_FAVOURITES_STORIES':
            return {
                ...state,
                favouritesStories: action.payload,
            }

        case '@/START_LOADING_FAVOURITES_COLLECTIONS':
            return {
                ...state,
                collectionsLoading: true,
            }
        case '@/STOP_LOADING_FAVOURITES_COLLECTIONS':
            return {
                ...state,
                collectionsLoading: false,
            }
        case '@/SET_FAVOURITES_COLLECTIONS_ERROR':
            return {
                ...state,
                collectionsError: action.payload,
            }
        case '@/SET_FAVOURITES_COLLECTIONS':
            return {
                ...state,
                favouritesCollection: action.payload,
            }

        case '@/SET_FAVOURITES_LIKED_COLLECTION':
            return {
                ...state,
                favouritesCollection: state?.favouritesCollection
                    ? likesAndSubscriptionMapper(
                          state.favouritesCollection,
                          action.payload.id,
                          action.payload.like,
                          'liked',
                      )
                    : state.favouritesCollection,
            }
        case '@/SET_FAVOURITES_LIKED_STORY':
            return {
                ...state,
                favouritesStories: state?.favouritesStories
                    ? likesAndSubscriptionMapper(
                          state.favouritesStories,
                          action.payload.id,
                          action.payload.like,
                          'liked',
                      )
                    : state.favouritesStories,
            }
        default:
            return state
    }
}
