import { RedirectFromType } from '@/core/types/search'
import {
    ReturnAnyActions,
    searchItemTypeMapObject,
    SearchResponseTypes,
    StateSearchItemType,
} from '../../types/types'
import { actionsSearch } from './actions'

export const initialState = {
    searchValue: '' as string,
    searchData: [] as Array<SearchResponseTypes<StateSearchItemType>>,
    loadingSearchData: false as boolean,
    error: null as string | null,
    redirectedFrom: null as null | RedirectFromType,
}

export type State = typeof initialState

export type SearchActions = ReturnAnyActions<typeof actionsSearch>

export const reducer = (state: State = initialState, action: SearchActions): State => {
    switch (action.type) {
        case '@/START_SEARCH_DATA_APP_':
            return {
                ...state,
                loadingSearchData: true,
            }
        case '@/STOP_SEARCH_DATA_APP_':
            return {
                ...state,
                loadingSearchData: false,
            }
        case '@/FILL_SEARCH_DATA_APP_SUCCESS':
            return {
                ...state,
                error: null,
                searchData: action.payload.data.map(({ searchItemType, ...data }) => ({
                    searchItemType: searchItemTypeMapObject[searchItemType],
                    ...data,
                })),
                searchValue: action.payload.searchValue,
            }

        case '@/FILL_SEARCH_DATA_APP_Error':
            return {
                ...state,
                error: action.payload,
            }

        case '@/SET_REDIRECTED_FROM_SEARCH_PAGE':
            return {
                ...state,
                redirectedFrom: action.payload,
            }
        case '@/CLEAR_SEARCH_DATA_APP_':
            return { ...initialState }
        default:
            return state
    }
}
