import axios from 'axios'
import { API_BASE_URL } from '@/core/constants/patch'
import { LocalStorage } from '@/core/constants/localStorage'

export const checkTokens = async (): Promise<string | null | void> => {
    const baseURL = API_BASE_URL
    const accessToken = localStorage.getItem(LocalStorage.AccessToken)
    const defaultUserToken = localStorage.getItem(LocalStorage.DefaultUserToken)

    if (accessToken) {
        localStorage.removeItem(LocalStorage.DefaultUserToken)
        return accessToken
    }

    if (defaultUserToken) {
        return defaultUserToken
    }

    if (!defaultUserToken && !accessToken) {
        const defaultToken = await axios
            .post(`${baseURL}/auth/signup`, { languageCode: '' })
            .then(response => response.headers)

        localStorage.setItem(LocalStorage.DefaultUserToken, defaultToken['x-auth-token'])

        return defaultToken['x-auth-token']
    }
}
