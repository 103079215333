import React, { ReactElement, useCallback } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useMediaQuery } from 'react-responsive'

import clsx from 'clsx'

import { Button } from '@/components/controls/Button'
import DonateImage from '@/assets/DonateImage.svg'

import { useStyles } from './styles'

const DonateCard = (): ReactElement => {
    const translate = useTranslate()
    const styles = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const onDonateClick = useCallback(() => {
        window.open('https://site.reveal.world/donate', '_blank')
    }, [])

    return (
        <div className={clsx(styles.donateCardContainer, { mobile: isMobile })}>
            <img src={DonateImage} width="143" height="143" />
            <div>
                <h2>{translate('#HelpUsGrowTitle')}</h2>
                <Button
                    type="outlined"
                    color="white"
                    title={translate('#DonateBtnTitle')}
                    onClick={onDonateClick}
                    size="md"
                />
            </div>
        </div>
    )
}

export default DonateCard
