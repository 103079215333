import colors from '@/core/theme/colors'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    paper: {
        backgroundColor: colors.white,
        borderRadius: '24px',
        padding: '24px',
    },
    root: {
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '20px',
        right: '22px',
        cursor: 'pointer',
        fontSize: '16px',
        color: colors.grey,
    },
}))
