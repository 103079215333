type PropActions<T> = T extends { [key: string]: infer R } ? R : never
export type ReturnAnyActions<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<
    PropActions<T>
>

export type requestAnyAction<T> = {
    type: string
    payload: T
}

export type requestsScrollingData = {
    page: number
    limit: number
    searchFragment?: string
    languages?: Array<string>
}

export type FetchDataTypes = (payload: requestsScrollingData) => void

export type RequestDataByAuthor = {
    startNum: number
    maxAmount: number
    author: string
}
export type ResponseSearchItemType = 'Collection' | 'Story'
export type StateSearchItemType = 'collection' | 'story'

type MapObject = {
    Collection: 'collection'
    Story: 'story'
}

export const searchItemTypeMapObject: MapObject = {
    Collection: 'collection',
    Story: 'story',
}

export type SearchResponseTypes<T> = {
    itemId: number
    slugName: string
    name: string
    picture: string
    searchItemType: T
}

export type CoordinatesWithTimeType = {
    lat: number
    lng: number
    timestamp: number
}

/**
 * Simple message with one text field
 */
export type StringMessageType = {
    value: string
    redirectUrl: string
}

export interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[]
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed'
        platform: string
    }>
    prompt(): Promise<void>
}
