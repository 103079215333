import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    waveformContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    wave: {
        width: '100%',
    },
    controls: {
        marginRight: '8px',
    },
    playButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '56px',
        height: '56px',
        background: theme.palette.secondary.main,
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
    },
}))
