import colors from '@/core/theme/colors'
import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        paidCardContainer: {
            background: colors.white,
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
            padding: '24px',
            borderRadius: '24px',
            margin: '16px 0',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            lineHeight: '28px',
            marginBottom: '16px',
            color: colors.black,
        },
        description: {
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: '150%',
            marginBottom: '24px',
            color: colors.black,
        },
        dividerText: {
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: '150%',
            margin: '8px 0',
            color: colors.black,
        },
    }),
)
