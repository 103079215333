import React, { FC, ReactElement } from 'react'
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { Props } from './types'

const CheckboxStyled = withStyles(({ palette }: Theme) => {
    return createStyles({
        root: {
            color: '#FFFFFF',
            opacity: 1,
            '&$checked': {
                // color: palette.success.main,
                opacity: 1,
            },
        },
        checked: {},
    })
})((props: Props) => <Checkbox {...props} />)

const CheckBox: FC<Props> = ({ label, ...props }: Props): ReactElement => {
    return (
        <FormControlLabel
            style={{ width: 'fit-content', marginRight: 0, marginLeft: 0 }}
            control={<CheckboxStyled name="checkedG" {...props} />}
            label={label}
        />
    )
}

export { CheckBox }
