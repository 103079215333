export const actionsInterests = {
    getInterestsData: () =>
        ({
            type: '@/GET_INTERESTS_DATA',
        } as const),
    startLoadingInterestsData: (data: any) =>
        ({
            type: '@/START_LOADING_INTERESTS_DATA',
            payload: data,
        } as const),
    stopLoadingInterestsData: (data: any) =>
        ({
            type: '@/STOP_LOADING_INTERESTS_DATA',
            payload: data,
        } as const),
    setInterestDataError: (payload: string) =>
        ({
            type: '@/SET_INTERESTS_DATA_ERROR',
            payload,
        } as const),
    setInterestsData: (data: any) =>
        ({
            type: '@/SET_INTERESTS_DATA',
            payload: data,
        } as const),
    toggleInterests: (id: string) =>
        ({
            type: '@/TOGGLE_INTERESTS',
            payload: id,
        } as const),
    saveInterests: (data: string[]) =>
        ({
            type: '@/SAVE_INTERESTS',
            payload: data,
        } as const),
}
