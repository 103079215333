import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslate } from '@/core/hooks/useTranslate'

import NoPhoto from '@/assets/no_photo.png'

import { AuthorsListDataProps } from '@/core/types/AuthorsTypes'

import { useStyles } from './styles'

const AuthorsListBox: FC<AuthorsListDataProps> = ({ data = [] }: AuthorsListDataProps) => {
    const translate = useTranslate()
    const styles = useStyles()
    const history = useHistory()

    const onAuthorClick = (id: string) => {
        history.push(`/author/${id}`)
    }

    return (
        <div className={styles.boxContainer}>
            <div className={styles.authorsListHeader}>
                <h2>{translate('#AuthorsText')}</h2>
            </div>
            <div className={styles.authorsList}>
                {data.map(author => (
                    <div
                        key={author.uuid}
                        className={styles.authorCard}
                        onClick={() => onAuthorClick(author.uuid)}
                    >
                        <img
                            src={author.avatar || NoPhoto}
                            alt="photo"
                            className={styles.authorPhoto}
                        />
                        <div className={styles.authorName}>{author.username}</div>
                        <div className={styles.authorInfo}>
                            <b>{author.storiesCount}</b> {translate('#PublicationsText')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AuthorsListBox
