import React, { FC, ReactElement } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { useClasses } from './CloseButtonStyles'
import clsx from 'clsx'
import { CloseButtonTypes } from './CloseButtonTypes'

const CloseButton: FC<CloseButtonTypes> = ({
    absolute = false,
    className,
    ...props
}: IconButtonProps & CloseButtonTypes): ReactElement => {
    const classes = useClasses()
    return (
        <IconButton
            size="small"
            className={clsx(
                classes.closeButton,
                { [classes.closeButtonAbsolute]: absolute },
                className,
            )}
            {...props}
        >
            <CloseIcon className={classes.icon} />
        </IconButton>
    )
}

export { CloseButton }
