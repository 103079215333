import colors from '@/core/theme/colors'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        bodyContainer: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        locationImage: {
            marginBottom: '24px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            lineHeight: '28px',
            marginBottom: '8px',
            color: colors.black,
        },
        description: {
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: '150%',
            marginBottom: '16px',
            color: colors.black,
        },
    }),
)
