import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getStoriesState = (state: AppState): typeof initialState => state.storiesReducer

export const getStory = createSelector(getStoriesState, state => state.story)
export const getStoryFetchError = createSelector(getStoriesState, state => state.storyFetchingError)

export const getStories = createSelector(getStoriesState, state => state.stories)
export const getAuthorStories = createSelector(getStoriesState, state => state.authorStories)
export const getCollectionStories = createSelector(
    getStoriesState,
    state => state.collectionStories,
)
export const getNearestStories = createSelector(getStoriesState, state => state.nearestStories)
export const getRecentStories = createSelector(getStoriesState, state => state.recentStories)
export const getRelatedStories = createSelector(getStoriesState, state => state.relatedStories)
