import { takeLatest, put, call } from 'redux-saga/effects'

import { LanguageAPI } from '@/core/api/languageAPI/languageAPI'

import { actionsLanguage } from '@/core/store/language'

import { requestAnyAction } from '@/core/types/types'
import { LanguageCodeType } from '@/core/types/languages'

import { DEFAULT_DATA_LANGUAGES, LANGUAGES } from '@/core/constants/languages'
import { LocalStorage } from '@/core/constants/localStorage'

function* fetchInitialisationLanguage(languageCode: string, resource: string): Generator {
    yield put(actionsLanguage.setInterfaceLanguageCode(languageCode))
    yield put(actionsLanguage.getInterfaceLanguageData(resource))
}

function* initAppLanguage(): Generator {
    // Set interface language code and data
    const interfaceLanguageCode =
        localStorage.getItem(LocalStorage.InterfaceLanguage) ||
        window.navigator.language.substring(0, 2)

    if (interfaceLanguageCode in LANGUAGES) {
        const { resource } = LANGUAGES[interfaceLanguageCode]
        yield call(fetchInitialisationLanguage, interfaceLanguageCode, resource)
    } else {
        yield call(fetchInitialisationLanguage, 'en', LANGUAGES.en.resource)
    }

    // Set data language code
    const storedDataLanguages = localStorage.getItem(LocalStorage.DataLanguages)
    const parsedStoredDataLanguages = storedDataLanguages && JSON.parse(storedDataLanguages)

    if (
        parsedStoredDataLanguages &&
        parsedStoredDataLanguages.every((code: any) => LANGUAGES[code])
    ) {
        yield put(actionsLanguage.setDataLanguagesCodes(parsedStoredDataLanguages))
    } else {
        const defaultDataLanguages =
            DEFAULT_DATA_LANGUAGES[window.navigator.language.substring(0, 2)] ||
            DEFAULT_DATA_LANGUAGES.en

        yield put(actionsLanguage.setDataLanguagesCodes(defaultDataLanguages))
    }
}

function* getInteraceLanguage({ payload }: requestAnyAction<LanguageCodeType>): Generator {
    const fetcher = new LanguageAPI(payload)
    const fetch = fetcher.fetchLanguageData.bind(fetcher)
    const language: any = yield call(fetch)
    yield put(actionsLanguage.setInterfaceLanguageData(language))
}

export function* watchLanguage(): Generator {
    yield takeLatest('@/GET_INTERFACE_LANGUAGE_DATA', getInteraceLanguage)
    yield takeLatest('@/INIT_INTERFACE_LANGUAGE', initAppLanguage)
}
