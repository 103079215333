import colors from '@/core/theme/colors'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            color: colors.secondaryText,
        },
    }),
)
