import React, { useEffect } from 'react'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import { useDispatch, useSelector } from 'react-redux'
import { actionsInterests, getInterests } from '@/core/store/interests'
import { useStyles } from './styles'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { CloseButton } from '@/components/controls/CloseButton'
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage } from 'react-intl'
import { InterestsModalPropsType } from '@/core/constants/modal'

const InterestsModal = (props: InterestsModalPropsType) => {
    const { hideModal } = useGlobalModalContext()
    const data = useSelector(getInterests)
    const dispatch = useDispatch()
    const styles = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const handleModalToggle = () => {
        hideModal()
        props?.afterLaterClickModal?.call(this)
    }

    const handleSubmit = () => {
        const fetchData = data.filter((el: any) => el.subscribed).map((item: any) => item.id)
        dispatch(actionsInterests.saveInterests(fetchData))
        hideModal()
        props?.afterSaveClickModal?.call(this)
    }

    const handleCheck = (id: string) => {
        dispatch(actionsInterests.toggleInterests(id))
    }

    return (
        <Dialog
            onClose={handleModalToggle}
            open={true}
            classes={{ paper: isMobile ? styles.paperMobile : styles.paper, root: styles.root }}
            BackdropProps={{
                classes: { root: styles.root },
            }}
            style={{ position: isMobile ? 'fixed' : 'absolute' }}
            disablePortal
            maxWidth="sm"
            scroll="paper"
            aria-labelledby="customized-dialog-title"
            aria-describedby="customized-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" color="textPrimary">
                        <FormattedMessage id="WhatsYourInterestsText" />
                    </Typography>
                    <CloseButton onClick={handleModalToggle} />
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Grid container spacing={10}>
                        {data &&
                            data.map((item: any) => {
                                return (
                                    <Grid item lg={6} md={6} xs={12} sm={12} key={item.id}>
                                        <FormControlLabel
                                            classes={{ label: styles.label }}
                                            className={styles.controlItem}
                                            control={
                                                <Checkbox
                                                    disableRipple
                                                    className={styles.checkboxItem}
                                                    color="default"
                                                    onChange={() => handleCheck(item.id)}
                                                    checked={item.subscribed}
                                                    checkedIcon={
                                                        <span
                                                            className={clsx(
                                                                styles.spanItem,
                                                                styles.spanItemChecked,
                                                            )}
                                                        >
                                                            <span className={styles.overlay} />
                                                            <img
                                                                className={styles.image}
                                                                src={item.image}
                                                            />
                                                        </span>
                                                    }
                                                    icon={
                                                        <span className={styles.spanItem}>
                                                            <span className={styles.overlay} />
                                                            <img
                                                                className={styles.image}
                                                                src={item.image}
                                                            />
                                                        </span>
                                                    }
                                                    inputProps={{
                                                        'aria-label': 'decorative checkbox',
                                                    }}
                                                />
                                            }
                                            label={item.name}
                                        />
                                    </Grid>
                                )
                            })}
                    </Grid>
                </FormGroup>
            </DialogContent>
            <DialogActions style={{ padding: '16px 24px' }}>
                <Button
                    onClick={handleSubmit}
                    className={styles.button}
                    disabled={data.filter((item: any) => item.subscribed).length < 3}
                >
                    <FormattedMessage id="SaveButtonText" />
                </Button>
                {props.showLaterButtonText && (
                    <Button onClick={handleModalToggle} className={styles.cancelButton}>
                        {props.showLaterButtonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default InterestsModal
