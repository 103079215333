import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { actionsFavourites, getFavouritesCollections } from '@/core/store/favourites'
import Spinner from '@/components/controls/Spinner'
import CollectionList from '@/components/CollectionList'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const CollectionTab = () => {
    const dispatch = useDispatch()
    const collectionData = useSelector(getFavouritesCollections, shallowEqual)

    useEffect(() => {
        dispatch(actionsFavourites.getFavouritesCollections({ offset: 0, limit: 999 }))
    }, [])

    return (
        <div>
            {!collectionData && <Spinner />}
            {collectionData && collectionData.length === 0 && (
                <Typography>
                    <FormattedMessage id="NoFavouritesText" />
                </Typography>
            )}
            {collectionData && collectionData.length > 0 && (
                <CollectionList collections={collectionData} />
            )}
        </div>
    )
}

export default CollectionTab
