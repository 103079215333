import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        donateCardContainer: {
            background:
                'linear-gradient(120.58deg, #8E3FDC 31.99%, #8321E4 71.17%, #17048C 99.53%)',
            padding: '24px',
            borderRadius: '24px',
            margin: '16px 0',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            '& h2': {
                margin: '0 0 12px 0',
                fontSize: '36px',
                lineHeight: '42px',
                fontWeight: 600,
            },
            '&.mobile': {
                '& h2': {
                    fontSize: '24px',
                },
            },
        },
    }),
)
