import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            color: 'inherit',
            width: '100%',
        },

        buttonsWrapper: {
            display: 'flex',
            gridTemplateColumns: '1fr 1fr 1fr',
            alignItems: 'center',
            color: 'inherit',
        },
        popover: {
            padding: '3px 10px',
            backgroundColor: 'rgba(0,0,0,0.1)',
        },
        darkBackGround: {
            color: '#000000',
        },
        lightBackGround: {
            color: '#FFF',
        },
    }),
)
