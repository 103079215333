import { ReturnAnyActions, CoordinatesWithTimeType } from '@/core/types/types'
import { actionsMap } from './actions'
import { coordinatesMapping } from '@/core/helpers/coordinatesMapping'
import { defaultUserPosition } from '@/core/constants/googleMaps'

export const initialState = {
    view: 'FULL',
    userCoordinates: {
        ...defaultUserPosition,
        isRequested: true,
        isSet: false,
    },
    originCoordinates: {
        lat: 59.33258,
        lng: 18.0649,
        timestamp: new Date().getTime(),
    } as CoordinatesWithTimeType,
    allMarkers: [],
    searchResultMarkers: [],
    pageMarkers: [],
}

export type State = typeof initialState

export type GeneralActions = ReturnAnyActions<typeof actionsMap>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/SET_MAP_VIEW': {
            return {
                ...state,
                view: action.payload,
            }
        }
        case '@/UNSET_MAP_VIEW': {
            return {
                ...state,
                view: 'FULL',
            }
        }
        case '@/SET_CURRENT_POSITION': {
            return {
                ...state,
                originCoordinates: action.payload,
            }
        }
        case '@/SET_USER_POSITION_REQUESTED': {
            return {
                ...state,
                userCoordinates: {
                    ...state.userCoordinates,
                    isRequested: action.payload,
                },
            }
        }
        case '@/SET_USER_POSITION': {
            return {
                ...state,
                userCoordinates: {
                    ...action.payload,
                    isSet: true,
                    isRequested: true,
                },
            }
        }
        case '@/SET_ALL_MARKERS': {
            return {
                ...state,
                allMarkers: coordinatesMapping(action.payload),
            }
        }

        case '@/SET_MARKERS_BY_IDS': {
            return {
                ...state,
                [action.payload.target]: coordinatesMapping(action.payload.data),
            }
        }

        default: {
            return state
        }
    }
}
