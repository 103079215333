import { makeStyles } from '@material-ui/core/styles'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() => ({
    interestsContainer: {
        background: colors.lightGrey2,
    },
    interestsHeader: {
        paddingBottom: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        '& h2': {
            fontSize: '24px',
            color: colors.black,
            margin: 0,
        },
    },
    interestsList: {
        paddingBottom: '16px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: '8px',
    },
}))
