import React, { ReactElement } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'

import ModalWindow from '@/components/controls/ModalWindow'
import { Button } from '@/components/controls/Button'
import LocationImage from '@/assets/LocationImage.svg'

import { ModalPropsType } from '@/core/constants/modal'

import { useStyles } from './styles'

const PermissionsModal = (props: ModalPropsType): ReactElement => {
    const translate = useTranslate()
    const styles = useStyles()

    const { hideModal } = useGlobalModalContext()
    const handleModalToggle = () => {
        hideModal()
        props.afterCloseModal.call(this)
    }

    return (
        <ModalWindow onClose={handleModalToggle} open>
            <div className={styles.bodyContainer}>
                <img
                    src={LocationImage}
                    alt="location-image"
                    width="113"
                    height="112"
                    className={styles.locationImage}
                />
                <div className={styles.title}>{translate('#PermissionsRequestHeader')}</div>
                <div className={styles.description}>{translate('#LocationRequestText')}</div>
                <Button
                    type="filled"
                    color="green"
                    title="OK"
                    size="md"
                    fullWidth
                    onClick={handleModalToggle}
                />
            </div>
        </ModalWindow>
    )
}

export { PermissionsModal }
