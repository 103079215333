import React from 'react'
import { useStyles } from '../styles'

const Cluster = ({
    color,
    superCluster,
    mapRef,
    latitude,
    longitude,
    cluster,
    pointCount,
}: any) => {
    const styles = useStyles()
    return (
        <div
            className={styles.clusterMarker}
            style={{
                border: `5px solid ${color}`,
            }}
            onClick={() => {
                const expansionZoom = Math.min(superCluster.getClusterExpansionZoom(cluster.id), 20)
                mapRef.current.setZoom(expansionZoom)
                mapRef.current.panTo({ lat: latitude, lng: longitude })
            }}
        >
            {pointCount}
        </div>
    )
}

export default Cluster
