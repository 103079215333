import { ReturnAnyActions } from '../../types/types'
import { actionsStories } from './actions'
import { reducerLikesAndSubscriptionMapper } from '@/core/helpers/likesMapper'
import { reducerSubscribeMapper } from '@/core/helpers/subscribeMapper'
import { SingleStoryDataType } from '@/core/types/stories/storiesTypes'

export const initialState = {
    stories: {
        data: [] as Array<SingleStoryDataType>,
        error: null as string | null,
        loading: false as boolean,
        loaded: false as boolean,
    },
    authorStories: {
        data: [] as Array<SingleStoryDataType>,
        error: null as string | null,
        loading: false as boolean,
        loaded: false as boolean,
    },
    collectionStories: {
        data: [] as Array<SingleStoryDataType>,
        error: null as string | null,
        loading: false as boolean,
        loaded: false as boolean,
        collectionId: null as number | null,
    },
    nearestStories: {
        data: [] as Array<SingleStoryDataType>,
        error: null as string | null,
        loading: false as boolean,
        loaded: false as boolean,
    },
    recentStories: {
        data: [] as Array<SingleStoryDataType>,
        error: null as string | null,
        loading: false as boolean,
        loaded: false as boolean,
    },
    relatedStories: {
        data: [] as Array<SingleStoryDataType>,
        error: null as string | null,
        loading: false as boolean,
        loaded: false as boolean,
    },
    story: null as SingleStoryDataType | null,
    storyFetchingError: null as string | null,
}

export type State = typeof initialState
export type GeneralActions = ReturnAnyActions<typeof actionsStories>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/START_LOADING_STORIES':
            return {
                ...state,
                [action.payload.storyType]: {
                    ...state[action.payload.storyType],
                    ...action.payload.payloadData,
                    loading: true,
                },
            }
        case '@/STOP_LOADING_STORIES':
            return {
                ...state,
                [action.payload.storyType]: {
                    ...state[action.payload.storyType],
                    loading: false,
                },
            }
        case '@/SET_STORIES_LOADING_ERROR':
            return {
                ...state,
                [action.payload.storyType]: {
                    ...state[action.payload.storyType],
                    error: action.payload.error,
                },
            }
        case '@/ADD_STORIES':
            return {
                ...state,
                [action.payload.storyType]: {
                    ...state[action.payload.storyType],
                    data: [...state[action.payload.storyType].data, ...action.payload.data],
                    loaded: !action.payload.data.length,
                },
            }

        case '@/SET_SUBSCRIBED_AUTHOR':
            return {
                ...state,
                stories: reducerSubscribeMapper(
                    state?.stories,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                authorStories: reducerSubscribeMapper(
                    state?.authorStories,
                    action.payload.id,
                    action.payload.subscribe,
                ),

                collectionStories: reducerSubscribeMapper(
                    state?.collectionStories,
                    action.payload.id,
                    action.payload.subscribe,
                ),

                recentStories: reducerSubscribeMapper(
                    state?.recentStories,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                nearestStories: reducerSubscribeMapper(
                    state?.nearestStories,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                relatedStories: reducerSubscribeMapper(
                    state?.relatedStories,
                    action.payload.id,
                    action.payload.subscribe,
                ),
                story:
                    state?.story?.author?.id === action.payload.id
                        ? {
                              ...state.story,
                              author: {
                                  ...state.story.author,
                                  subscribed: action.payload.subscribe,
                              },
                          }
                        : state.story,
            }
        case '@/SET_LIKED_STORY':
            return {
                ...state,
                stories: reducerLikesAndSubscriptionMapper(
                    state.stories,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                authorStories: reducerLikesAndSubscriptionMapper(
                    state.authorStories,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                collectionStories: reducerLikesAndSubscriptionMapper(
                    state.collectionStories,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                nearestStories: reducerLikesAndSubscriptionMapper(
                    state.nearestStories,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                recentStories: reducerLikesAndSubscriptionMapper(
                    state.recentStories,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                relatedStories: reducerLikesAndSubscriptionMapper(
                    state.relatedStories,
                    action.payload.id,
                    action.payload.like,
                    'liked',
                ),
                story:
                    state?.story?.id === action.payload.id
                        ? {
                              ...state.story,
                              liked: action.payload.like,
                          }
                        : state.story,
            }
        case '@/SET_STORY':
            return {
                ...state,
                story: action.payload,
            }
        case '@/ERROR_FETCHING_SINGLE_STORY':
            return {
                ...state,
                storyFetchingError: action.payload,
            }
        case '@/CLEAR_STORIES':
            return {
                ...state,
                [action.payload]: { ...initialState[action.payload] },
            }
        case '@/CLEAR_STORY':
            return {
                ...state,
                story: initialState.story,
            }
        default:
            return state
    }
}
