import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PrimitiveType } from 'intl-messageformat'
import defaultTranslation from '@resources/en.json'

const checkIfValidForTranslate = (string: string): boolean => string.indexOf('#') === 0

const turnIntoId = (string: string): string => string.replace('#', '')

export const useTranslate = (): ((
    string: string,
    options?: Record<string, PrimitiveType>,
) => string) => {
    const intl = useIntl()
    const messages: { [key: string]: string } = defaultTranslation
    return (string: string, options): string =>
        checkIfValidForTranslate(string)
            ? intl.formatMessage(
                  {
                      id: turnIntoId(string),
                      defaultMessage: messages[turnIntoId(string)],
                  },
                  options,
              )
            : string
}

export const translate = (string: string): React.ReactElement | string =>
    checkIfValidForTranslate(string) ? <FormattedMessage id={turnIntoId(string)} /> : string
