import React, { FC } from 'react'
import { Card } from '../controls/CardComponent'
import { newCollectionsPreviewTypes } from '@/core/types/CollectionsTypes'
import TagsWrapper from '@/components/TagsWrapper'
import DonateCard from '@/components/DonateCard'
import { AuthorDataType } from '@/core/types/AuthorsTypes'
import AuthorsListBox from '../AuthorsListBox'
import Spinner from '@/components/controls/Spinner'

export type CollectionsListProps = {
    collections: newCollectionsPreviewTypes[]
    authorsList?: AuthorDataType[]
    showDonateCard?: boolean
    isLoading?: boolean
}

const CollectionList: FC<CollectionsListProps> = ({
    collections,
    authorsList,
    showDonateCard = false,
    isLoading = false,
}: CollectionsListProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {collections.map(
                (
                    {
                        id: collectionId,
                        address,
                        picture,
                        name,
                        description,
                        author,
                        categories,
                        liked,
                        price,
                        purchased,
                        slugName,
                        collectionType,
                        storyCount,
                    },
                    idx,
                ) => (
                    <div key={`${collectionId}-${idx}`}>
                        {idx === 1 && showDonateCard && <DonateCard />}
                        {idx === 3 && authorsList && <AuthorsListBox data={authorsList} />}
                        <Card
                            picture={picture[0]}
                            key={collectionId}
                            id={collectionId}
                            title={name}
                            authorId={author.id}
                            authorName={author.name}
                            photoAuthorUrl={author.avatar}
                            subscribed={author.subscribed}
                            liked={liked}
                            eventId={collectionId}
                            type={'collection'}
                            price={price || null}
                            purchased={purchased}
                            slugName={slugName}
                            address={address}
                            collectionType={collectionType}
                            storyCount={storyCount}
                        >
                            {description}

                            <TagsWrapper tags={categories} />
                        </Card>
                    </div>
                ),
            )}
            {isLoading && <Spinner />}
        </div>
    )
}

export default CollectionList
