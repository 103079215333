import React, { FC, ReactElement } from 'react'
import clsx from 'clsx'

import { useStyles } from './styles'

type ButtonProps = {
    type?: 'filled' | 'outlined'
    color?: 'dark' | 'green' | 'white'
    size?: 'md' | 'sm'
    title: string
    className?: string
    disabled?: boolean
    fullWidth?: boolean
    noHover?: boolean
    style?: any
    onClick?: () => void
}
export const Button: FC<ButtonProps> = ({
    type = 'filled',
    color = 'dark',
    title,
    className,
    disabled,
    style,
    size = 'sm',
    fullWidth = false,
    noHover = false,
    onClick,
}: ButtonProps): ReactElement => {
    const styles = useStyles()

    return (
        <button
            style={style}
            disabled={disabled}
            onClick={onClick}
            className={clsx(styles.baseBtn, className, {
                [styles.filledBtn]: type === 'filled',
                [styles.outlinedBtn]: type === 'outlined',
                [styles.smallBtn]: size === 'sm',
                [styles.mediumBtn]: size === 'md',
                [styles.fullWidth]: fullWidth,
                darkBtn: color === 'dark',
                greenBtn: color === 'green',
                whiteBtn: color === 'white',
                noHover,
            })}
        >
            {title}
        </button>
    )
}
