import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
    getDataLocationAndPayload,
    makeRequestWithSpinner,
    OptionsType,
    StoryFillType,
} from '../sagaWorkers/saga'
import { DefaultUserTypes } from '@/core/types/auth/authTypes'
import { StoriesAPI } from '@/core/api/storiesAPI/storiesAPI'
import { requestAnyAction, RequestDataByAuthor, requestsScrollingData } from '@/core/types/types'
import { actionsStories, actionsLoadingStories } from '@/core/store/stories/actions'
import { CollectionsSiteBarAPI } from '@/core/api/collectionAPI/newsCollectionAPI'
import { LikeCollection } from '@/core/types/collection/collection'
import { actionsFavourites } from '@/core/store/favourites'
import { getSearchDataApp } from '@/core/store/search/saga'
import { AppState } from '@/core/store/rootReducer'
import { RelatedStoriesActionType, SingleStoryDataType } from '@/core/types/stories/storiesTypes'
import { actionsGeneral } from '@/core/store/general'
import { ModalTypes } from '@/core/constants/modal'

function* getStory({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    try {
        const fetcherStory = new StoriesAPI(payload)
        const fetch = fetcherStory.fetchStoryByIdOrSlug.bind(fetcherStory)
        const story: any = yield call(fetch)
        yield put(actionsStories.setStory(story))
    } catch (e: any) {
        console.error('Catched Error in getCollection', e)
        yield put(actionsStories.errorFetchingSingleStory(e.message))
    }
}

function* getAllStories({ payload }: requestAnyAction<requestsScrollingData>): Generator {
    const { lat, lng, timestamp }: any = yield select(
        (state: AppState) => state.mapReducer.userCoordinates,
    )
    const body = {
        latitude: lat,
        longitude: lng,
        timestamp,
        startNum: payload.page,
        maxAmount: payload.limit,
        languages: payload.languages,
    }

    const fetcher = new CollectionsSiteBarAPI(body)
    const options: OptionsType<Array<SingleStoryDataType>> = {
        fetcher: fetcher.fetchStoriesPreviewData.bind(fetcher),
        startFetching: actionsLoadingStories.startLoading('stories'),
        stopFetching: actionsLoadingStories.startLoading('stories'),
        fill: actionsLoadingStories.responseSuccess('stories'),
        setErrorAction: actionsLoadingStories.errorLoading('stories'),
    }

    yield makeRequestWithSpinner<Array<SingleStoryDataType>>(options)
}

function* getStoriesByAuthor({ payload }: requestAnyAction<RequestDataByAuthor>): Generator {
    const fetcher = new StoriesAPI(payload)
    const options: OptionsType<Array<SingleStoryDataType>> = {
        fetcher: fetcher.fetchStoriesByAuthor.bind(fetcher),
        startFetching: actionsLoadingStories.startLoading('authorStories'),
        stopFetching: actionsLoadingStories.stopLoading('authorStories'),
        fill: actionsLoadingStories.responseSuccess('authorStories'),
        setErrorAction: actionsLoadingStories.errorLoading('authorStories'),
    }
    yield makeRequestWithSpinner<Array<SingleStoryDataType>>(options)
}

function* getStoriesByCollection({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    const fetcher = new StoriesAPI(payload)
    const options: OptionsType<Array<SingleStoryDataType>> = {
        fetcher: fetcher.fetchStoriesByCollection.bind(fetcher),
        startFetching: actionsLoadingStories.startLoading('collectionStories', {
            collectionId: payload,
        }),
        stopFetching: actionsLoadingStories.stopLoading('collectionStories'),
        fill: actionsLoadingStories.responseSuccess('collectionStories'),
        setErrorAction: actionsLoadingStories.errorLoading('collectionStories'),
    }
    yield makeRequestWithSpinner<Array<SingleStoryDataType>>(options)
}

function* getNearestStoriesData(
    { payload }: requestAnyAction<requestsScrollingData>,
    fill: StoryFillType<Array<SingleStoryDataType>>,
): Generator {
    const body: any = yield call(getDataLocationAndPayload, payload)
    if (!payload.searchFragment || payload.searchFragment?.includes('interest:"')) {
        const fetcher = new StoriesAPI(body)
        const options: OptionsType<Array<SingleStoryDataType>> = {
            fetcher: fetcher.fetchNearestStories.bind(fetcher),
            startFetching: actionsLoadingStories.startLoading('nearestStories'),
            stopFetching: actionsLoadingStories.stopLoading('nearestStories'),
            fill,
            setErrorAction: actionsLoadingStories.errorLoading('nearestStories'),
        }
        yield makeRequestWithSpinner<Array<SingleStoryDataType>>(options)
    } else {
        yield call(getSearchDataApp, {
            type: '',
            payload,
        })
    }
}

function* getNearestStories(payload: requestAnyAction<requestsScrollingData>): Generator {
    yield call(
        getNearestStoriesData,
        payload,
        actionsLoadingStories.responseSuccess('nearestStories'),
    )
}

function* getNearestStoriesWithSearchChangeFragment(
    payload: requestAnyAction<requestsScrollingData>,
): Generator {
    yield call(
        getNearestStoriesData,
        payload,
        actionsLoadingStories.responseSuccess('nearestStories'),
    )
}

function* getRecentStoriesData(
    { payload }: requestAnyAction<requestsScrollingData>,
    fill: StoryFillType<Array<SingleStoryDataType>>,
): Generator {
    const body: any = yield call(getDataLocationAndPayload, payload)
    if (!payload.searchFragment || payload.searchFragment?.includes('interest:"')) {
        const fetcher = new StoriesAPI(body)
        const options: OptionsType<Array<SingleStoryDataType>> = {
            fetcher: fetcher.fetchRecent.bind(fetcher),
            startFetching: actionsLoadingStories.startLoading('recentStories'),
            stopFetching: actionsLoadingStories.stopLoading('recentStories'),
            fill,
            setErrorAction: actionsLoadingStories.errorLoading('recentStories'),
        }
        yield makeRequestWithSpinner<Array<SingleStoryDataType>>(options)
    } else {
        yield call(getSearchDataApp, {
            type: '',
            payload,
        })
    }
}

function* getRecentStories(payload: requestAnyAction<requestsScrollingData>): Generator {
    yield call(
        getRecentStoriesData,
        payload,
        actionsLoadingStories.responseSuccess('recentStories'),
    )
}

function* getRecentStoriesWithChangeSearchFragment(
    payload: requestAnyAction<requestsScrollingData>,
): Generator {
    yield call(
        getRecentStoriesData,
        payload,
        actionsLoadingStories.responseSuccess('recentStories'),
    )
}

function* getRelatedStoriessData({
    payload,
}: requestAnyAction<RelatedStoriesActionType>): Generator {
    const body: any = yield call(getDataLocationAndPayload, {
        ...payload,
        searchFragment: `interest:"${payload.interests?.[0]}"`,
    })

    const fetcher = new StoriesAPI(body)
    const options: OptionsType<Array<SingleStoryDataType>> = {
        fetcher: fetcher.fetchNearestStories.bind(fetcher),
        startFetching: actionsLoadingStories.startLoading('relatedStories'),
        stopFetching: actionsLoadingStories.stopLoading('relatedStories'),
        fill: actionsLoadingStories.responseSuccess('relatedStories'),
        setErrorAction: actionsLoadingStories.errorLoading('relatedStories'),
    }

    yield makeRequestWithSpinner<Array<SingleStoryDataType>>(options)
}

function* likeStory({ payload }: requestAnyAction<LikeCollection>): Generator {
    try {
        const userProfile: any = yield select((state: AppState) => state.authReducer.profile)
        const continueAnonymouslyConfirmed: any = yield select(
            (state: AppState) => state.generalReducer.continueAnonymouslyConfirmed,
        )

        if (!continueAnonymouslyConfirmed && !userProfile) {
            yield put(
                actionsGeneral.showModal(ModalTypes.MY_ACCOUNT_MODAL, {
                    showAnonymousOption: true,
                }),
            )
        }

        const fetcher = new StoriesAPI(payload)
        const request = payload.like
            ? fetcher.likeStory.bind(fetcher)
            : fetcher.unlikeStory.bind(fetcher)
        yield call(request)
        yield put(actionsStories.setLikedStory(payload.id, payload.like))
        yield put(actionsFavourites.setFavouritesLikedStory(payload.id, payload.like))
    } catch (e) {
        console.error('Catched Error in likeStory', e)
    }
}

export function* watchStories(): Generator {
    yield takeLatest('@/GET_STORY', getStory)
    yield takeLatest('@/GET_ALL_STORIES_ASYNC', getAllStories)
    yield takeLatest('@/STORIES/GET_STORIES_BY_AUTHOR', getStoriesByAuthor)
    yield takeLatest('@/STORIES/GET_STORIES_BY_COLLECTION', getStoriesByCollection)
    yield takeLatest('@/GET_NEAREST_STORIES', getNearestStories)
    yield takeLatest(
        '@/GET_NEATEST_STORIES_WITH_CHANGE_SEARCH_FRAGMENT',
        getNearestStoriesWithSearchChangeFragment,
    )
    yield takeLatest('@/GET_RECENT_STORIES_', getRecentStories)
    yield takeLatest(
        '@/GET_RECENT_STORIES_WITH_CHANGE_SEARCH_FRAGMENT',
        getRecentStoriesWithChangeSearchFragment,
    )
    yield takeLatest('@/LIKE_STORY', likeStory)
    yield takeLatest('@/GET_RELATED_STORIES', getRelatedStoriessData)
}
