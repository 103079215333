import React, { ReactElement, ReactNode } from 'react'

import { Dialog } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import { useStyles } from './styles'

type ModalWindowProps = {
    open: boolean
    onClose: () => void
    children: ReactNode
}
const ModalWindow = ({ open, onClose, children }: ModalWindowProps): ReactElement => {
    const styles = useStyles()

    return (
        <Dialog onClose={onClose} open={open} classes={{ paper: styles.paper, root: styles.root }}>
            <Close onClick={onClose} className={styles.closeButton} />
            {children}
        </Dialog>
    )
}

export default ModalWindow
