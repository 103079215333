import { makeStyles } from '@material-ui/core/styles'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() => ({
    registerText: {
        color: '#38d979',
        cursor: 'pointer',
        textAlign: 'center',
        paddingTop: '10px',
    },
    anonymousText: {
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'center',
        lineHeight: '150%',
        margin: '0',
        color: colors.black,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center',
        lineHeight: '28px',
        marginBottom: '0',
        color: colors.black,
    },
    errorLabel: {
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: '150%',
        marginBottom: '16px',
        color: colors.errorText,
    },
    activityIndicator: {
        margin: 10,
        textAlign: 'center',
    },
    successTitle: {
        margin: 0,
        color: colors.black,
    },
    textLabel: {
        fontSize: '14px',
        color: colors.black,
    },
}))
