import { AuthorFollowTypes } from '@/core/types/subscriptionsTypes/subscriptionTypes'

export const actionsSubscriptions = {
    getAuthorsSubscriptions: () =>
        ({
            type: '@/GET_AUTHORS_SUBSCRIPTIONS',
        } as const),
    setAuthorsSubscriptions: (payload: Array<AuthorFollowTypes>) =>
        ({
            type: '@/SET_AUTHORS_SUBSCRIPTIONS',
            payload,
        } as const),
    startLoadingAuthorsSubscriptions: () =>
        ({
            type: '@/START_LOADING_AUTHORS_SUBSCRIPTIONS',
        } as const),
    stopLoadingAuthorsSubscriptions: () =>
        ({
            type: '@/STOP_LOADING_AUTHORS_SUBSCRIPTIONS',
        } as const),
    setAuthorsSubscriptionsError: (payload: string) =>
        ({
            type: '@/SET_AUTHORS_SUBSCRIPTIONS_ERROR',
            payload,
        } as const),
}
