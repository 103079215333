import { ReturnAnyActions } from '../../types/types'
import { actionsGeneral } from './actions'
import { LocalStorage } from '@/core/constants/localStorage'

export const initialState = {
    appInstallRequestedFlag: false,

    modalType: '',
    modalProps: {},

    continueAnonymouslyConfirmed: false,

    isMapView: false,
}

export type State = typeof initialState

export type GeneralActions = ReturnAnyActions<typeof actionsGeneral>

export const reducer = (state: State = initialState, action: GeneralActions): State => {
    switch (action.type) {
        case '@/SET_APP_INSTALL_FLAG':
            localStorage.setItem(
                LocalStorage.AppInstallRequested,
                Boolean(action.payload).toString(),
            )
            return {
                ...state,
                appInstallRequestedFlag: action.payload,
            }
        case '@/SET_CONTINUE_ANONYMOUSLY_FLAG':
            localStorage.setItem(
                LocalStorage.ContinueAnonymouslyConfirmed,
                Boolean(action.payload).toString(),
            )
            return {
                ...state,
                continueAnonymouslyConfirmed: action.payload,
            }
        case '@/SHOW_MODAL':
            return {
                ...state,
                modalType: action.payload.type,
                modalProps: action.payload.props,
            }
        case '@/HIDE_MODAL':
            return {
                ...state,
                modalType: '',
                modalProps: {},
            }
        case '@/SET_MAP_VIEW':
            return {
                ...state,
                isMapView: action.payload,
            }
        case '@/LOG_OUT_USER_RESPONSE_SUCCESS':
            return {
                ...initialState,
                appInstallRequestedFlag: false,
            }
        default:
            return state
    }
}
