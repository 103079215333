import React, { ReactElement, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useScroll } from '@/core/hooks/useScroll'

import { newCollectionsPreviewTypes } from '@/core/types/CollectionsTypes'
import { SingleStoryDataType } from '@/core/types/stories/storiesTypes'

import { useStyles } from './styles'

type DataType = {
    loading: boolean
    loaded: boolean
    data: Array<SingleStoryDataType | newCollectionsPreviewTypes>
    error: string | null
}

type RelatedContentType = {
    title?: string
    data: DataType
    languages: any
    RenderComponent: any
    componentDataProp: string
    renderComponentProps?: any
    fetchData: any
    clearData: any
}

const InfinityScrollList = ({
    title,
    data,
    languages,
    RenderComponent,
    componentDataProp,
    renderComponentProps = {},
    fetchData,
    clearData,
}: RelatedContentType): ReactElement => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const endRef = useRef<any>()

    const { flushPageNumber } = useScroll({
        parentRef: null,
        childRef: endRef,
        loading: data.loading || data.loaded || !languages.length,
        callback: fetchData,
    })

    useEffect(() => {
        return () => {
            flushPageNumber()
            dispatch(clearData)
        }
    }, [])

    return (
        <div>
            {title && <h2 className={styles.header}>{title}</h2>}
            <RenderComponent
                isLoading={data.loading}
                {...{ [componentDataProp]: data.data }}
                {...renderComponentProps}
            />
            <div ref={endRef} style={{ height: '30px' }} />
        </div>
    )
}

export default InfinityScrollList
