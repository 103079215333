import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import DirectionsBike from '@material-ui/icons/DirectionsBike'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import DirectionsWalk from '@material-ui/icons/DirectionsWalk'

export const COLLLECTION_TYPE_TITLE_MAP = [
    '#ForBrowsingCollectionTypeTitle',
    '#WalkCollectionTypeTitle',
    '#ByCarCollectionTypeTitle',
    '#ByBicycleCollectionTypeTitle',
    '#MixedCollectionTypeTitle',
]

export const COLLECTION_TYPE_ICON_MAP = [
    ImportContactsIcon,
    DirectionsWalk,
    DirectionsCar,
    DirectionsBike,
    DirectionsCar,
]
