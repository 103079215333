import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    carouselWrapper: {
        position: 'relative',
    },
    imageContainer: {
        '& > img': {
            width: '100%',
        },
    },
    button: {
        cursor: 'pointer',
        border: 'none',
        background: 'transparent',
        '&:disabled': {
            opacity: '0.5',
        },
    },
    buttonPrev: {
        transform: 'rotate(-180deg)',
    },
    controlButtonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        zIndex: 1,
        bottom: '16px',
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
}))
