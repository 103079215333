import React, { FC, ReactElement } from 'react'
import { Props } from './types'
import FreeContent from '@/components/controls/CardComponent/FreeContent'
import PaidContent from '@/components/controls/CardComponent/PaidContent'

const Card: FC<Props> = ({ ...props }: Props): ReactElement => {
    return <>{!props.paid ? <FreeContent {...props} /> : <PaidContent {...props} />}</>
}

export { Card }
