import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperMobile: {
        margin: 0,
        maxHeight: '100%',
        backgroundColor: theme.palette.secondary.main,
        height: '100%',
        width: '100%',
    },
    root: {
        position: 'absolute',
        color: 'white',
    },
    backDrop: {
        position: 'absolute',
    },
    formGroupWrapper: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid #5F5F5F',
    },

    photoUser: {
        width: '90px',
        height: '90px',
        borderRadius: '50%',
    },

    photoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '20px',
        flexDirection: 'column',
        height: '150px',
        color: 'white',
    },

    addPhoto: {
        position: 'absolute',
        // top: '200px',
        left: '70px',
        width: '50%',
        height: '14px',
        opacity: 0,
        cursor: 'pointer',
    },

    buttonStyle: {
        marginTop: '25px',
        marginBottom: '10px',
    },

    errorText: {
        height: theme.spacing(2),
        fontSize: '12px',
        paddingTop: theme.spacing(4),
        color: theme.palette.error.dark,
    },
}))
