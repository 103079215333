import React from 'react'
import ReactDOM from 'react-dom'
import AppContainer from './containers/AppContainer'
import { Provider } from 'react-redux'
import { store } from '@/core/store/store'
import GA4React from 'ga-4-react'

const ga4react = new GA4React('G-NP2KGTY935')

;(async () => {
    await ga4react
        .initialize()
        .then(() => console.log('Analytics Success.'))
        .catch(() => console.log('Analytics Failure.'))
        .finally(() => {
            ReactDOM.render(
                <Provider store={store}>
                    <AppContainer />
                </Provider>,
                document.getElementById('root'),
            )
        })
})()
