import colors from '@/core/theme/colors'
import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        cardContainer: {
            background:
                'linear-gradient(120.58deg, #8E3FDC 31.99%, #8321E4 71.17%, #17048C 99.53%)',
            padding: '24px',
            borderRadius: '24px',
            margin: '16px 0',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '24px',
            '& h2': {
                margin: '0',
                fontSize: '24px',
            },
        },
        socialIconsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '24px',
        },
        socialIcon: {
            color: colors.mainText,
            fontSize: '45px',
            lineHeight: '30px',
            '&:hover': {
                color: colors.darkGrey,
            },
        },
    }),
)
