import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useStyles } from './styles'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useInput } from '@/components/controls/TextInput/useInputHook'
import { Typography } from '@material-ui/core'
import { Button } from '@/components/controls/Button'
import { FormattedMessage } from 'react-intl'
import ClipLoader from 'react-spinners/ClipLoader'
import { TextInput } from '@/components/controls/TextInput/TextInput'
import {
    actionsMagicLinks,
    getErrorOccured,
    getErrorText,
    getLoading,
    getSuccess,
    getResetPasswordErrorType,
} from '@/core/store/magicLinks'
import { actionsAuth, getUserProfile } from '@/core/store/auth'
import {
    ResetPasswordResponseEnumType,
    ResetPasswordType,
    UserRole,
} from '@/core/types/auth/authTypes'

const ResetPasswordCommitContainer: FC = (): ReactElement => {
    const styles = useStyles()
    const translate = useTranslate()
    const history = useHistory()
    const errorOccured = useSelector(getErrorOccured)
    const resetErrorText = useSelector(getErrorText)
    const resetPasswordErrorType = useSelector(getResetPasswordErrorType)
    const loading = useSelector(getLoading)
    const success = useSelector(getSuccess)
    const userProfile = useSelector(getUserProfile)
    const [errorText, setErrorText] = useState('')
    const [invalidLinkError, setInvalidLinkError] = useState(false)
    const dispatch = useDispatch()
    const { token } = useParams<{ token: string }>()
    const password = useInput('')
    const confirm = useInput('')

    useEffect(() => {
        if (errorOccured) {
            switch (resetPasswordErrorType) {
                case ResetPasswordResponseEnumType.Success:
                    setErrorText(resetErrorText)
                    break
                case ResetPasswordResponseEnumType.ShortPassword:
                    setErrorText(translate('#PasswordLengthText'))
                    break
                case ResetPasswordResponseEnumType.PasswordNotMatch:
                    setErrorText(translate('#NotEqualsText'))
                    break
                case ResetPasswordResponseEnumType.InvalidLink:
                    setErrorText(translate('#InvalidLinkText'))
                    setInvalidLinkError(true)
                    break
                default:
                    setErrorText(resetErrorText)
                    break
            }
        }
    }, [errorOccured])

    useEffect(() => {
        if (success) {
            dispatch(actionsAuth.getUserProfile())
        }
    }, [success])

    useEffect(() => {
        if (userProfile !== null && userProfile.role !== UserRole.Default) {
            history.push('/')
        }
    }, [userProfile])

    const resetPasswordClicked = () => {
        const data: ResetPasswordType = {
            token: token,
            password: password.inputValue,
            confirm: confirm.inputValue,
        }
        dispatch(actionsMagicLinks.resetPassword(data))
    }

    return (
        <div className={styles.centerDiv}>
            {!loading && !invalidLinkError && (
                <>
                    <TextInput
                        type="password"
                        placeholder={translate('#PasswordPlaceholderText')}
                        value={password.inputValue}
                        handleInputChange={password.handleInputChange}
                    />
                    <TextInput
                        type="password"
                        placeholder={translate('#ConfirmPlaceholderText')}
                        value={confirm.inputValue}
                        handleInputChange={confirm.handleInputChange}
                    />
                </>
            )}
            {errorOccured && !invalidLinkError && (
                <Typography className={styles.errorLabel}>{errorText}</Typography>
            )}
            {errorOccured && invalidLinkError && (
                <Typography variant={'h1'} align={'center'}>
                    {errorText}
                </Typography>
            )}
            {!loading && !invalidLinkError && (
                <Button
                    title={translate('#ResetPasswordText')}
                    style={{ marginTop: 10 }}
                    onClick={resetPasswordClicked}
                />
            )}

            {loading && (
                <>
                    <div className={styles.activityIndicator}>
                        <ClipLoader color={'white'} />
                    </div>
                </>
            )}
        </div>
    )
}

export default ResetPasswordCommitContainer
