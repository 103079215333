import { ReturnAnyActions } from '../../types/types'
import { actionsLanguage } from './actions'
import { LocalStorage } from '@/core/constants/localStorage'
import { LangTypesState } from '@/core/types/languages'

export const initialState = {
    languages: {} as LangTypesState,
    interfaceLanguageCode: 'en' as string,
    dataLanguageCodes: [] as Array<string>,
}

export type State = typeof initialState

export type LanguageActions = ReturnAnyActions<typeof actionsLanguage>

export const reducer = (state: State = initialState, action: LanguageActions): State => {
    switch (action.type) {
        case '@/SET_INTERFACE_LANGUAGE_DATA': {
            const { code } = action.payload
            return {
                ...state,
                languages: {
                    ...state.languages,
                    [code]: action.payload,
                },
            }
        }
        case '@/SET_INTERFACE_LANGUAGE_CODE': {
            localStorage.setItem(LocalStorage.InterfaceLanguage, action.payload)

            return {
                ...state,
                interfaceLanguageCode: action.payload,
            }
        }
        case '@/SET_DATA_LANGUAGES_CODES': {
            localStorage.setItem(LocalStorage.DataLanguages, JSON.stringify(action.payload))

            return {
                ...state,
                dataLanguageCodes: action.payload,
            }
        }
        default: {
            return state
        }
    }
}
