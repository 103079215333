import React, { FC, ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import CollectionList from '@/components/CollectionList'
import StoriesList from '@/components/StoriesList'
import InterestsBox from '@/components/InterestsBox'
import PermissionsDriver from '@/components/PermissionsDriver'

import Spinner from '@/components/controls/Spinner'
import PageLayout from '@/components/PageLayout'
import FilledTabs from '@/components/controls/FilledTabs'
import InfinityScrollList from '@/components/InfinityScrollList'

import { actionsCollection, getNearesCollections } from '@/core/store/collection'
import { actionsStories, getNearestStories, getRecentStories } from '@/core/store/stories'
import { getInterests, getInterestsFetchError } from '@/core/store/interests'
import { getDataLanguagesCodes } from '@/core/store/language'
import { requestsScrollingData } from '@/core/types/types'
import { getUserCoordinates } from '@/core/store/map'

import { getNameBySlugName } from '@/core/helpers/getNameBySlugName'

const InterestPage: FC = (): ReactElement => {
    const history = useHistory()
    const { slugName } = useParams<{ slugName: string }>()

    const [tab, setTab] = React.useState(0)
    const [type, setType] = React.useState(1)

    const languages = useSelector(getDataLanguagesCodes)
    const interests = useSelector(getInterests)
    const interestsFetchError = useSelector(getInterestsFetchError)
    const userCoordinates = useSelector(getUserCoordinates)

    const nearestCollections = useSelector(getNearesCollections)
    const nearestStories = useSelector(getNearestStories)
    const recentStories = useSelector(getRecentStories)

    const interestNameLowerCase = getNameBySlugName(slugName)
    const currInterest = interests.find(
        (interest: any) => interest.name.toLowerCase() === interestNameLowerCase,
    )

    useEffect(() => {
        if (
            interestsFetchError ||
            nearestCollections.error ||
            nearestStories.error ||
            recentStories.error
        ) {
            throw new Error('Error fetching data for Interest Page')
        }
    }, [interestsFetchError, nearestCollections.error, nearestStories.error, recentStories.error])

    const fetchNearesCollections = (data: requestsScrollingData) =>
        actionsCollection.getNearestCollections({
            languages,
            ...data,
            searchFragment: `interest:"${currInterest?.name}"`,
        })
    const fetchNearestStories = (data: requestsScrollingData) =>
        actionsStories.getNearestStories({
            languages,
            ...data,
            searchFragment: `interest:"${currInterest?.name}"`,
        })
    const fetchRecentStories = (data: requestsScrollingData) =>
        actionsStories.getRecentStories({
            languages,
            ...data,
            searchFragment: `interest:"${currInterest?.name}"`,
        })

    const handleTabChange = (newValue: number) => {
        if (newValue === 0) setType(1)
        setTab(newValue)
    }
    const handleTypeChange = (newValue: number) => {
        setType(newValue)
    }
    const handleCategoryClick = (newSlugName: string) => {
        if (slugName === newSlugName) {
            history.push('/')
        } else {
            history.push(`/interest/${newSlugName}`)
        }
    }

    const title = `Reveal World | ${currInterest?.name}`

    const interestsPageContent = (
        <>
            {tab === 0 && type === 1 && (
                <InfinityScrollList
                    key={`${currInterest?.name}tab${tab}type${type}`}
                    data={nearestCollections}
                    languages={languages}
                    RenderComponent={CollectionList}
                    renderComponentProps={{ showDonateCard: true }}
                    componentDataProp="collections"
                    fetchData={fetchNearesCollections}
                    clearData={actionsCollection.clearCollectionsData('nearestCollections')}
                />
            )}
            {tab === 1 && type === 0 && (
                <InfinityScrollList
                    key={`${currInterest?.name}tab${tab}type${type}`}
                    data={recentStories}
                    languages={languages}
                    RenderComponent={StoriesList}
                    renderComponentProps={{ showDonateCard: true }}
                    componentDataProp="stories"
                    fetchData={fetchRecentStories}
                    clearData={actionsStories.clearStories('recentStories')}
                />
            )}
            {tab === 1 && type === 1 && (
                <InfinityScrollList
                    key={`${currInterest?.name}tab${tab}type${type}`}
                    data={nearestStories}
                    languages={languages}
                    RenderComponent={StoriesList}
                    renderComponentProps={{ showDonateCard: true }}
                    componentDataProp="stories"
                    fetchData={fetchNearestStories}
                    clearData={actionsStories.clearStories('nearestStories')}
                />
            )}
        </>
    )

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={title} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={title} />
                <meta property="fb:app_id" content="418921822030203" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={title} />
            </Helmet>
            {type === 1 && <PermissionsDriver />}
            {currInterest ? (
                <>
                    <PageLayout greyBody>
                        <InterestsBox
                            data={interests}
                            handleInputChange={handleCategoryClick}
                            selectedInterestId={currInterest.id}
                        />
                    </PageLayout>
                    <PageLayout>
                        <FilledTabs
                            onTabChange={handleTabChange}
                            onTypeChange={handleTypeChange}
                            showTypes
                            defaultType={1}
                        />
                        {type === 1 ? (
                            userCoordinates.isSet ? (
                                interestsPageContent
                            ) : (
                                <Spinner />
                            )
                        ) : (
                            interestsPageContent
                        )}
                    </PageLayout>
                </>
            ) : (
                <Spinner />
            )}
        </>
    )
}

export default InterestPage
