import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    tagsButton: {
        padding: '4px 8px',
        borderRadius: '10px',
        display: 'inline-block',
        cursor: 'pointer',
        userSelect: 'none',
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: '16px',
        textDecoration: 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    collectionButton: {
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'none',
            background: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        },
    },
    darkMode: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.secondary.main,
        },
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '16px',
        margin: '16px 0',
    },
}))
