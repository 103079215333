import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        backgroundColor: theme.palette.secondary.main,
        minHeight: '40%',
    },
    paperMobile: {
        margin: 0,
        maxHeight: '100%',
        backgroundColor: theme.palette.secondary.main,
        height: '100%',
        width: '100%',
    },
    root: {
        position: 'absolute',
    },
    rootContentMobile: {
        padding: 0,
    },
    rootContent: {
        padding: '8 24px',
    },
    contentMobile: {
        overflowY: 'hidden',
    },
    backDrop: {
        position: 'absolute',
    },
    button: {
        backgroundColor: theme.palette.success.main,
    },
    controlItem: {
        height: '70px',
        display: 'block',
        position: 'relative',
        margin: 0,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    checkboxItem: {
        padding: 0,
    },
    spanItem: {
        height: '70px',
        overflow: 'hidden',
        display: 'block',
        borderRadius: '5px',
        position: 'relative',
        border: `2px solid transparent`,
    },
    spanItemChecked: {
        border: `2px solid ${theme.palette.success.main}`,
    },
    image: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    label: {
        position: 'absolute',
        zIndex: 999,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: '#fff',
    },
}))
