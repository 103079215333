import { BaseAPI } from '../index'

export class AuthAPI<T> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async signUpDefaultUser() {
        try {
            return await this.requestService.post(`/auth/signup`, { languageCode: '' }, true)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async signInCredentials() {
        try {
            return await this.requestService.post(`/auth/signin/credentials`, this.data, true)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async signOut() {
        try {
            return await this.requestService.get(`/auth/signout`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async getProfile() {
        try {
            return await this.requestService.get(`/auth/profile`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async magicLinkLoginConfirm() {
        try {
            return await this.requestService.post(`/rest/auth/signin/confirm`, this.data, true)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async magicLinkRegistrationConfirm() {
        try {
            return await this.requestService.post(`rest/auth/signup/confirm`, this.data, true)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async credentialsActivation() {
        try {
            return await this.requestService.post(`/rest/auth/activate`, this.data, true)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async resetPassword() {
        try {
            return await this.requestService.post(`/auth/resetpassword`, this.data, true)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async sendAuthMagicLink() {
        try {
            return await this.requestService.post(`/rest/auth/sendlink`, this.data, true)
        } catch (e) {
            console.log(e)
            throw Reflect.construct(Error, [e])
        }
    }
}
