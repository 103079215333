import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            margin: '16px 0',
            background: 'white',
            borderRadius: '10px',
            color: 'black',
            overflow: 'hidden',
            position: 'relative',
            height: '500px',
        },
        paidOverlay: {
            height: '500px',
            position: 'absolute',
            bottom: 0,
            zIndex: 9999,
            width: '100%',
            background: 'linear-gradient( 0deg , rgba(255,255,255,1) 65%, rgba(253,187,45,0) 100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
        },
        mobileCard: {
            margin: '16px 0',
        },
        imageWrapper: {
            width: '100%',
            height: '100%',
            position: 'relative',
        },
        image: {
            width: '100%',
            height: '100%',
        },
    }),
)
