import React, { FC, ReactElement, useState, useEffect } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import clsx from 'clsx'

import { IconButton, Drawer } from '@material-ui/core'
import { Button } from '@/components/controls/Button'
import MenuIcon from '@material-ui/icons/Menu'
import AccountIcon from '@material-ui/icons/AccountCircle'
import Close from '@material-ui/icons/Close'
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen'
import Logo from '@/components/Logo'

import { actionsAuth, getUserProfile } from '@/core/store/auth'
import { actionsGeneral } from '@/core/store/general'

import { IBeforeInstallPromptEvent } from '@/core/types/types'
import { userMenu } from '@/core/constants/menuItems'

import { useStyles } from './styles'

const MENU_ITEM_HANDLER_MAP = {
    modal: ({ data, dispatch }: any) => {
        if (!data) return

        dispatch(actionsGeneral.showModal(data, {}))
    },
    link: ({ data }: any) => {
        window.open(data, '_blank')
    },
    logout: ({ dispatch }: any) => {
        dispatch(actionsAuth.logOutUserAsync())
        setTimeout(window.location.reload, 0)
    },
}

const BasicMenu: FC = (): ReactElement => {
    const translate = useTranslate()
    const dispatch = useDispatch()
    const [open, setIsOpen] = useState(false)
    const isMobile = useMediaQuery({ maxWidth: 600 })

    const userProfile = useSelector(getUserProfile)
    const styles = useStyles({ isMobile })

    const handleClick = () => {
        setIsOpen(true)
    }
    const handleClose = () => {
        setIsOpen(false)
    }

    const [installPrompt, setInstallPrompt] = useState<IBeforeInstallPromptEvent | null>(null)
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e: any) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault()
            // Stash the event so it can be triggered later.
            setInstallPrompt(e)
        })
    }, [])

    const MenuBtnIcon = userProfile ? AccountIcon : MenuIcon

    return (
        <>
            <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={styles.menuBtn}
            >
                <MenuBtnIcon fontSize="medium" color="secondary" />
            </IconButton>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                classes={{ paper: styles.root }}
            >
                <div>
                    <div className={styles.headerContainer}>
                        <Logo />
                        <Close className={styles.closeIcon} onClick={handleClose} />
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.menuContainer}>
                        {userMenu.map(({ label, data, type }) => (
                            <div
                                key={label}
                                onClick={() => {
                                    MENU_ITEM_HANDLER_MAP[type]({ data, dispatch })
                                    handleClose()
                                }}
                                className={clsx(styles.menuItem, {
                                    [styles.smallFontSize]: [
                                        'PrivacyPolicy',
                                        'TermsOfUse',
                                    ].includes(label),
                                })}
                            >
                                {translate(`#${label}`)}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    {!!installPrompt && (
                        <div
                            onClick={() => {
                                installPrompt.prompt()
                                setInstallPrompt(null)
                                handleClose()
                            }}
                            className={styles.menuItem}
                        >
                            <AddToHomeScreenIcon /> {translate(`#AddToHomeScreenBtn`)}
                        </div>
                    )}
                    {!!userProfile && (
                        <div className={styles.logOutWrapper}>
                            <Button
                                title={translate('#LogOutText')}
                                type="outlined"
                                color="dark"
                                fullWidth
                                onClick={() => {
                                    MENU_ITEM_HANDLER_MAP.logout({ dispatch })
                                    handleClose()
                                }}
                            />
                        </div>
                    )}
                </div>
            </Drawer>
        </>
    )
}

export default BasicMenu
