import { ReturnAnyActions } from '../../types/types'
import { actionsAuth } from './actions'
import { DefaultUserTypes, ProfileTypes } from '@/core/types/auth/authTypes'
import { LocalStorage } from '@/core/constants/localStorage'

export const initialState = {
    defaultUser: null as DefaultUserTypes | null,
    loading: false,
    error: null as string | null,
    profile: null as ProfileTypes | null,
    tokenExists: false,
    tokenChecked: false,
}

export type State = typeof initialState

export type UserGroupAnyActions = ReturnAnyActions<typeof actionsAuth>
export const reducer = (state: State = initialState, action: UserGroupAnyActions): State => {
    switch (action.type) {
        case '@/SET_TOKEN_EXISTS':
            return {
                ...state,
                tokenExists: action.payload,
            }
        case '@/SET_TOKEN_CHECKED':
            return {
                ...state,
                tokenChecked: action.payload,
            }
        case '@/START_LOADING':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_LOADING':
            return {
                ...state,
                loading: false,
            }
        case '@/FILL_DEFAULT_USER_RESPONSE_SUCCESS':
            return {
                ...state,
                error: null,
                defaultUser: action.payload.data,
            }
        case '@/FILL_DEFAULT_USER_RESPONSE_FALL':
            return {
                ...state,
                error: action.payload,
            }
        case '@/FILL_PROFILE_RESPONSE_SUCCESS':
            localStorage.setItem(LocalStorage.UserProfile, JSON.stringify(action.payload))
            return {
                ...state,
                error: null,
                profile: action.payload,
            }
        case '@/FILL_SIGN_IN_SUCCESS':
            {
                const { success } = action.payload.data
                success &&
                    localStorage.setItem(
                        LocalStorage.AccessToken,
                        action.payload.headers['x-auth-token'],
                    )
                localStorage.removeItem(LocalStorage.DefaultUserToken)
            }
            return state
        case '@/LOG_OUT_USER_RESPONSE_SUCCESS':
            localStorage.removeItem(LocalStorage.AccessToken)
            localStorage.removeItem(LocalStorage.DefaultUserToken)
            localStorage.removeItem(LocalStorage.UserProfile)
            localStorage.removeItem(LocalStorage.InterfaceLanguage)
            localStorage.removeItem(LocalStorage.DataLanguages)
            localStorage.removeItem(LocalStorage.AppInstallRequested)
            localStorage.removeItem(LocalStorage.ContinueAnonymouslyConfirmed)
            return { ...initialState }
        default:
            return state
    }
}
