import { ReturnAnyActions } from '../../types/types'
import { actionsMagicLinks } from './actions'
import { LocalStorage } from '@/core/constants/localStorage'
import {
    RegistrationResponseEnumType,
    ResetPasswordResponseEnumType,
} from '@/core/types/auth/authTypes'

type MagicLinkStateType = {
    loading: boolean
    success: boolean
    redirectUrl: string
    errorOccured: boolean
    errorText: string
    registrationErrorType: RegistrationResponseEnumType
    registrationErrorText: string
    resetPasswordErrorType: ResetPasswordResponseEnumType
}

export const initialState: MagicLinkStateType = {
    loading: false,
    success: false,
    redirectUrl: '',
    errorOccured: false,
    errorText: '',

    registrationErrorText: '',
    registrationErrorType: RegistrationResponseEnumType.Success,
    resetPasswordErrorType: ResetPasswordResponseEnumType.Success,
}

export type MaginLinksAnyActions = ReturnAnyActions<typeof actionsMagicLinks>
export const reducer = (
    state: MagicLinkStateType = initialState,
    action: MaginLinksAnyActions,
): MagicLinkStateType => {
    switch (action.type) {
        // VERIFY LOGIN LINKVERIFY_REGISTRATION_LINK
        case '@/VERIFY_LOGIN_LINK':
            return {
                ...state,
                errorOccured: false,
                errorText: '',
                success: false,
            }
        case '@/START_VERIFY_LOGIN_LINK':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_VERIFY_LOGIN_LINK':
            return {
                ...state,
                loading: false,
            }
        case '@/VERIFY_LOGIN_LINK_SUCCESS':
            if (action.payload.data.success === true) {
                localStorage.setItem(
                    LocalStorage.AccessToken,
                    action.payload.headers['x-auth-token'],
                )
                localStorage.removeItem(LocalStorage.DefaultUserToken)
                return {
                    ...state,
                    errorOccured: false,
                    success: true,
                    redirectUrl: action.payload.data.redirectUrl,
                }
            } else {
                return {
                    ...state,
                    errorOccured: true,
                    errorText: action.payload.data.message,
                }
            }
        case '@/VERIFY_LOGIN_LINK_FAILURE':
            return {
                ...state,
                errorOccured: true,
                errorText: action.payload,
            }

        // VERIFY REGISTRATION LINK
        case '@/VERIFY_REGISTRATION_LINK':
            return {
                ...state,
                errorOccured: false,
                errorText: '',
                success: false,
            }
        case '@/START_VERIFY_REGISTRATION_LINK':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_VERIFY_REGISTRATION_LINK':
            return {
                ...state,
                loading: false,
            }
        case '@/VERIFY_REGISTRATION_LINK_SUCCESS':
            if (action.payload.data.success === true) {
                localStorage.setItem(
                    LocalStorage.AccessToken,
                    action.payload.headers['x-auth-token'],
                )
                localStorage.removeItem(LocalStorage.DefaultUserToken)
                return {
                    ...state,
                    errorOccured: false,
                    success: true,
                    redirectUrl: action.payload.data.redirectUrl,
                }
            } else {
                return {
                    ...state,
                    errorOccured: true,
                    errorText: action.payload.data.message,
                }
            }
        case '@/VERIFY_REGISTRATION_LINK_FAILURE':
            return {
                ...state,
                errorOccured: true,
                errorText: action.payload,
            }

        // VERIFY REGISTRATION PASSWORD LINK
        case '@/VERIFY_REGISTRATION_PASSWORD_LINK':
            return {
                ...state,
                errorOccured: false,
                errorText: '',
                success: false,
                registrationErrorType: RegistrationResponseEnumType.Success,
            }
        case '@/START_VERIFY_REGISTRATION_PASSWORD_LINK':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_VERIFY_REGISTRATION_PASSWORD_LINK':
            return {
                ...state,
                loading: false,
            }
        case '@/VERIFY_REGISTRATION_PASSWORD_LINK_SUCCESS':
            if (action.payload.data.success === true) {
                localStorage.setItem(
                    LocalStorage.AccessToken,
                    action.payload.headers['x-auth-token'],
                )
                localStorage.removeItem(LocalStorage.DefaultUserToken)
                return {
                    ...state,
                    errorOccured: false,
                    success: true,
                }
            } else {
                return {
                    ...state,
                    errorOccured: true,
                    registrationErrorType: action.payload.data.responseType,
                }
            }
        case '@/VERIFY_REGISTRATION_PASSWORD_LINK_FAILURE':
            return {
                ...state,
                errorOccured: true,
                errorText: action.payload,
            }

        // RESET PASSWORD
        case '@/RESET_PASSWORD':
            return {
                ...state,
                errorOccured: false,
                errorText: '',
                success: false,
                registrationErrorType: RegistrationResponseEnumType.Success,
                registrationErrorText: '',
                resetPasswordErrorType: ResetPasswordResponseEnumType.Success,
            }
        case '@/START_RESET_PASSWORD':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_RESET_PASSWORD':
            return {
                ...state,
                loading: false,
            }
        case '@/RESET_PASSWORD_SUCCESS':
            if (action.payload.data.success === true) {
                localStorage.setItem(
                    LocalStorage.AccessToken,
                    action.payload.headers['x-auth-token'],
                )
                localStorage.removeItem(LocalStorage.DefaultUserToken)
                return {
                    ...state,
                    errorOccured: false,
                    success: true,
                    resetPasswordErrorType: ResetPasswordResponseEnumType.Success,
                }
            } else {
                return {
                    ...state,
                    errorOccured: true,
                    resetPasswordErrorType: action.payload.data.responseType,
                }
            }
        case '@/RESET_PASSWORD_FAILURE':
            return {
                ...state,
                errorOccured: true,
                errorText: action.payload,
            }

        // AUTHORIZATION
        case '@/SEND_AUTH_LINK':
            return {
                ...state,
                errorOccured: false,
                errorText: '',
                success: false,
            }
        case '@/START_SEND_AUTH_LINK':
            return {
                ...state,
                loading: true,
            }
        case '@/STOP_SEND_AUTH_LINK':
            return {
                ...state,
                loading: false,
            }
        case '@/SEND_AUTH_LINK_SUCCESS':
            if (action.payload.data.success === true) {
                return {
                    ...state,
                    errorOccured: false,
                    success: true,
                }
            } else {
                return {
                    ...state,
                    errorOccured: true,
                    errorText: action.payload.data.message,
                }
            }
        case '@/SEND_AUTH_LINK_FAILURE':
            return {
                ...state,
                errorOccured: true,
                errorText: action.payload,
            }

        case '@/FLUSH_MAGIC_LINK_STATE':
            return {
                ...initialState,
            }

        default:
            return state
    }
}
