import React from 'react'

import CarouselWrapper from '@/components/controls/CarouselWrapper'
import Waveform from '@/components/controls/Waveform'
import { ActionButtons } from '@/components/ActionsButtons'

import { useStyles } from './styles'

const StoryContent = ({ id, picture, audio, content, slugName, liked, author = {} }: any) => {
    const styles = useStyles()

    return (
        <div className={styles.storyContent}>
            {!!picture?.length && <CarouselWrapper images={picture} />}
            <div className={styles.body}>
                {audio && <Waveform src={audio} />}
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <ActionButtons
                    photoUrl={author.avatar}
                    authorName={author.name}
                    authorId={author.id}
                    type={'story'}
                    id={id}
                    slugName={slugName}
                    liked={liked}
                    subscribed={author.subscribed}
                />
            </div>
        </div>
    )
}

export default StoryContent
