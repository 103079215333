import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalTypes, ModalPropsType } from '@/core/constants/modal'

import { actionsGeneral, getModalType } from '@/core/store/general'
import { actionsMap, getUserCoordinates } from '@/core/store/map'

import { requestGeolocation } from '@/core/helpers/requestGeolocation'

const PermissionsDriver = (): null => {
    const dispatch = useDispatch()

    const currModalType = useSelector(getModalType)
    const userCoordinates = useSelector(getUserCoordinates)

    useEffect(() => {
        if (currModalType || userCoordinates.isRequested || userCoordinates.isSet) return

        const permissionsModalOptions: ModalPropsType = {
            afterCloseModal: () => {
                dispatch(actionsMap.setUserPositionRequested(true))
                requestGeolocation((origin: any) => dispatch(actionsMap.setUserPosition(origin)))
            },
        }

        dispatch(actionsGeneral.showModal(ModalTypes.PERMISSIONS_MODAL, permissionsModalOptions))
    }, [currModalType, userCoordinates.isRequested, userCoordinates.isSet])

    return null
}

export default PermissionsDriver
