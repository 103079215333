import React, { FC } from 'react'
import { SingleStoryDataType } from '@/core/types/stories/storiesTypes'
import { Card } from '../controls/CardComponent'
import { Typography } from '@material-ui/core'
import CollectionLinks from '@/components/CollectionLinks'
import PaidCollectionCard from '../PaidCollectionCard'
import DonateCard from '@/components/DonateCard'
import TagsWrapper from '../TagsWrapper'
import Spinner from '@/components/controls/Spinner'

type StoriesListProps = {
    stories: SingleStoryDataType[]
    showPaidCollectionCard?: boolean
    showDonateCard?: boolean
    isLoading?: boolean
}

const StoriesList: FC<StoriesListProps> = (props: StoriesListProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {props.stories.map(
                (
                    {
                        id: storyID,
                        picture,
                        liked,
                        author,
                        name,
                        teaser,
                        address,
                        collections,
                        interests,
                        slugName,
                    },
                    idx,
                ) => (
                    <div key={storyID}>
                        {idx === 1 && props.showPaidCollectionCard && <PaidCollectionCard />}
                        {idx === 1 && props.showDonateCard && <DonateCard />}
                        <Card
                            key={storyID}
                            picture={picture[0]}
                            title={name}
                            authorId={author.id}
                            authorName={author.name}
                            photoAuthorUrl={author.avatar}
                            type={'story'}
                            subscribed={author.subscribed}
                            liked={liked}
                            id={storyID}
                            eventId={storyID}
                            slugName={slugName}
                        >
                            <Typography variant="body1" paragraph>
                                {address}
                            </Typography>

                            {collections && collections.length > 0 && (
                                <CollectionLinks collections={collections} />
                            )}
                            {interests && interests.length > 0 && <TagsWrapper tags={interests} />}

                            <div dangerouslySetInnerHTML={{ __html: teaser }} />
                        </Card>
                    </div>
                ),
            )}
            {props.isLoading && <Spinner />}
        </div>
    )
}

export default StoriesList
