export default {
    background: '#FFF',
    mainText: '#FDFDFD',
    secondaryText: '#2A2A2A',
    secondaryBackground: '#2A2A2A',
    button: '#3fdc7f',
    buttonHover: '#36c470',
    buttonDisabled: '#7dc198',
    errorText: '#FF5757',
    brand: '#44C151',
    white: '#FFF',
    black: '#000',

    green: '#3FDC7F',
    deepGreen: '#20AC59',
    darkGreen: '#07772D',
    lightGreen: '#82F1AF',

    grey: '#B5BDBF',
    lightGrey: '#D5DBDD',
    lightGrey2: '#EBF0F2',
    darkGrey: '#1A1D1E',
    deepGrey: '#7E888A',

    gradientGreenBlue: 'linear-gradient(74.73deg, #3FDC7F 12.9%, #2BCAC3 71.57%, #21C1E4 100%)',
    gradientRedOrange: 'linear-gradient(45deg, #FF005C 0%, #FF7A00 100%)',
    gradientViolet: 'linear-gradient(301.52deg, #8E3FDC 1.56%, #5421E4 97.84%)',
}
