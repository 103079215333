import React, { ReactElement } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'

import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'

import { useStyles } from './styles'

const SocialIcons = (): ReactElement => {
    const styles = useStyles()
    const translate = useTranslate()

    return (
        <div className={styles.cardContainer}>
            <h2>{translate('#OurSocials')}</h2>
            <div className={styles.socialIconsContainer}>
                <a
                    href="https://www.instagram.com/reveal.world_official/"
                    target="_blank"
                    rel="nofollow"
                    className={styles.socialIcon}
                >
                    <InstagramIcon fontSize="inherit" />
                </a>
                <a
                    href="https://www.linkedin.com/company/reveal-world/"
                    target="_blank"
                    rel="nofollow"
                    className={styles.socialIcon}
                >
                    <LinkedInIcon fontSize="inherit" />
                </a>
                <a
                    href="https://www.facebook.com/revealdotworld"
                    target="_blank"
                    rel="nofollow"
                    className={styles.socialIcon}
                >
                    <FacebookIcon fontSize="inherit" />
                </a>
            </div>
        </div>
    )
}

export default SocialIcons
