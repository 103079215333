import { put, takeLatest, call } from 'redux-saga/effects'
import { uniqBy } from 'lodash'

import { MapAPI } from '@/core/api/mapAPI/mapAPI'

import { actionsMap } from './actions'

import { DefaultUserTypes } from '@/core/types/auth/authTypes'
import { requestAnyAction } from '@/core/types/types'
import { GetMarkersByIdsPayload, PointerType } from '@/core/types/map'

function* getAllMarkers({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    try {
        const fetcher = new MapAPI(payload)

        const fetch = fetcher.fetchAllPoints.bind(fetcher)
        const markers = (yield call(fetch)) as Array<PointerType>

        yield put(actionsMap.setAllMarkers(markers))
    } catch (e) {
        console.error('Catched Error in getAllMarkers', e)
    }
}

function* getMarkersByIds({ payload }: requestAnyAction<GetMarkersByIdsPayload>): Generator {
    try {
        const fetcher = new MapAPI(payload.requestData)

        const fetch = fetcher.fetchPointersByIds.bind(fetcher)
        const markers = (yield call(fetch)) as Array<PointerType>
        const uniqueMarkers = uniqBy(markers, 'slugName')

        yield put(actionsMap.setMarkersByIds({ target: payload.target, data: uniqueMarkers }))
    } catch (e) {
        console.error('Catched Error in getMarkersByIds', e)
    }
}

export function* watchMap(): Generator {
    yield takeLatest('@/GET_ALL_MARKERS', getAllMarkers)
    yield takeLatest('@/GET_MARKERS_BY_IDS', getMarkersByIds)
}
