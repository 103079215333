import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from './styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import WaveSurfer from 'wavesurfer.js'

const formWaveSurferOptions: any = (ref: any) => ({
    container: ref,
    waveColor: '#949494',
    progressColor: '#3FDC7F',
    cursorColor: 'transparent',
    cursorWidth: 0,
    barGap: 5,
    barHeight: 5,
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 50,
    normalize: true,
    partialRender: true,
    xhr: {
        mode: 'cors',
        requestHeaders: [
            { key: 'Access-Control-Allow-Origin', value: '*' },
            { key: 'X-AUTH-TOKEN', value: localStorage.getItem('access_token') },
        ],
    },
})

const Waveform = ({ src }: any) => {
    const styles = useStyles()
    const waveformRef = useRef(null)
    const wavesurfer: any = useRef(null)
    const [playing, setPlay] = useState(false)
    const [volume, setVolume] = useState(0.5)

    useEffect(() => {
        setPlay(false)

        const options = formWaveSurferOptions(waveformRef.current)
        wavesurfer.current = WaveSurfer.create(options)
        wavesurfer.current.load(src)

        wavesurfer.current.on('ready', function () {
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume)
                setVolume(volume)
            }
        })
        return () => wavesurfer.current.destroy()
    }, [src])

    const handlePlayPause = () => {
        setPlay(!playing)
        wavesurfer.current.playPause()
    }

    const onVolumeChange = (e: any) => {
        const { target } = e
        const newVolume = +target.value

        if (newVolume) {
            setVolume(newVolume)
            wavesurfer.current.setVolume(newVolume || 1)
        }
    }

    return (
        <div className={styles.waveformContainer}>
            <div id="waveform" className={styles.wave} ref={waveformRef} />
            <div className={styles.controls}>
                <button className={styles.playButton} onClick={handlePlayPause}>
                    {!playing ? (
                        <PlayArrowIcon htmlColor="#3fdc7f" fontSize="large" />
                    ) : (
                        <PauseIcon htmlColor="#3fdc7f" fontSize="large" />
                    )}
                </button>
            </div>
        </div>
    )
}

export default Waveform
