export const likesAndSubscriptionMapper = (state: any, id: number, like: boolean, type: string) => {
    return state.map((item: any) => {
        if (item.id === id) {
            return {
                ...item,
                [type]: like,
            }
        }
        return item
    })
}

export const reducerLikesAndSubscriptionMapper = (
    state: any,
    id: number,
    like: boolean,
    type: string,
) => ({
    ...state,
    data: likesAndSubscriptionMapper(state.data, id, like, type),
})
