import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        defPageStyle: {
            backgroundColor: theme.palette.secondary.main,
        },
        paper: {
            width: '368px',
            backgroundColor: theme.palette.secondary.main,
        },
        paperMobile: {
            margin: 0,
            padding: 0,
            maxHeight: '100%',
            backgroundColor: theme.palette.secondary.main,
            height: '100%',
            width: '100%',
        },
        root: {
            position: 'absolute',
            padding: 0,
        },
        carouselText: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            margin: '24px 10px 24px 10px',
        },
        indicatorStyles: {
            backgroundColor: 'red',
            height: '3px !important',
            borderRadius: '0px !important',
            width: '25%',
        },
        closeButton: {
            position: 'absolute',
            top: '18px',
            right: '18px',
            cursor: 'pointer',
            color: '#fff',
        },
        greenLink: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#12e643',
            cursor: 'pointer',
        },
        carouselIndicatorOl: {
            display: 'inline',
            listStyle: 'none',
            float: 'none',
            margin: 0,
            padding: 0,
        },
        carouselIndicator: {
            backgroundColor: '#fff',
            borderRadius: '50%',
            width: 8,
            height: 8,
            display: 'inline-block',
            margin: 5,
        },
        carouselIndicatorSelected: {
            backgroundColor: '#12e643',
            borderRadius: '50%',
            width: 8,
            height: 8,
            display: 'inline-block',
            margin: 5,
        },
    }),
)
