import { takeLatest } from 'redux-saga/effects'
import { makeRequestWithSpinner, OptionsType } from '../sagaWorkers/saga'
import { AuthorsSubscriptionsAPI } from '@/core/api/subscriptionsAPI/authorsSubscriptionsAPI'
import { actionsSubscriptions } from './actions'
import { AuthorFollowTypes } from '@/core/types/subscriptionsTypes/subscriptionTypes'

function* getAuthors(): Generator {
    const fetcher = new AuthorsSubscriptionsAPI(null)
    const options: OptionsType<Array<AuthorFollowTypes>> = {
        fetcher: fetcher.fetchSubscribedAuthorsData.bind(fetcher),
        fill: actionsSubscriptions.setAuthorsSubscriptions,
        startFetching: actionsSubscriptions.startLoadingAuthorsSubscriptions,
        stopFetching: actionsSubscriptions.stopLoadingAuthorsSubscriptions,
        setErrorAction: actionsSubscriptions.setAuthorsSubscriptionsError,
    }
    yield makeRequestWithSpinner<Array<AuthorFollowTypes>>(options)
}

export function* watchSubscriptions(): Generator {
    yield takeLatest('@/GET_AUTHORS_SUBSCRIPTIONS', getAuthors)
}
