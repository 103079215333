import {
    newCollectionsPreviewTypes,
    CollectionTypes,
    RelatedCollectionsActionType,
} from '@/core/types/CollectionsTypes'
import { RequestDataByAuthor, requestsScrollingData } from '@/core/types/types'

export const actionsLoadingCollections = {
    startLoading: (collectionName: CollectionTypes) => () =>
        ({
            type: '@/START_COLLECTION_LOADING',
            payload: { collectionName },
        } as const),
    stopLoading: (collectionName: CollectionTypes) => () =>
        ({
            type: '@/STOP_COLLECTION_LOADING',
            payload: { collectionName },
        } as const),
    errorLoading: (collectionName: CollectionTypes) => (payload: string) =>
        ({
            type: '@/ERROR_COLLECTION_LOADING',
            payload: { collectionName, error: payload },
        } as const),
    responseSuccess:
        (collectionName: CollectionTypes) => (data: Array<newCollectionsPreviewTypes>) =>
            ({
                type: '@/SET_COLLECTIONS',
                payload: { collectionName, data },
            } as const),
}

export const actionsCollection = {
    startCollectionLoading: (collectionName: CollectionTypes) =>
        ({
            type: '@/START_COLLECTION_LOADING',
            payload: { collectionName },
        } as const),
    stopCollectionLoading: (collectionName: CollectionTypes) =>
        ({
            type: '@/STOP_COLLECTION_LOADING',
            payload: { collectionName },
        } as const),
    errorCollectionLoading: (collectionName: CollectionTypes, payload: string) =>
        ({
            type: '@/ERROR_COLLECTION_LOADING',
            payload: { collectionName, error: payload },
        } as const),
    setCollectionsResponseSuccess: (
        collectionName: CollectionTypes,
        payload: Array<newCollectionsPreviewTypes>,
    ) =>
        ({
            type: '@/SET_COLLECTIONS',
            payload: { collectionName, data: payload },
        } as const),

    getCollection: (value: string | number) =>
        ({
            type: '@/GET_COLLECTION',
            payload: value,
        } as const),
    setCollection: (payload: newCollectionsPreviewTypes) =>
        ({
            type: '@/SET_COLLECTION',
            payload,
        } as const),
    errorFetchingSingleCollection: (payload: string) =>
        ({
            type: '@/ERROR_FETCHING_SINGLE_COLLECTION',
            payload,
        } as const),

    getCollectionsAsync: (payload: { page: number; limit: number; languages?: Array<string> }) =>
        ({
            type: '@/GET_COLLECTIONS_DATA_ASYNC',
            payload,
        } as const),
    getNearestCollections: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_NEAREST_COLLECTIONS',
            payload,
        } as const),
    getNearestCollectionsWithChangeSearchFragment: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_NEAREST_COLLECTIONS_WITH_CHANGE_SEARCH_FRAGMENT',
            payload,
        } as const),
    getCollectionByAuthor: (payload: RequestDataByAuthor) =>
        ({
            type: '@/GET_COLLECTION_BY_AUTHOR',
            payload,
        } as const),
    getRelatedCollections: (payload: RelatedCollectionsActionType) =>
        ({
            type: '@/GET_RELATED_COLLECTIONS',
            payload,
        } as const),

    likeCollection: (id: number, like: boolean) =>
        ({
            type: '@/LIKE_COLLECTION',
            payload: { id, like },
        } as const),
    setLikedCollection: (id: number, like: boolean) =>
        ({
            type: '@/SET_LIKED_COLLECTION',
            payload: { id, like },
        } as const),

    setSubscribedAuthor: (id: string, subscribe: boolean) =>
        ({
            type: '@/SET_SUBSCRIBED_AUTHOR',
            payload: { id, subscribe },
        } as const),
    buyCollection: (id: number) =>
        ({
            type: '@/BUY_COLLECTION',
            payload: { id },
        } as const),
    setBoughtCollection: (id: number) =>
        ({
            type: '@/SET_BOUGHT_COLLECTION',
            payload: { id },
        } as const),

    clearCollectionsData: (collectionName: CollectionTypes) =>
        ({
            type: '@/CLEAR_COLLECTIONS_DATA',
            payload: collectionName,
        } as const),
    clearCollection: () =>
        ({
            type: '@/CLEAR_COLLECTION',
        } as const),
}
