import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import useSupercluster from 'use-supercluster'

import GoogleMapReact, { Bounds } from 'google-map-react'
import Marker from '@/containers/MapContainer/Marker'
import UserMarker from '@/containers/MapContainer/UserMarker'
import Cluster from '@/containers/MapContainer/Cluster/Cluster'

import { mapStyler } from '@/containers/MapContainer/constants'

const googleMapApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string

function CalcBorder(pointers: any) {
    let maxLat = -180
    let minLat = 180
    let maxLon = -180
    let minLon = 180
    for (let i = 0; i < pointers.length; i++) {
        const [longitude, latitude] = pointers[i].geometry.coordinates
        maxLat = Math.max(maxLat, latitude)
        minLat = Math.min(minLat, latitude)
        maxLon = Math.max(maxLon, longitude)
        minLon = Math.min(minLon, longitude)
    }
    return { minLat, minLon, maxLat, maxLon }
}

const MapContainer = ({
    language,
    markers,
    originCoordinates,
    userCoordinates,
    defaultZoom,
    isMain,
}: any): ReactElement => {
    const mapRef: any = useRef(null)
    const mapsRef: any = useRef(null)

    const [zoom, setZoom] = useState(10)
    const [apiIsLoaded, setAPIIsLoaded] = useState(false)
    const [bounds, setBounds] = useState<any>(null)

    const mapCenter = useMemo(() => {
        if (isMain && userCoordinates) {
            return {
                lat: userCoordinates?.lat,
                lng: userCoordinates?.lng,
            }
        }
        if (!isMain && markers.length) {
            const { minLat, minLon, maxLat, maxLon } = CalcBorder(markers)
            return {
                lat: (minLat + maxLat) / 2,
                lng: (minLon + maxLon) / 2,
            }
        }
        if (originCoordinates) {
            return {
                lat: originCoordinates.lat,
                lng: originCoordinates.lng,
            }
        }
        return { lat: 59.33258, lng: 18.0649 }
    }, [])

    // Fit map bounds based on markers for collection page
    useEffect(() => {
        if (!apiIsLoaded || !markers.length || originCoordinates || isMain) return

        const { minLat, minLon, maxLat, maxLon } = CalcBorder(markers)

        const bounds = new mapsRef.current.LatLngBounds()
        bounds.extend(new mapsRef.current.LatLng(minLat, minLon))
        bounds.extend(new mapsRef.current.LatLng(maxLat, maxLon))

        mapRef.current.fitBounds(bounds)
    }, [apiIsLoaded, markers])

    // Set map center based on user coordinates fro main map
    useEffect(() => {
        if (!apiIsLoaded || !isMain || !userCoordinates) return

        mapRef.current.setCenter(
            new mapsRef.current.LatLng(userCoordinates.lat, userCoordinates.lng),
        )
    }, [apiIsLoaded, isMain, userCoordinates])

    const { clusters, supercluster } = useSupercluster({
        points: markers,
        bounds,
        zoom,
        options: {
            radius: 150,
            maxZoom: 5,
            map: (props: any) => {
                return {
                    ...props,
                    color: props.color,
                }
            },
            reduce: (acc: any, props: any) => {
                return props
            },
        },
    })

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: googleMapApiKey,
                language: language,
                region: language,
            }}
            defaultCenter={mapCenter}
            defaultZoom={defaultZoom}
            options={maps => ({
                minZoom: 3,
                maxZoom: 20,
                styles: mapStyler,
                mapTypeControl: true,
                zoomControlOptions: { position: maps.ControlPosition.TOP_RIGHT },
                clickableIcons: false,
            })}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
                mapRef.current = map
                mapsRef.current = maps
                setAPIIsLoaded(true)
            }}
            onChange={({ zoom, bounds }: { zoom: number; bounds: Bounds }) => {
                setZoom(zoom)
                setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
            }}
        >
            {clusters.map((cluster: any) => {
                const [longitude, latitude] = cluster.geometry.coordinates
                const {
                    cluster: isCluster,
                    point_count: pointCount,
                    color,
                    generalColor,
                    label,
                    slugName,
                } = cluster.properties

                if (isCluster) {
                    return (
                        <Cluster
                            key={`cluster-${cluster.id}`}
                            lat={latitude}
                            lng={longitude}
                            color={color}
                            superCluster={supercluster}
                            mapRef={mapRef}
                            latitude={latitude}
                            longitude={longitude}
                            cluster={cluster}
                            pointCount={pointCount}
                        />
                    )
                }
                return (
                    <Marker
                        key={`marker-${cluster.properties.id}`}
                        lat={latitude}
                        lng={longitude}
                        label={label}
                        generalColor={generalColor}
                        color={color}
                        slugName={slugName}
                        isMain={isMain}
                        isOriginMarker={
                            originCoordinates?.lat === latitude &&
                            originCoordinates?.lng === longitude
                        }
                    />
                )
            })}
            {!!userCoordinates && (
                <UserMarker lat={userCoordinates.lat} lng={userCoordinates.lng} />
            )}
        </GoogleMapReact>
    )
}

export const MemoizedMapContainer = React.memo(MapContainer)
