import React, { FC, ReactElement, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '@/core/hooks/useTranslate'

import { Helmet } from 'react-helmet'
import StoryMeta from '@/components/StoryMeta'
import StoryContent from '@/components/StoryContent'
import StoriesList from '@/components/StoriesList'
import InfinityScrollList from '@/components/InfinityScrollList'
import { Loader } from '@/components/controls/Loader'
import SocialIcons from '@/components/SocialIcons'

import {
    actionsStories,
    getRelatedStories,
    getCollectionStories,
    getStory,
    getStoryFetchError,
} from '@/core/store/stories'
import { actionsMap, getPageMarkers } from '@/core/store/map'
import { getDataLanguagesCodes } from '@/core/store/language'

import {
    RelatedStoriesActionType,
    SingleStoryDataType,
    CollectionType,
} from '@/core/types/stories/storiesTypes'
import { useStyles } from './styles'

const StoryPage: FC = (): ReactElement => {
    const { slugName } = useParams<{ slugName: string }>()
    const dispatch = useDispatch()
    const translate = useTranslate()
    const styles = useStyles()

    const story: any = useSelector(getStory)
    const storyFetchError = useSelector(getStoryFetchError)
    const pageMarkers = useSelector(getPageMarkers)
    const languages = useSelector(getDataLanguagesCodes)
    const collectionStories = useSelector(getCollectionStories, shallowEqual)
    const relatedStories = useSelector(getRelatedStories)

    useEffect(() => {
        dispatch(actionsStories.getStory(slugName))
        return () => {
            dispatch(actionsStories.clearStory())
            dispatch(actionsMap.setMarkersByIds({ target: 'pageMarkers', data: [] }))
        }
    }, [slugName])

    // Load page markers based on story collections
    useEffect(() => {
        if (story?.collections.length) {
            dispatch(
                actionsMap.getMarkersByIds({
                    target: 'pageMarkers',
                    requestData: {
                        collections: story.collections.map((collection: any) => collection.id),
                        stories: [],
                    },
                }),
            )
        }
    }, [story?.collections.length])

    // Load collection stories in case they are not loaded yet
    useEffect(() => {
        const firstCollectionId = story?.collections?.[0]?.id
        const colectionStoriesLoaded = story?.collections?.some(
            (collection: CollectionType) => collection.id === collectionStories.collectionId,
        )

        if (!colectionStoriesLoaded && !collectionStories.loading && firstCollectionId) {
            dispatch(actionsStories.clearStories('collectionStories'))
            dispatch(actionsStories.getStoriesByCollection(firstCollectionId))
        }
    }, [story?.collections, collectionStories.loading, collectionStories.collectionId])

    useEffect(() => {
        if (storyFetchError || collectionStories.error || relatedStories.error) {
            throw new Error('Error fetching data for Story Page')
        }
    }, [storyFetchError, collectionStories.error, relatedStories.error])

    const fetchRelatedStoriess = (data: RelatedStoriesActionType) =>
        actionsStories.getRelatedStories({
            languages,
            ...data,
            interests: story?.interests.map(({ name }: SingleStoryDataType) => name),
        })

    const teserExists = !!story?.teaser?.length
    const pictureExists = !!story?.picture?.length
    const moreStoriesCollectionName = story?.collections.find(
        (collection: CollectionType) => collection.id === collectionStories.collectionId,
    )?.name
    const moreFromCollectionStories = useMemo(() => {
        if (!story?.id || !collectionStories.data.length) return []

        return collectionStories.data.filter(colectionStory => story?.id !== colectionStory.id)
    }, [story?.id, collectionStories.data])

    return (
        <>
            <Helmet>
                <title>{story?.name}</title>
                <meta name="title" content={story?.name} />
                {teserExists && <meta name="description" content={story.teaser} />}

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={story?.name} />
                {teserExists && <meta property="og:description" content={story.teaser} />}
                {pictureExists && <meta property="og:image" content={story.picture[0]} />}
                <meta property="fb:app_id" content="418921822030203" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={story?.name} />
                {teserExists && <meta property="twitter:description" content={story.teaser} />}
                {pictureExists && <meta property="twitter:image" content={story.picture[0]} />}
            </Helmet>
            {!story && <Loader />}
            {story && (
                <>
                    <StoryMeta
                        id={story.id}
                        name={story.name}
                        address={story.address}
                        teaser={story.teaser}
                        collections={story.collections}
                        interests={story.interests}
                        author={story.author}
                        slugName={story.slugName}
                        liked={story.liked}
                        lat={story.latitude}
                        lng={story.longitude}
                        mapProps={{
                            markers: pageMarkers,
                            originCoordinates: {
                                lat: story.latitude,
                                lng: story.longitude,
                            },
                            defaultZoom: 19,
                        }}
                    />
                    <StoryContent
                        id={story.id}
                        picture={story.picture}
                        audio={story.audio}
                        content={story.content}
                        author={story.author}
                        slugName={story.slugName}
                        liked={story.liked}
                    />
                    <SocialIcons />
                    {!collectionStories.loading && !!moreStoriesCollectionName && (
                        <h2 className={styles.header}>
                            {`${translate(
                                '#MoreStoriesFromCollectionLabel',
                            )} ${moreStoriesCollectionName}`}
                        </h2>
                    )}
                    <StoriesList
                        isLoading={collectionStories.loading}
                        stories={moreFromCollectionStories}
                    />
                    <InfinityScrollList
                        title={translate('#RelatedStoriesTitle')}
                        data={relatedStories}
                        languages={languages}
                        RenderComponent={StoriesList}
                        renderComponentProps={{ showDonateCard: true }}
                        componentDataProp="stories"
                        fetchData={fetchRelatedStoriess}
                        clearData={actionsStories.clearStories('relatedStories')}
                    />
                </>
            )}
        </>
    )
}

export default StoryPage
