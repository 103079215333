import { createStyles, makeStyles } from '@material-ui/core'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() =>
    createStyles({
        searchContainer: {
            position: 'relative',
            width: '100%',
        },
        searchInput: {
            background: colors.lightGrey2,
            borderRadius: '60px',
            border: 'none',
            width: '100%',
            padding: '7px 35px 7px 10px',
            color: colors.darkGrey,
            outline: 'none',
            fontSize: '14px',
            lineHeight: '16px',
        },
        searchIcon: {
            position: 'absolute',
            top: '5px',
            right: '10px',
            color: colors.darkGrey,
            fontSize: '20px',
            cursor: 'pointer',
        },
    }),
)
