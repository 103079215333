import { BaseAPI } from '../index'
import { nearestCollectionTypes, newCollectionsPreviewTypes } from '@/core/types/CollectionsTypes'

export class CollectionAPI<T> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchCollectionByIdOrSlug() {
        try {
            return await this.requestService.get(`/rest/collection/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchCollectionByAuthor() {
        try {
            return await this.requestService.post(`/rest/collections/author`, this.data)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async likeCollection() {
        try {
            return await this.requestService.get(`/like/collection/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async unlikeCollection() {
        try {
            return await this.requestService.get(`/unlike/collection/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchNearestCollections() {
        try {
            const res: nearestCollectionTypes = await this.requestService.post(
                `/nearest/collections`,
                this.data,
            )
            return res.items.map(
                ({ data }): newCollectionsPreviewTypes => ({
                    ...JSON.parse(data),
                }),
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async buyCollection() {
        try {
            return await this.requestService.get(`/rest/buy/collection/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
