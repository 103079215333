import { ModalTypes } from '@/core/constants/modal'

type MenuItemType = {
    label: string
    data: string
    type: 'modal' | 'link'
}

const userMenu: Array<MenuItemType> = [
    {
        label: 'MyAccountText',
        data: ModalTypes.MY_ACCOUNT_MODAL,
        type: 'modal',
    },
    {
        label: 'CategoriesText',
        data: ModalTypes.INTERESTS_MODAL,
        type: 'modal',
    },
    {
        label: 'SubscriptionsText',
        data: ModalTypes.SUBSCRIPTIONS_MODAL,
        type: 'modal',
    },
    {
        label: 'FavouritesText',
        data: ModalTypes.FAVOURITES_MODAL,
        type: 'modal',
    },
    {
        label: 'LanguagesText',
        data: ModalTypes.LANGUAGE_MODAL,
        type: 'modal',
    },
    {
        label: 'BecomeRevealAuthorText',
        data: 'https://docs.google.com/forms/d/e/1FAIpQLSdOWfeNejflHW6bBXNuEoQyQxCa9Ait6W01qajilHoq5ZSTQQ/viewform',
        type: 'link',
    },
    {
        label: 'AboutRevealWorld',
        data: 'https://site.reveal.world/',
        type: 'link',
    },
    {
        label: 'PrivacyPolicy',
        data: 'https://site.reveal.world/privacy',
        type: 'link',
    },
    {
        label: 'TermsOfUse',
        data: 'https://site.reveal.world/terms',
        type: 'link',
    },
]

export { userMenu }
