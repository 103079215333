import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflowY: 'auto',
        flex: '1 1 auto',
        maxHeight: '600px',
        padding: '0 9px',
    },
    mobileRoot: {
        overflowY: 'inherit',
    },
}))
