import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '8px',
            color: 'inherit',
            '&:focus, &:hover, &:visited, &:link, &:active': {
                textDecoration: 'none',
            },
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
        },
        photoUser: {
            width: '35px',
            height: '35px',
            borderRadius: '50%',
        },
    }),
)
