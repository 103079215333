import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
    },
    appBar: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        padding: '0 15px',
        color: theme.palette.text.primary,
    },
    tab: {
        borderBottom: `2px solid ${theme.palette.text.hint}`,
    },
}))
