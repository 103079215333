import { AuthorDataTypes, StartPageAuthorType } from '@/core/types/AuthorsTypes'

export const actionsLoadingAuthors = {
    startLoading: (authorCollectionName: AuthorDataTypes) => () =>
        ({
            type: '@/START_AUTHORS_LOADING',
            payload: { authorCollectionName },
        } as const),
    stopLoading: (authorCollectionName: AuthorDataTypes) => () =>
        ({
            type: '@/STOP_AUTHORS_LOADING',
            payload: { authorCollectionName },
        } as const),
    errorLoading: (authorCollectionName: AuthorDataTypes) => (payload: string) =>
        ({
            type: '@/ERROR_AUTHORS_LOADING',
            payload: { authorCollectionName, error: payload },
        } as const),
    responseSuccess:
        (authorCollectionName: AuthorDataTypes) => (data: Array<StartPageAuthorType>) =>
            ({
                type: '@/SET_AUTHORS',
                payload: { authorCollectionName, data },
            } as const),
}

export const actionsAuthor = {
    getStartPageAuthors: (languages: Array<string>) =>
        ({
            type: '@/GET_START_PAGE_AUTHORS',
            payload: languages,
        } as const),
    startAuthorsLoading: (authorCollectionName: AuthorDataTypes) =>
        ({
            type: '@/START_AUTHORS_LOADING',
            payload: { authorCollectionName },
        } as const),
    stopLoading: (authorCollectionName: AuthorDataTypes) =>
        ({
            type: '@/STOP_AUTHORS_LOADING',
            payload: { authorCollectionName },
        } as const),
    errorAuthorsLoading: (authorCollectionName: AuthorDataTypes, payload: string) =>
        ({
            type: '@/ERROR_AUTHORS_LOADING',
            payload: { authorCollectionName, error: payload },
        } as const),
    responseAuthorsSuccess: (
        authorCollectionName: AuthorDataTypes,
        data: Array<StartPageAuthorType>,
    ) =>
        ({
            type: '@/SET_AUTHORS',
            payload: { authorCollectionName, data },
        } as const),
    getAuthorData: (id: string) =>
        ({
            type: '@/GET_AUTHOR_DATA',
            payload: id,
        } as const),
    startLoadingAuthorData: (data: any) =>
        ({
            type: '@/START_LOADING_AUTHOR_DATA',
            payload: data,
        } as const),
    stopLoadingAuthorData: (data: any) =>
        ({
            type: '@/STOP_LOADING_AUTHOR_DATA',
            payload: data,
        } as const),
    setAuthorDataError: (payload: string) =>
        ({
            type: '@/SET_AUTHOR_DATA_ERROR',
            payload,
        } as const),
    setAuthorData: (data: any) =>
        ({
            type: '@/SET_AUTHOR_DATA',
            payload: data,
        } as const),
    subscribeAuthor: (id: string, subscribe: boolean) =>
        ({
            type: '@/SUBSCRIBE_AUTHOR',
            payload: { id, subscribe },
        } as const),
    setSubscribedAuthor: (id: number, subscribe: boolean) =>
        ({
            type: '@/SET_SUBSCRIBED_AUTHOR',
            payload: { id, subscribe },
        } as const),
    clearAuthorData: () =>
        ({
            type: '@/CLEAR_AUTHOR_DATA',
        } as const),
}
