import {
    ServerResponseType,
    RegistrationResponseType,
    ResetPasswordType,
    ResetPasswordResponseType,
    SendMagicLinkType,
    MagicLinkVerificationResponseType,
} from '@/core/types/auth/authTypes'
import { AxiosResponse } from 'axios'

export const actionsMagicLinks = {
    // VERIFY LOGIN LINK
    verifyLoginLink: (payload: string) =>
        ({
            type: '@/VERIFY_LOGIN_LINK',
            payload,
        } as const),
    startVerifyLoginLink: () =>
        ({
            type: '@/START_VERIFY_LOGIN_LINK',
        } as const),
    stopVerifyLoginLink: () =>
        ({
            type: '@/STOP_VERIFY_LOGIN_LINK',
        } as const),
    verifyLoginLinkSuccess: (payload: AxiosResponse<MagicLinkVerificationResponseType>) =>
        ({
            type: '@/VERIFY_LOGIN_LINK_SUCCESS',
            payload,
        } as const),
    verifyLoginLinkFail: (payload: string) =>
        ({
            type: '@/VERIFY_LOGIN_LINK_FAILURE',
            payload,
        } as const),

    // VERIFY REGISTRATION LINK
    verifyRegistrationLink: (payload: string) =>
        ({
            type: '@/VERIFY_REGISTRATION_LINK',
            payload,
        } as const),
    startVerifyRegistrationLink: () =>
        ({
            type: '@/START_VERIFY_REGISTRATION_LINK',
        } as const),
    stopVerifyRegistrationLink: () =>
        ({
            type: '@/STOP_VERIFY_REGISTRATION_LINK',
        } as const),
    verifyRegistrationLinkSuccess: (payload: AxiosResponse<MagicLinkVerificationResponseType>) =>
        ({
            type: '@/VERIFY_REGISTRATION_LINK_SUCCESS',
            payload,
        } as const),
    verifyRegistrationLinkFail: (payload: string) =>
        ({
            type: '@/VERIFY_REGISTRATION_LINK_FAILURE',
            payload,
        } as const),

    // VERIFY REGISTRATION PASSWORD LINK
    verifyRegistrationPasswordLink: (payload: string) =>
        ({
            type: '@/VERIFY_REGISTRATION_PASSWORD_LINK',
            payload,
        } as const),
    startVerifyRegistrationPasswordLink: () =>
        ({
            type: '@/START_VERIFY_REGISTRATION_PASSWORD_LINK',
        } as const),
    stopVerifyRegistrationPasswordLink: () =>
        ({
            type: '@/STOP_VERIFY_REGISTRATION_PASSWORD_LINK',
        } as const),
    verifyRegistrationPasswordLinkSuccess: (payload: AxiosResponse<RegistrationResponseType>) =>
        ({
            type: '@/VERIFY_REGISTRATION_PASSWORD_LINK_SUCCESS',
            payload,
        } as const),
    verifyRegistrationPasswordLinkFail: (payload: string) =>
        ({
            type: '@/VERIFY_REGISTRATION_PASSWORD_LINK_FAILURE',
            payload,
        } as const),

    // RESET PASSWORD
    resetPassword: (payload: ResetPasswordType) =>
        ({
            type: '@/RESET_PASSWORD',
            payload,
        } as const),
    startResetPassword: () =>
        ({
            type: '@/START_RESET_PASSWORD',
        } as const),
    stopResetPassword: () =>
        ({
            type: '@/STOP_RESET_PASSWORD',
        } as const),
    resetPasswordSuccess: (payload: AxiosResponse<ResetPasswordResponseType>) =>
        ({
            type: '@/RESET_PASSWORD_SUCCESS',
            payload,
        } as const),
    resetPasswordFail: (payload: string) =>
        ({
            type: '@/RESET_PASSWORD_FAILURE',
            payload,
        } as const),

    // AUTHORIZATION
    sendAuthLink: (payload: SendMagicLinkType) =>
        ({
            type: '@/SEND_AUTH_LINK',
            payload,
        } as const),
    startSendAuthLink: () =>
        ({
            type: '@/START_SEND_AUTH_LINK',
        } as const),
    stopSendAuthLink: () =>
        ({
            type: '@/STOP_SEND_AUTH_LINK',
        } as const),
    sendAuthLinkSuccess: (payload: AxiosResponse<ServerResponseType>) =>
        ({
            type: '@/SEND_AUTH_LINK_SUCCESS',
            payload,
        } as const),
    sendAuthLinkFail: (payload: string) =>
        ({
            type: '@/SEND_AUTH_LINK_FAILURE',
            payload,
        } as const),

    flushMagicLinkState: () => ({ type: '@/FLUSH_MAGIC_LINK_STATE' } as const),
}
