import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getLanguageState = (state: AppState): typeof initialState => state.languageReducer

export const getInterfaceLanguageCode = createSelector(
    getLanguageState,
    state => state.interfaceLanguageCode,
)
export const getInterfaceLanguageData = createSelector(
    getLanguageState,
    (state: any) => state.languages[state.interfaceLanguageCode],
)

export const getDataLanguagesCodes = createSelector(
    getLanguageState,
    state => state.dataLanguageCodes,
)
