import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { getUserProfile } from '@/core/store/auth'
import { MyAccountModal } from '@/components/MyAccountModal/MyAccountModal'
import { SignInRegisterModalWrapper } from '@/components/SignInOrRegisterModalWrapper'

const AuthModalWrapper = (): ReactElement => {
    const profile = useSelector(getUserProfile)

    const modalJSX = profile ? <MyAccountModal /> : <SignInRegisterModalWrapper />

    return modalJSX
}

export { AuthModalWrapper }
