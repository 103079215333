import { StoriesPointers } from '@/core/types/stories'
import { colors } from '@/containers/MapContainer/constants'

export const coordinatesMapping = (coordinates: any) => {
    const defaultColor = '#3498DB'
    return coordinates.map((point: StoriesPointers) => ({
        type: 'Stories',
        properties: {
            cluster: false,
            id: point.id,
            slugName: point.slugName,
            label: point.label,
            color: colors[Math.floor(Math.random() * colors.length)],
            generalColor: point.color ? defaultColor : null,
        },
        geometry: {
            type: 'Point',
            coordinates: [point.longitude, point.latitude],
        },
    }))
}

export const waypointsMapping = (data: []) => {
    return data.map((item: any) => {
        return {
            location: { lat: item.latitude, lng: item.longitude },
        }
    })
}
