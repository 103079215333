import { createTheme } from '@material-ui/core/styles'
import colors from './colors'
import units from './units'

export const materialTheme = createTheme({
    palette: {
        primary: {
            main: colors.background,
        },
        secondary: {
            main: colors.secondaryBackground,
        },
        text: {
            primary: colors.mainText,
            secondary: colors.secondaryText,
            hint: colors.grey,
        },
        error: {
            main: colors.background,
            dark: colors.errorText,
        },
        background: {
            default: colors.background,
        },
        success: {
            main: colors.button,
            dark: colors.buttonHover,
        },
    },
    typography: {
        body1: {
            fontSize: '14px',
        },
        h3: {
            fontSize: '16px',
            fontWeight: 'normal',
        },
        h4: {
            fontSize: '18px',
            fontWight: 'normal',
        },
        h5: {
            fontWeight: 'bold',
            marginBottom: units.single,
        },
        h6: {
            fontWeight: 'bold',
            fontSize: '22px',
        },
        h1: {
            fontFamily: 'Roboto, sans-serif',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '28px',
        },
    },
    props: {
        MuiButton: {
            color: 'primary',
            disableElevation: true,
        },
        MuiRadio: {
            color: 'primary',
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiGrid: {
            alignItems: 'center',
        },
        MuiTextField: {
            size: 'small',
        },
    },
    overrides: {
        MuiTabs: {
            indicator: {
                backgroundColor: colors.button,
            },
            root: {
                textTransform: 'none',
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                fontFamily: 'Roboto, sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '19px',
            },
        },
        MuiTypography: {
            paragraph: {
                marginBottom: '8px',
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                color: colors.errorText,
                '&$checked': {
                    color: colors.errorText,
                },
            },

            colorSecondary: {
                color: colors.secondaryBackground,
                '&$checked': {
                    color: colors.button,
                },
            },
        },
        MuiContainer: {
            root: {
                '&:focus': {
                    outline: 'none',
                },
            },
        },
        MuiButton: {
            root: {
                fontWeight: 'bold',
                transition: '0.2s',
            },
            containedPrimary: {
                color: '#fff',
            },
            contained: {
                '&:hover': {
                    filter: 'drop-shadow(0px 4px 4px #00000036)',
                },
                margin: '8px 0',
            },
            outlined: {
                '&:hover': {
                    filter: 'drop-shadow(0px 4px 4px #00000064)',
                },
            },
        },
        MuiSelect: {
            select: {
                color: colors.black,
            },
        },
        MuiMenuItem: {
            root: {
                color: colors.black,
            },
        },
    },
    spacing: units.single,
})
