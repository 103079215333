import React, { ReactElement, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { MemoizedMapContainer } from '@/containers/MapContainer/MapContainer'

import { getMapView } from '@/core/store/general'
import {
    actionsMap,
    getAllMarkers,
    getSearchMarkers,
    getPageMarkers,
    getUserCoordinates,
} from '@/core/store/map'

import PermissionsDriver from '../PermissionsDriver'

const MainMap = (): ReactElement | null => {
    const dispatch = useDispatch()
    const isSearchPage = useRouteMatch('/search')?.isExact
    const isCollectionPage = useRouteMatch('/collection')
    const isStoryPage = useRouteMatch('/story')

    const isMapView = useSelector(getMapView)
    const allMarkers = useSelector(getAllMarkers)
    const pageMarkers = useSelector(getPageMarkers)
    const searchMarkers = useSelector(getSearchMarkers)
    const userCoordinates = useSelector(getUserCoordinates)

    useEffect(() => {
        dispatch(actionsMap.getAllMarkers())
    }, [])

    const markers = isSearchPage
        ? searchMarkers
        : isCollectionPage || isStoryPage
        ? pageMarkers
        : allMarkers

    return isMapView ? (
        <div
            style={{
                position: 'fixed',
                width: '100%',
                height: 'calc(100vh - 90px)',
            }}
        >
            <PermissionsDriver />
            <MemoizedMapContainer
                markers={markers}
                userCoordinates={userCoordinates.isSet ? userCoordinates : null}
                defaultZoom={5}
                isMain
            />
        </div>
    ) : null
}

export default MainMap
