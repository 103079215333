import React, { useState } from 'react'
import RightIcon from '@/assets/right.svg'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useStyles } from './styles'
import clsx from 'clsx'

const CarouselWrapper = ({ images }: any) => {
    const styles = useStyles()
    const [currentSlide, setCurrentSlide] = useState(0)

    const next = () => {
        setCurrentSlide(prevState => prevState + 1)
    }
    const prev = () => {
        setCurrentSlide(prevState => prevState - 1)
    }
    return (
        <div className={styles.carouselWrapper}>
            <div className={styles.controlButtonsWrapper}>
                <button
                    onClick={prev}
                    disabled={currentSlide === 0}
                    className={clsx(styles.button)}
                >
                    <img alt="prev" src={RightIcon} className={styles.buttonPrev} />
                </button>
                <button
                    onClick={next}
                    disabled={currentSlide === images.length - 1}
                    className={styles.button}
                >
                    <img alt="next" src={RightIcon} />
                </button>
            </div>
            <Carousel
                className={styles.carouselWrapper}
                selectedItem={currentSlide}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                showArrows={false}
            >
                {images.map((item: string, i: number) => (
                    <div key={item} className={styles.imageContainer}>
                        <img alt="image" src={item} key={i} />
                    </div>
                ))}
            </Carousel>
        </div>
    )
}
export default CarouselWrapper
