import React from 'react'
import { useStyles } from './styles'
import clsx from 'clsx'
import { useMediaQuery } from 'react-responsive'
import ImgPreview from '@/assets/placeholder-image.png'
import { Button } from '@/components/controls/Button'

const PaidContent = (props: any) => {
    const styles = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 767 })

    return (
        <div
            className={clsx(styles.card, {
                [styles.mobileCard]: isMobile,
            })}
        >
            <div className={styles.imageWrapper}>
                {<img src={props.picture || ImgPreview} alt="image" className={styles.image} />}
            </div>
            <div className={styles.paidOverlay}>
                {props.price && props.buyClicked && (
                    <Button
                        title={props.price ? `Buy for ${props.price} €` : 'FREE'}
                        onClick={props.buyClicked}
                    />
                )}
            </div>
        </div>
    )
}

export default PaidContent
