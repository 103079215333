import { BaseAPI } from '../index'

export class InterestsAPI<T extends { [key: string]: unknown }> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchInterestsData() {
        try {
            return await this.requestService.get(`/rest/interests/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async saveInterestsData() {
        try {
            return await this.requestService.post(`/subscribe/interestlist`, this.data)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
