import React, { createContext, useContext, useState } from 'react'
import { ModalComponentsTypes, ModalTypes } from '@/core/constants/modal'
import FavouritesModal from '@/components/FavouritesModal'
import InterestsModal from '@/components/Interests'
import { SubscriptionsModal } from '@/components/SubscriptionsModal'
import { GetMobileAppModal } from '@/components/GetMobileAppModal'
import { AuthModalWrapper } from '@/components/AuthModalWrapper/AuthModalWrapper'
import StoryModal from '@/components/StoryModal'
import { LanguageModal } from '@/components/LanguageModal'
import { useDispatch, useSelector } from 'react-redux'
import { actionsGeneral, getModalData } from '@/core/store/general'
import { WizardModal } from '@/components/Wizard'
import { PermissionsModal } from '@/components/PermissionsModal'

import { AddToHomeModal } from '@/components/AddToHomeModal'

const MODAL_COMPONENTS: ModalComponentsTypes<(props: any) => JSX.Element> = {
    [ModalTypes.FAVOURITES_MODAL]: FavouritesModal,
    [ModalTypes.INTERESTS_MODAL]: InterestsModal,
    [ModalTypes.SUBSCRIPTIONS_MODAL]: SubscriptionsModal,
    [ModalTypes.MY_ACCOUNT_MODAL]: AuthModalWrapper,
    [ModalTypes.GET_MOBILE_APP_MODAL]: GetMobileAppModal,
    [ModalTypes.STORY_MODAL]: StoryModal,
    [ModalTypes.LANGUAGE_MODAL]: LanguageModal,
    [ModalTypes.WIZARD_MODAL]: WizardModal,
    [ModalTypes.PERMISSIONS_MODAL]: PermissionsModal,
    [ModalTypes.ADD_TO_HOME_MODAL]: AddToHomeModal,
}

type GlobalModalContext = {
    hideModal: () => void
    props: any
    modalType: string
}

const initialState: GlobalModalContext = {
    hideModal: () => undefined,
    modalType: '',
    props: {},
}

const GlobalModalContext = createContext(initialState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

const GlobalModal = ({ children }: any) => {
    const dispatch = useDispatch()
    const { type: modalType, props: modalProps } = useSelector(getModalData)

    const hideModal = () => {
        dispatch(actionsGeneral.hideModal())
    }

    const renderComponent = () => {
        const ModalComponent =
            modalType.length > 0 ? MODAL_COMPONENTS[modalType as ModalTypes] : null
        if (!ModalComponent) {
            return null
        }
        return <ModalComponent id="global-modal" {...modalProps} />
    }
    return (
        <GlobalModalContext.Provider
            value={{
                hideModal,
                props: modalProps,
                modalType,
            }}
        >
            {renderComponent()}
            {children}
        </GlobalModalContext.Provider>
    )
}
export default GlobalModal
