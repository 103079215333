import React, { FC, ReactElement } from 'react'

import { Link } from 'react-router-dom'
import NoPhoto from '@/assets/no_photo.png'

import { useStyles } from './styles'

type AuthorInfoProps = {
    photoUrl?: string
    authorName?: string
    id?: string
}

const AuthorInfo: FC<AuthorInfoProps> = ({
    authorName,
    photoUrl,
    id,
}: AuthorInfoProps): ReactElement => {
    const styles = useStyles()
    return (
        <Link to={`/author/${id}`} className={styles.root}>
            <img src={photoUrl || NoPhoto} alt="photo" className={styles.photoUser} />
            <div>{authorName}</div>
        </Link>
    )
}

export { AuthorInfo }
