import { call, put, takeLatest, select } from 'redux-saga/effects'

import { AuthorAPI } from '@/core/api/authorAPI/authorAPI'

import { SubscribeTypes } from '@/core/types/collection/collection'
import { StartPageAuthorType } from '@/core/types/AuthorsTypes'
import { actionsGeneral } from '@/core/store/general'
import { DefaultUserTypes } from '@/core/types/auth/authTypes'
import { requestAnyAction } from '@/core/types/types'

import { actionsAuthor, actionsLoadingAuthors } from '@/core/store/author/actions'

import { makeRequestWithSpinner, OptionsType } from '../sagaWorkers/saga'
import { AppState } from '@/core/store/rootReducer'
import { ModalTypes } from '@/core/constants/modal'

function* getAuthorData({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    const fetcher = new AuthorAPI(payload)
    const options: OptionsType<DefaultUserTypes> = {
        fetcher: fetcher.fetchAuthorData.bind(fetcher),
        startFetching: actionsAuthor.startLoadingAuthorData,
        stopFetching: actionsAuthor.stopLoadingAuthorData,
        fill: actionsAuthor.setAuthorData,
        setErrorAction: actionsAuthor.setAuthorDataError,
    }
    yield makeRequestWithSpinner<DefaultUserTypes>(options)
}

function* getStartPageAuthors(payload: requestAnyAction<Array<string>>): Generator {
    const fetcher = new AuthorAPI(payload)

    const options: OptionsType<Array<StartPageAuthorType>> = {
        fetcher: fetcher.fetchStartPageAuthors.bind(fetcher),
        startFetching: actionsLoadingAuthors.startLoading('startPageAuthors'),
        stopFetching: actionsLoadingAuthors.stopLoading('startPageAuthors'),
        fill: actionsLoadingAuthors.responseSuccess('startPageAuthors'),
        setErrorAction: actionsLoadingAuthors.errorLoading('startPageAuthors'),
    }

    yield makeRequestWithSpinner<Array<StartPageAuthorType>>(options)
}

function* subscribeAuthor({ payload }: requestAnyAction<SubscribeTypes>): Generator {
    try {
        const userProfile: any = yield select((state: AppState) => state.authReducer.profile)
        const continueAnonymouslyConfirmed: any = yield select(
            (state: AppState) => state.generalReducer.continueAnonymouslyConfirmed,
        )

        if (!continueAnonymouslyConfirmed && !userProfile) {
            yield put(
                actionsGeneral.showModal(ModalTypes.MY_ACCOUNT_MODAL, {
                    showAnonymousOption: true,
                }),
            )
        }

        const fetcher = new AuthorAPI(payload)
        yield call(
            payload.subscribe
                ? fetcher.subscribeAuthor.bind(fetcher)
                : fetcher.unsubscribeAuthor.bind(fetcher),
        )
        yield put(actionsAuthor.setSubscribedAuthor(payload.id, payload.subscribe))
    } catch (e) {
        console.error('Catched Error in subscribeAuthor', e)
    }
}

export function* watchAuthor(): Generator {
    yield takeLatest('@/GET_AUTHOR_DATA', getAuthorData)
    yield takeLatest('@/SUBSCRIBE_AUTHOR', subscribeAuthor)
    yield takeLatest('@/GET_START_PAGE_AUTHORS', getStartPageAuthors)
}
