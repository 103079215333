import { call, takeLatest } from 'redux-saga/effects'
import { makeRequestWithSpinner, OptionsType } from '../sagaWorkers/saga'
import { DefaultUserTypes } from '@/core/types/auth/authTypes'
import { requestAnyAction } from '@/core/types/types'
import { FavouritesAPI } from '@/core/api/favouritesAPI/favouritesAPI'
import { actionsFavourites } from '@/core/store/favourites/actions'
import { StoriesAPI } from '@/core/api/storiesAPI/storiesAPI'

function* getStoriesByIds(payload: any): Generator {
    const fetcher = new StoriesAPI(payload)
    const options: OptionsType<DefaultUserTypes> = {
        fetcher: fetcher.fetchStories.bind(fetcher),
        startFetching: actionsFavourites.startLoadingStories,
        stopFetching: actionsFavourites.stopLoadingStories,
        fill: actionsFavourites.setFavouritesStories,
        setErrorAction: actionsFavourites.setStoriesError,
    }
    yield makeRequestWithSpinner<DefaultUserTypes>(options)
}

function* getCollectionsByIds(payload: any): Generator {
    const fetcher = new StoriesAPI(payload)
    const options: OptionsType<DefaultUserTypes> = {
        fetcher: fetcher.fetchCollections.bind(fetcher),
        startFetching: actionsFavourites.startLoadingCollections,
        stopFetching: actionsFavourites.stopLoadingCollections,
        fill: actionsFavourites.setFavouritesCollections,
        setErrorAction: actionsFavourites.setCollectionsError,
    }
    yield makeRequestWithSpinner<DefaultUserTypes>(options)
}
function* getFavouritesCollectionsData({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    const instance: any = new FavouritesAPI(payload)
    const request: any = yield call(instance.fetchFavouritesCollections.bind(instance))
    yield call(getCollectionsByIds, { ids: request.map((item: any) => item.itemId) })
}

function* getFavouritesStoriesData({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    const instance: any = new FavouritesAPI(payload)
    const request: any = yield call(instance.fetchFavouritesStories.bind(instance))
    yield call(getStoriesByIds, { ids: request.map((item: any) => item.itemId) })
}

export function* watchFavourites(): Generator {
    yield takeLatest('@/GET_FAVOURITES_STORIES', getFavouritesStoriesData)
    yield takeLatest('@/GET_FAVOURITES_COLLECTIONS', getFavouritesCollectionsData)
}
