import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { TabPanelProps } from '../../FavouritesModal/types'
import { useStyles } from './styles'
import { useMediaQuery } from 'react-responsive'
import clsx from 'clsx'

const TabPanel = (props: TabPanelProps) => {
    const styles = useStyles()
    const { children, value, index, ...other } = props
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 })

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className={clsx(styles.root, {
                [styles.mobileRoot]: isMobile,
            })}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'} variant={'body2'}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    )
}

export default TabPanel
