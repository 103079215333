import { SagaIterator } from '@redux-saga/core'
import { ActionCreator, AnyAction } from 'redux'
import { put, call } from '@redux-saga/core/effects'
import { requestsScrollingData } from '@/core/types/types'
import { select } from 'redux-saga/effects'
import { AppState } from '@/core/store/rootReducer'
import { CollectionsResponsePayload, CollectionTypes } from '@/core/types/CollectionsTypes'
import { StoriesResponsePayload, StoriesTypes } from '@/core/types/stories/storiesTypes'
import { AuthorDataTypes, AuthorDataResponsePayload } from '@/core/types/AuthorsTypes'

export type ActionType<T> = (payload: T) => {
    type: string
    payload: T
}

export type CollectionFillType<T> = (payload: T) => {
    type: string
    payload: CollectionsResponsePayload
}

export type StoryFillType<T> = (payload: T) => {
    type: string
    payload: StoriesResponsePayload
}

export type AuthorFillType<T> = (payload: T) => {
    type: string
    payload: AuthorDataResponsePayload
}

type CollectionErrorPayload = {
    collectionName: CollectionTypes
    error: string
}

type StoryErrorPayload = {
    storyType: StoriesTypes
    error: string
}

type AuthorErrorPayload = {
    authorCollectionName: AuthorDataTypes
    error: string
}

export type ErrorActionType = (payload: string) => {
    type: string
    payload: string | CollectionErrorPayload | StoryErrorPayload | AuthorErrorPayload
}

export type OptionsType<T> = {
    fetcher: (...arg: any) => Promise<T>
    // fetcher: () => Promise<T>
    fetcherParam?: T
    startFetching: ActionCreator<AnyAction>
    stopFetching: ActionCreator<AnyAction>
    fill: ActionType<T> | CollectionFillType<T> | StoryFillType<T> | AuthorFillType<T>
    setErrorAction: ErrorActionType
}

export function* makeRequestWithSpinner<T>(options: OptionsType<T>): SagaIterator {
    const { fetcher, fill, setErrorAction, startFetching, stopFetching, fetcherParam } = options
    try {
        yield put(startFetching())
        const result = yield call(fetcher, fetcherParam)
        yield put(fill(result))
    } catch (e: any) {
        yield put(setErrorAction(e.message))
    } finally {
        yield put(stopFetching())
    }
}

export function* getDataLocationAndPayload(payload: requestsScrollingData): Generator {
    const { lat, lng, timestamp }: any = yield select(
        (state: AppState) => state.mapReducer.userCoordinates,
    )
    return {
        latitude: lat,
        longitude: lng,
        timestamp,
        searchRequest: payload.searchFragment || '',
        languages: payload.languages,
        startNum: payload.page,
        maxAmount: payload.limit,
    }
}
