import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getSubscriptionsState = (state: AppState): typeof initialState => state.subscriptionsReducer

export const getAuthorsSubscriptionsState = createSelector(
    getSubscriptionsState,
    state => state.authorsSubscriptions,
)
