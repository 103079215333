import { createStyles, makeStyles } from '@material-ui/core'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() =>
    createStyles({
        menuBtn: {
            padding: '0',
            '& .MuiSvgIcon-root': {
                color: colors.darkGrey,
            },
        },
        root: {
            background: colors.white,
            width: 'calc(100% - 30px)',
            maxWidth: '360px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '24px 0px 0px 24px',
        },
        headerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px',
        },
        closeIcon: {
            fontSize: '16px',
            color: colors.grey,
            cursor: 'pointer',
        },
        divider: {
            height: '1px',
            background: colors.lightGrey2,
            width: 'calc(100% - 64px)',
            margin: '0 auto',
        },
        menuContainer: {
            padding: '24px 0',
        },
        menuItem: {
            fontSize: '16px',
            lineHeight: '19px',
            color: colors.darkGrey,
            fontWeight: 400,
            padding: '12px 24px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            '&:hover': {
                background: colors.lightGrey,
            },
        },
        smallFontSize: {
            fontSize: '14px',
        },
        logOutWrapper: {
            padding: '24px',
        },
    }),
)
