import colors from '@/core/theme/colors'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        storyContent: {
            background: 'white',
            borderRadius: '10px',
            color: 'black',
            overflow: 'hidden',
            boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.08)',
        },
        imageWrapper: {
            width: '100%',
            height: '100%',
            position: 'relative',
        },
        image: {
            width: '100%',
            height: '200px',
            backgroundColor: colors.secondaryBackground,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        body: {
            padding: '16px',
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
        },
    }),
)
