import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '@/core/hooks/useTranslate'

import { Helmet } from 'react-helmet'
import { CircularProgress } from '@material-ui/core'
import { Card } from '@/components/controls/CardComponent'
import FilledTabs from '@/components/controls/FilledTabs'

import {
    actionsSearch,
    getSearchDataResponse,
    getLoadingSearch,
    getSearchError,
    getRedirectFromSearch,
} from '@/core/store/search'

import { SearchResponseTypes, StateSearchItemType } from '@/core/types/types'
import { groupSearchResultData } from '@/core/helpers/groupSearchResultData'

import { useStyles } from './styles'

const SearchPage: FC = (): ReactElement => {
    const dispatch = useDispatch()
    const translate = useTranslate()
    const styles = useStyles()

    const searchData = useSelector(getSearchDataResponse)
    const isLoading = useSelector(getLoadingSearch)
    const searchError = useSelector(getSearchError)
    const redirectFrom = useSelector(getRedirectFromSearch)

    const [tab, setTab] = useState(redirectFrom?.tab || 0)

    useEffect(() => {
        if (searchError) {
            throw new Error('Error fetching data for Search Page')
        }
    }, [searchError])
    const handleTabChange = (newValue: number) => {
        setTab(newValue)
    }

    const title = `Reveal World | ${translate('#SearchResults')}`
    const { collections, stories } = useMemo(() => {
        if (isLoading) return { collections: [], stories: [] }

        return groupSearchResultData(searchData)
    }, [searchData, isLoading])

    useEffect(() => {
        if (redirectFrom) {
            window.scrollTo(0, redirectFrom.offsetTop)
        }
    }, [redirectFrom])

    const onCardClick = () => {
        dispatch(actionsSearch.setRefirectedFrom({ tab, offsetTop: window.pageYOffset }))
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={title} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={title} />
                <meta property="fb:app_id" content="418921822030203" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={title} />
            </Helmet>
            {isLoading ? (
                <div className={styles.loaderContainer}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <>
                    <FilledTabs
                        defaultTab={redirectFrom?.tab}
                        showTypes={false}
                        onTabChange={handleTabChange}
                    />
                    <div
                        style={{
                            display: tab === 0 ? 'flex' : 'none',
                            flexDirection: 'column',
                            gap: '16px',
                        }}
                    >
                        {collections.length ? (
                            collections.map(
                                ({
                                    itemId: collectionId,
                                    picture,
                                    name,
                                    slugName,
                                }: SearchResponseTypes<StateSearchItemType>) => (
                                    <Card
                                        picture={picture}
                                        key={collectionId}
                                        id={collectionId}
                                        title={name}
                                        eventId={collectionId}
                                        type={'collection'}
                                        slugName={slugName}
                                        hideActionButtons
                                        hideContent
                                        authorId=""
                                        liked={false}
                                        subscribed={false}
                                        onCardClick={onCardClick}
                                    />
                                ),
                            )
                        ) : (
                            <h2 className={styles.notFoundText}>
                                {translate('#NoCollectionsFound')}
                            </h2>
                        )}
                    </div>
                    <div
                        style={{
                            display: tab === 1 ? 'flex' : 'none',
                            flexDirection: 'column',
                            gap: '16px',
                        }}
                    >
                        {stories.length ? (
                            stories.map(
                                ({
                                    itemId: storyID,
                                    picture,
                                    name,
                                    slugName,
                                }: SearchResponseTypes<StateSearchItemType>) => (
                                    <Card
                                        key={storyID}
                                        picture={picture}
                                        title={name}
                                        type={'story'}
                                        id={storyID}
                                        eventId={storyID}
                                        slugName={slugName}
                                        hideActionButtons
                                        hideContent
                                        authorId=""
                                        liked={false}
                                        subscribed={false}
                                        onCardClick={onCardClick}
                                    />
                                ),
                            )
                        ) : (
                            <h2 className={styles.notFoundText}>{translate('#NoStoriesFound')}</h2>
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default SearchPage
