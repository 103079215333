// /* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { checkTokens } from '@/core/services/checkTokens'

const API_BASE_URL = 'https://api.reveal.world'

const getFormData = (object: any): FormData => {
    const formData: FormData = new FormData()

    Object.keys(object).forEach((key: string) => formData.append(key, object[key]))

    return formData
}

export class RequestService {
    private service: AxiosInstance

    constructor() {
        const service = axios.create({
            baseURL: API_BASE_URL,
        })
        service.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const token = await checkTokens()
            config.headers['Content-type'] = 'application/json; charset=UTF-8'
            config.headers['x-auth-token'] = token
            config.headers.accept = 'application/json'

            if (config.data instanceof FormData) {
                Object.assign(config.headers, {
                    'Content-Type': 'application/x-www-form-urlencoded',
                })
            }
            return config
        })

        service.interceptors.response.use(this.handleSuccess, this.handleError)

        this.service = service
    }

    private handleSuccess = (response: AxiosResponse): AxiosResponse => {
        return response
    }

    private handleError = (error: AxiosError): void => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    throw new Error(error.response.data.message)
                case 401:
                    throw new Error(error.response.data.message)
                case 403:
                    throw new Error(error.response.data.message)
                case 404:
                    throw new Error(error.response.data.message)
                case 422:
                    throw new Error(error.response.data.message)
                case 500:
                    throw new Error(error.response.data.message)
                case 502:
                    throw new Error(error.response.data.message)
                case 503:
                    throw new Error(error.response.data.message)
                case 504:
                    throw new Error(error.response.data.message)
                default:
            }
        } else {
            throw new Error(error.message)
        }
    }

    public get(path: string, params?: any): Promise<AxiosResponse['data']> {
        return this.service.get(path, { params }).then((response: AxiosResponse) => response.data)
    }

    public patch = (path: string, data: any): Promise<AxiosResponse['data']> => {
        return this.service.patch(path, data).then((response: AxiosResponse) => response.data)
    }

    public post = (
        path: string,
        data: unknown,
        withHeaders?: boolean,
    ): Promise<AxiosResponse['data']> => {
        return this.service.post(path, data).then((response: AxiosResponse) => {
            return withHeaders ? { data: response.data, headers: response.headers } : response.data
        })
    }

    public postFormData = (path: string, data: unknown): Promise<AxiosResponse['data']> => {
        return this.service
            .post(path, getFormData(data))
            .then((response: AxiosResponse) => response.data)
    }

    public put = (path: string, data: unknown): Promise<AxiosResponse['data']> => {
        return this.service.put(path, data).then((response: AxiosResponse) => response.data)
    }

    public delete = (path: string): Promise<AxiosResponse['data']> => {
        return this.service.delete(path).then((response: AxiosResponse) => response.data)
    }
}

export const getRequestService = (): RequestService => new RequestService()
