import { AllLanguagesType, DefaultLanguagesType } from '@/core/types/languages'

export const LANGUAGES: AllLanguagesType = {
    en: { code: 'en', name: 'English', resource: 'en.json' },
    ru: { code: 'ru', name: 'Russian', resource: 'ru.json' },
    sv: { code: 'sv', name: 'Swedish', resource: 'sv.json' },
}

export const DEFAULT_DATA_LANGUAGES: DefaultLanguagesType = {
    en: ['en'],
    ru: ['ru'],
    sv: ['en', 'sv'],
}
