import { BaseAPI } from '../index'

export class FavouritesAPI<T extends { [key: string]: unknown }> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchFavouritesStories() {
        try {
            return await this.requestService.get(
                `/favourites/stories/${this.data.offset}/${this.data.limit}`,
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchFavouritesCollections() {
        try {
            return await this.requestService.get(
                `/favourites/collections/${this.data.offset}/${this.data.limit}`,
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
