import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: '100%',
        maxHeight: '300px',
        height: '100%',
        padding: '30px',
        width: '500px',
        backgroundColor: '#fff',
    },
    skeletonHead: {
        position: 'relative',
        overflow: 'hidden',
        width: '70%',
        height: '20px',
        background: '#f6f7f8',
        marginBottom: '10px',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, #f6f7f8, #edeef1, #f6f7f8)',
            animation: '$progress 1s ease-in-out infinite',
        },
    },
    skeletonAddress: {
        position: 'relative',
        overflow: 'hidden',
        width: '50%',
        height: '20px',
        background: '#f6f7f8',
        marginBottom: '10px',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, #f6f7f8, #edeef1, #f6f7f8)',
            animation: '$progress 1s ease-in-out infinite',
        },
    },
    skeletonImage: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100px',
        background: '#f6f7f8',
        marginBottom: '10px',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, #f6f7f8, #edeef1, #f6f7f8)',
            animation: '$progress 1s ease-in-out infinite',
        },
    },
    skeletonContent: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '20px',
        background: '#f6f7f8',
        marginBottom: '10px',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, #f6f7f8, #edeef1, #f6f7f8)',
            animation: '$progress 1s ease-in-out infinite',
        },
    },
    '@keyframes progress': {
        '0%': {
            transform: 'translate3d(-100%, 0, 0)',
        },
        '100%': {
            transform: 'translate3d(100%, 0, 0)',
        },
    },
}))
