import { makeStyles } from '@material-ui/core/styles'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() => ({
    root: {
        marginTop: '16px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
    },
    link: {
        borderRadius: '50px',
        padding: '4px 8px',
        background: colors.lightGrey2,
        color: colors.deepGrey,
        textDecoration: 'none',
        '&:hover': {
            background: colors.lightGrey,
        },
    },
}))
