import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
    marker: {
        fontSize: '30px',
        color: 'rgb(52, 152, 219)',
        background: '#FFF',
        borderRadius: '100%',
    },
}))
