import React, { ReactElement, useState, useEffect } from 'react'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { useInput } from '@/components/controls/TextInput/useInputHook'

import ModalWindow from '@/components/controls/ModalWindow'
import { Button } from '@/components/controls/Button'
import { TextInput } from '@/components/controls/TextInput'
import ClipLoader from 'react-spinners/ClipLoader'

import { actionsGeneral } from '@/core/store/general'
import {
    actionsMagicLinks,
    getErrorOccured,
    getErrorText,
    getLoading,
    getSuccess,
} from '@/core/store/magicLinks'

import { useStyles } from './styles'

const SignInRegisterModalWrapper = (): ReactElement => {
    const translate = useTranslate()
    const styles = useStyles()
    const dispatch = useDispatch()
    const { hideModal, props } = useGlobalModalContext()

    const email = useInput('')
    const [onceSuccessSent, setOnceSuccessSent] = useState(false)

    const magicLinkError = useSelector(getErrorOccured)
    const magicLinkErrorText = useSelector(getErrorText)
    const magicLinkSending = useSelector(getLoading)
    const magicLinkSuccess = useSelector(getSuccess)

    const onCloseModal = () => {
        dispatch(actionsMagicLinks.flushMagicLinkState())
        hideModal()
    }

    const onContinueAnonymouslyClick = () => {
        dispatch(actionsGeneral.setContinueAnonymouslyFlag(true))
        onCloseModal()
    }

    useEffect(() => {
        if (magicLinkSuccess) {
            setOnceSuccessSent(true)
        }
    }, [magicLinkSuccess])

    const sendMagicLinkClick = () => {
        dispatch(
            actionsMagicLinks.sendAuthLink({
                email: email.inputValue,
                currentUrl: location.pathname,
            }),
        )
    }

    return (
        <ModalWindow onClose={onCloseModal} open>
            <div className={styles.root}>
                <h1 className={styles.title}>{translate('#RegistrationPageHeader')}</h1>
                <TextInput
                    placeholder={'Enter your email address'}
                    value={email.inputValue}
                    handleInputChange={email.handleInputChange}
                    type="light"
                />
                {magicLinkSuccess && (
                    <>
                        <h3 className={styles.successTitle}>{translate('#SentText')}</h3>
                        <div className={styles.textLabel}>
                            {translate('#RegistrationLinkSentText')}
                        </div>
                    </>
                )}

                {magicLinkError && <div className={styles.errorLabel}>{magicLinkErrorText}</div>}
                {magicLinkSending && (
                    <div className={styles.activityIndicator}>
                        <ClipLoader color={'black'} />
                    </div>
                )}
                {!magicLinkSending && (
                    <Button
                        title={
                            onceSuccessSent
                                ? translate('#SendAgainText')
                                : translate('#SendMagicLinkText')
                        }
                        onClick={sendMagicLinkClick}
                        type="outlined"
                        color="green"
                    />
                )}

                <div className={styles.anonymousText}>
                    {translate('#SignInLegalText')}{' '}
                    <a href="https://site.reveal.world/terms" target="_blank">
                        {translate('#TermsOfUse')}
                    </a>{' '}
                    {translate('#AndText')}{' '}
                    <a href="https://site.reveal.world/privacy" target="_blank">
                        {translate('#PrivacyPolicyText')}
                    </a>
                    .
                </div>

                {props.showAnonymousOption && (
                    <>
                        <div className={styles.anonymousText}>
                            {translate('#OrText').toUpperCase()}
                        </div>
                        <Button
                            title={translate('#ContinueAnonymouslyBtn')}
                            fullWidth
                            onClick={onContinueAnonymouslyClick}
                        />
                        <p className={styles.anonymousText}>
                            {translate('#ContinueAnonymouslyText')}
                        </p>
                    </>
                )}
            </div>
        </ModalWindow>
    )
}

export { SignInRegisterModalWrapper }
