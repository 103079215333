import { BaseAPI } from '../index'

export class AuthorsSubscriptionsAPI<T> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchSubscribedAuthorsData() {
        try {
            return await this.requestService.get(`subscription/author`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
