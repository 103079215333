import colors from '@/core/theme/colors'
import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'absolute',
    },
    closeButton: {
        width: '36px',
        height: '36px',
    },
    headerContainer: {
        display: 'flex',
        padding: '16px',
        justifyContent: 'space-between',
        color: colors.secondaryText,
        gap: '16px',
        '& h1': {
            margin: 0,
        },
    },
    openButton: {
        padding: '8px',
        borderRadius: '10px',
        display: 'inline-block',
        cursor: 'pointer',
        userSelect: 'none',
        color: '#FFF',
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.secondary.main,
            backgroundColor: '#FFF',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px',
    },
    linksContaier: {
        padding: '0 16px',
    },
}))
