import { fade, withStyles } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const createCommonInputStyles = (theme: Theme) => {
    return createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(10),
            },
        },
        input: {
            position: 'relative',
            border: `1px solid ${theme.palette.success.main} `,
            fontSize: 16,
            width: '100%',
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    })
}

export const TextInputStyles = withStyles((theme: Theme) =>
    createStyles({
        root: createCommonInputStyles(theme).root,
        input: {
            ...createCommonInputStyles(theme).input,
            borderRadius: 4,
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.dark, 0.25)} 0 0 0 0.2rem`,
                backgroundColor: theme.palette.secondary.main,
            },
        },
    }),
)(InputBase)

export const ExploreInputStyles = withStyles((theme: Theme) =>
    createStyles({
        root: createCommonInputStyles(theme).root,
        input: {
            ...createCommonInputStyles(theme).input,
            borderRadius: '10px',
            backgroundColor: 'white',
            color: 'black',
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.dark, 0.25)} 0 0 0 0.2rem`,
            },
        },
    }),
)(InputBase)

export const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
    margin: {
        width: '100%',
        // margin: spacing(2),
    },

    errorText: {
        height: spacing(2),
        fontSize: '12px',
        paddingTop: spacing(4),
        color: palette.error.dark,
    },
}))
