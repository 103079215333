import { call, select, takeLatest } from 'redux-saga/effects'
import { makeRequestWithSpinner, OptionsType } from '../sagaWorkers/saga'
import { DefaultUserTypes } from '@/core/types/auth/authTypes'
import { requestAnyAction } from '@/core/types/types'
import { InterestsAPI } from '@/core/api/interestsAPI/interestsAPI'
import { actionsInterests } from '@/core/store/interests/actions'
import { AppState } from '@/core/store/rootReducer'

function* getInterests(): Generator {
    const interfaceLanguageCode: any = yield select(
        (state: AppState) => state.languageReducer.interfaceLanguageCode,
    )

    const fetcher = new InterestsAPI(interfaceLanguageCode)
    const options: OptionsType<DefaultUserTypes> = {
        fetcher: fetcher.fetchInterestsData.bind(fetcher),
        startFetching: actionsInterests.startLoadingInterestsData,
        stopFetching: actionsInterests.stopLoadingInterestsData,
        fill: actionsInterests.setInterestsData,
        setErrorAction: actionsInterests.setInterestDataError,
    }
    yield makeRequestWithSpinner<DefaultUserTypes>(options)
}

function* saveInterests({ payload }: requestAnyAction<DefaultUserTypes>): Generator {
    try {
        const fetcher = new InterestsAPI(payload)
        yield call(fetcher.saveInterestsData.bind(fetcher))
    } catch (e) {
        console.error('Catched Error in saveInterests', e)
    }
}

export function* watchInterests(): Generator {
    yield takeLatest('@/GET_INTERESTS_DATA', getInterests)
    yield takeLatest('@/SAVE_INTERESTS', saveInterests)
}
