import { takeLatest } from 'redux-saga/effects'
import { makeRequestWithSpinner, OptionsType } from '../sagaWorkers/saga'
import {
    DefaultUserTypes,
    ProfileTypes,
    sigInInRequestTypes,
    ServerResponseType,
} from '@/core/types/auth/authTypes'
import { AuthAPI } from '@/core/api/authAPI/authAPI'
import { actionsAuth } from '@/core/store/auth/actions'
import { requestAnyAction } from '@/core/types/types'
import { call, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { LocalStorage } from '@/core/constants/localStorage'

function* fetchSignUpDefaultUser({ payload }: requestAnyAction<any>): Generator {
    const fetcher = new AuthAPI(payload)
    const options: OptionsType<AxiosResponse<DefaultUserTypes>> = {
        fetcher: fetcher.signUpDefaultUser.bind(fetcher),
        startFetching: actionsAuth.startLoading,
        stopFetching: actionsAuth.stopLoading,
        fill: actionsAuth.fillDefaultUserResponseSuccess,
        setErrorAction: actionsAuth.fillDefaultUserResponseFall,
    }
    yield makeRequestWithSpinner<AxiosResponse<DefaultUserTypes>>(options)
}

function* fetchSignCredentials(payload: sigInInRequestTypes): Generator {
    const fetcher = new AuthAPI(payload)
    const options: OptionsType<AxiosResponse<ServerResponseType>> = {
        fetcher: fetcher.signInCredentials.bind(fetcher),
        startFetching: actionsAuth.startLoading,
        stopFetching: actionsAuth.stopLoading,
        fill: actionsAuth.fillSignInCredentialsSuccess,
        setErrorAction: actionsAuth.fillDefaultUserResponseFall,
    }
    yield makeRequestWithSpinner<AxiosResponse<ServerResponseType>>(options)
}

function* fetchProfile(): Generator {
    const fetcher = new AuthAPI(null)
    const options: OptionsType<ProfileTypes> = {
        fetcher: fetcher.getProfile.bind(fetcher),
        startFetching: actionsAuth.startLoading,
        stopFetching: actionsAuth.stopLoading,
        fill: actionsAuth.fillGetProfileResponseSuccess,
        setErrorAction: actionsAuth.fillDefaultUserResponseFall,
    }
    yield makeRequestWithSpinner<ProfileTypes>(options)
}

function* fetchSignIn({ payload }: requestAnyAction<sigInInRequestTypes>): Generator {
    yield call(fetchSignCredentials, payload)
    yield call(fetchProfile)
}

function* checkToken(): Generator {
    const defaultUserToken = localStorage.getItem(LocalStorage.DefaultUserToken)
    const accessToken = localStorage.getItem(LocalStorage.AccessToken)
    if (defaultUserToken || accessToken) {
        yield put(actionsAuth.setTokenExists(true))
    } else {
        yield put(actionsAuth.setTokenExists(false))
    }
    yield put(actionsAuth.setTokenChecked(true))
}

function* fetchLogOutUser(): Generator {
    const fetcher = new AuthAPI(null)
    const options: OptionsType<unknown> = {
        fetcher: fetcher.signOut.bind(fetcher),
        startFetching: actionsAuth.startLoading,
        stopFetching: actionsAuth.stopLoading,
        fill: actionsAuth.logOutUserResponseSuccess,
        setErrorAction: actionsAuth.fillDefaultUserResponseFall,
    }
    yield makeRequestWithSpinner<unknown>(options)
}

export function* watchAuth(): Generator {
    yield takeLatest('@/GET_SIGN_UP_DEFAULT_USER_ASYNC', fetchSignUpDefaultUser)
    yield takeLatest('@/GET_SIGN_IN_USER_ASYNC', fetchSignIn)
    yield takeLatest('@/GET_PROFILE', fetchProfile)
    yield takeLatest('@/CHECK_TOKEN', checkToken)
    yield takeLatest('@/LOG_OUT_USER_ASYNC', fetchLogOutUser)
}
