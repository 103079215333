import colors from '@/core/theme/colors'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    tabsContainer: {
        position: 'relative',
        width: '100%',
        backgroundColor: colors.lightGrey2,
        padding: '4px',
        borderRadius: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '24px',
    },
    tab: {
        width: '100%',
        textAlign: 'center',
        padding: '16px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: colors.black,
        borderRadius: '50px',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
            background: colors.lightGrey,
        },
    },
    selectedTab: {
        color: colors.white,
        '&:hover': {
            background: 'transparent',
        },
    },
    selectedTabBg: {
        background: colors.gradientViolet,
        position: 'absolute',
        top: '4px',
        height: 'calc(100% - 8px)',
        borderRadius: '50px',
        transition: 'all 0.3s ease',
    },
    typesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        color: colors.black,
        marginBottom: '24px',
        '& h3': {
            margin: 0,
        },
        '& .MuiInputBase-root': {
            maxWidth: '200px',
            width: '100%',
        },
        '& .MuiSelect-select:focus': {
            background: 'none',
        },
    },
}))
