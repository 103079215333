import { BaseAPI } from '../index'
import { nearestStoriesTypes } from '@/core/types/CollectionsTypes'
import { SingleStoryDataType } from '@/core/types/stories/storiesTypes'

export class StoriesAPI<T extends { [key: string]: any }> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchStoryByIdOrSlug() {
        try {
            return await this.requestService.get(`/rest/story/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchStories() {
        try {
            return await this.requestService.post(`/rest/stories`, this.data)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchCollections() {
        try {
            return await this.requestService.post(`/rest/collections`, this.data)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchStoriesByAuthor() {
        try {
            return await this.requestService.post(`/rest/stories/author`, this.data)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchStoriesByCollection() {
        try {
            return await this.requestService.get(`/rest/stories/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async likeStory() {
        try {
            return await this.requestService.get(`/like/story/${this.data.id}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async unlikeStory() {
        try {
            return await this.requestService.get(`/unlike/story/${this.data.id}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchNearestStories() {
        try {
            const res: nearestStoriesTypes = await this.requestService.post(`/nearest`, this.data)

            return res.items.map(
                ({ data }): SingleStoryDataType => ({
                    ...JSON.parse(data),
                }),
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchRecent() {
        try {
            const res: nearestStoriesTypes = await this.requestService.post(`/recent`, this.data)

            return res.items.map(
                ({ data }): SingleStoryDataType => ({
                    ...JSON.parse(data),
                }),
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
