import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { actionsGeneral } from '@/core/store/general'

const LocationChangeDispatcher = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionsGeneral.locationChange())
    }, [location])

    return null
}

export default LocationChangeDispatcher
