class LanguageAPI {
    data: string

    constructor(data: string) {
        this.data = data
    }

    public fetchLanguageData = async () => {
        try {
            return await import(`@resources/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}

export { LanguageAPI }
