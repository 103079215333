import { makeStyles } from '@material-ui/core/styles'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() => ({
    baseBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
        transition: 'all 0.3s ease',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
    },
    smallBtn: {
        padding: '8px 16px',
    },
    mediumBtn: {
        padding: '16px 24px',
    },
    fullWidth: {
        width: '100%',
    },
    filledBtn: {
        border: '2px solid',
        '&.darkBtn': {
            color: colors.darkGrey,
            borderColor: 'transparent',
            '&:not(.noHover):hover': {
                color: colors.white,
                background: colors.darkGrey,
                borderColor: colors.darkGrey,
            },
        },
        '&.greenBtn': {
            color: colors.darkGrey,
            background: colors.green,
            borderColor: colors.green,
            '&:not(.noHover):hover': {
                color: colors.white,
                background: colors.deepGreen,
                borderColor: colors.deepGreen,
            },
        },
        '&.whiteBtn': {
            color: colors.white,
            borderColor: 'transparent',
            '&:not(.noHover):hover': {
                color: colors.darkGrey,
                background: colors.white,
                borderColor: colors.white,
            },
        },
    },
    outlinedBtn: {
        border: '2px solid',
        background: 'transparent',
        '&.darkBtn': {
            borderColor: colors.darkGrey,
            color: colors.darkGrey,
            '&:not(.noHover):hover': {
                color: colors.white,
                background: colors.darkGrey,
            },
        },
        '&.greenBtn': {
            color: colors.darkGrey,
            borderColor: colors.green,
            '&:not(.noHover):hover': {
                background: colors.green,
            },
        },
        '&.whiteBtn': {
            color: colors.white,
            borderColor: colors.white,
            '&:not(.noHover):hover': {
                color: colors.darkGrey,
                background: colors.white,
            },
        },
    },
}))
