import React, { ReactElement, useCallback } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'

import { MemoizedMapContainer } from '@/containers/MapContainer/MapContainer'
import CollectionLinks from '@/components/CollectionLinks'
import TagsWrapper from '@/components/TagsWrapper'
import { ActionButtons } from '@/components/ActionsButtons'
import { Button } from '@/components/controls/Button'

import { useStyles } from './styles'

const StoryMeta = ({
    id,
    name,
    address,
    teaser,
    collections,
    interests,
    slugName,
    liked,
    lat,
    lng,
    author = {},
    mapProps = {},
}: any): ReactElement => {
    const translate = useTranslate()
    const styles = useStyles()

    const onNavigateClick = useCallback(() => {
        const googleMapURL = new URL(
            `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
        )

        window.open(googleMapURL, '_blank')
    }, [lat, lng])

    return (
        <div className={styles.root}>
            <div>
                <h1 className={styles.storyName}>{name}</h1>
                {address && <p>{address}</p>}
            </div>
            {teaser && <div dangerouslySetInnerHTML={{ __html: teaser }} />}
            {!!collections?.length && <CollectionLinks collections={collections} darkMode />}
            {!!interests?.length && <TagsWrapper tags={interests} />}
            <div className={styles.map}>
                <MemoizedMapContainer {...mapProps} />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    type="outlined"
                    color="white"
                    size="md"
                    title={translate('#NavigateText')}
                    onClick={onNavigateClick}
                />
            </div>
            <ActionButtons
                photoUrl={author.avatar}
                authorName={author.name}
                authorId={author.id}
                type="story"
                id={id}
                slugName={slugName}
                liked={liked}
                subscribed={author.subscribed}
                lightMode
            />
        </div>
    )
}

export default StoryMeta
