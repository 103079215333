import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { useStyles } from './styles'

const PageLayout = ({ greyBody, children }: any): ReactElement => {
    const styles = useStyles()
    return (
        <div className={styles.pageLayout}>
            <div className={clsx(styles.pageBody, { [styles.greyBody]: greyBody })}>{children}</div>
        </div>
    )
}

export default PageLayout
