import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getFavouritesState = (state: AppState): typeof initialState => state.favouritesReducer

export const getFavouritesCollections = createSelector(
    getFavouritesState,
    (state: any) => state.favouritesCollection,
)
export const getFavouritesStories = createSelector(
    getFavouritesState,
    (state: any) => state.favouritesStories,
)
