import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '428px',
            height: '309px',
            padding: theme.spacing(10),
            background: '#2A2A2A',
            color: 'white',
        },
        link: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            padding: theme.spacing(2),
            border: '1px solid #3FDC7F',
            borderRadius: '7px',
            cursor: 'pointer',
            color: 'white',
            textDecoration: 'none',
        },
        closeButton: {
            position: 'absolute',
            top: '40px',
            right: '40px',
            cursor: 'pointer',
        },
    }),
)
