import { makeStyles } from '@material-ui/core/styles'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() => ({
    boxContainer: {
        padding: '40px 0',
    },
    authorsListHeader: {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        '& h2': {
            fontSize: '24px',
            color: colors.black,
            margin: 0,
        },
    },
    authorsList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: '16px',
    },
    authorCard: {
        padding: '24px 16px',
        background: 'linear-gradient(144.87deg, #ECF1F3 -25.72%, #EBF0F2 81.28%)',
        borderRadius: '24px',
        minWidth: '160px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    authorPhoto: {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        marginBottom: '16px',
    },
    authorName: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '19px',
        textAlign: 'center',
        color: colors.black,
        marginBottom: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    authorInfo: {
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center',
        color: colors.black,
    },
}))
