import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
        },
    }),
)
