import React, { FC, useCallback } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'

import { Button } from '@/components/controls/Button'

import { StyledTag } from '@/components/StyledTag'

import { useStyles } from './styles'
import { useDispatch } from 'react-redux'
import { actionsGeneral } from '@/core/store/general'
import { ModalTypes } from '@/core/constants/modal'

type TagDataType = {
    id: number
    name: string
    image: string
    subscribed: boolean
}
type InterestsDataProps = {
    data: TagDataType[]
    handleInputChange: (slugName: string) => void
    selectedInterestId?: number
}

const InterestsBox: FC<InterestsDataProps> = ({
    data = [],
    handleInputChange,
    selectedInterestId,
}: InterestsDataProps) => {
    const translate = useTranslate()
    const dispatch = useDispatch()
    const styles = useStyles()

    const onChooseClick = useCallback(() => {
        dispatch(actionsGeneral.showModal(ModalTypes.INTERESTS_MODAL, {}))
    }, [])

    return (
        <div className={styles.interestsContainer}>
            <div className={styles.interestsHeader}>
                <h2>{translate('#CategoriesText')}</h2>
                <Button
                    title={translate('#ChooseButtonText')}
                    type="outlined"
                    color="dark"
                    onClick={onChooseClick}
                />
            </div>
            <div className={styles.interestsList}>
                {data.map(interest => (
                    <StyledTag
                        id={interest.id}
                        key={interest.id}
                        name={interest.name}
                        image={interest.image}
                        onClick={handleInputChange}
                        subscribed={
                            selectedInterestId
                                ? selectedInterestId === interest.id
                                : interest.subscribed
                        }
                    />
                ))}
            </div>
        </div>
    )
}

export default InterestsBox
