import colors from '@/core/theme/colors'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        card: {
            background: 'white',
            borderRadius: '24px',
            color: 'black',
            overflow: 'hidden',
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
        },
        headerContainer: {
            width: '100%',
            height: '200px',
            position: 'relative',
            cursor: 'pointer',
            backgroundColor: colors.secondaryBackground,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: ({ image }: any) =>
                image
                    ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${image}")`
                    : 'none',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: '16px',
        },
        topHeaderContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%',
        },
        title: {
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '28px',
            color: colors.white,
            margin: 0,
        },
        location: {
            fontSize: '12px',
            color: colors.white,
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            margin: '8px 0',
        },
        bottomHeaderContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textTransform: 'uppercase',
            color: colors.white,
        },
        collectionIcon: {
            marginRight: '8px',
        },
        content: {
            margin: '24px',
            position: 'relative',
            fontSize: '14px',
            fontWeight: 400,
        },
    }),
)
