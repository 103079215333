import { BaseAPI } from '../index'
import { newCollectionsPreviewTypes, newsCollectionDataTypes } from '@/core/types/CollectionsTypes'
import { SingleStoryDataType } from '@/core/types/stories/storiesTypes'

export class CollectionsSiteBarAPI<T> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchCollectionData() {
        try {
            // const geolocation = await getUserGeolocation()
            // const body = {
            //     ...geolocation,
            //     startNum: 0,
            //     maxAmount: 99999,
            //     timestamp: Date.now(),
            // }

            const res = await this.requestService.post(`/news/collections`, this.data)
            return res.news.map(
                ({ data }: newsCollectionDataTypes<string>): newCollectionsPreviewTypes => ({
                    ...JSON.parse(data),
                }),
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchStoriesPreviewData() {
        try {
            // const geolocation = await getUserGeolocation()
            // const body = {
            //     ...geolocation,
            //     startNum: 0,
            //     maxAmount: 99999,
            //     timestamp: Date.now(),
            // }

            const res = await this.requestService.post(`news/stories`, this.data)
            return res.news.map(
                ({ data }: newsCollectionDataTypes<string>): SingleStoryDataType => ({
                    ...JSON.parse(data),
                }),
            )
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
