import { createSelector } from 'reselect'
import { AppState } from '../rootReducer'
import { initialState } from './reducer'

const getMapState = (state: AppState): typeof initialState => state.mapReducer

export const getMapView = createSelector(getMapState, state => state)
export const getUserCoordinates = createSelector(getMapState, state => state.userCoordinates)
export const getOriginCoordinates = createSelector(getMapState, state => state.originCoordinates)
export const getAllMarkers = createSelector(getMapState, state => state.allMarkers)
export const getSearchMarkers = createSelector(getMapState, state => state.searchResultMarkers)
export const getPageMarkers = createSelector(getMapState, state => state.pageMarkers)
