import React, { FC, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslate } from '@/core/hooks/useTranslate'

import { Helmet } from 'react-helmet'
import CollectionList from '@/components/CollectionList'
import StoriesList from '@/components/StoriesList'
import InterestsBox from '@/components/InterestsBox'
import PageLayout from '@/components/PageLayout'
import FilledTabs from '@/components/controls/FilledTabs'
import PermissionsDriver from '@/components/PermissionsDriver'
import InfinityScrollList from '@/components/InfinityScrollList'
import Spinner from '@/components/controls/Spinner'

import {
    actionsCollection,
    getNearesCollections,
    getNewsCollections,
} from '@/core/store/collection'
import { actionsStories, getNearestStories, getRecentStories } from '@/core/store/stories'
import { getInterests, getInterestsFetchError } from '@/core/store/interests'
import { getDataLanguagesCodes } from '@/core/store/language'
import { actionsAuthor, getStartPageAuthors } from '@/core/store/author'
import { getUserCoordinates } from '@/core/store/map'

import { requestsScrollingData } from '@/core/types/types'

const HomePage: FC = (): ReactElement => {
    const dispatch = useDispatch()
    const translate = useTranslate()
    const history = useHistory()

    const [tab, setTab] = React.useState(0)
    const [type, setType] = React.useState(0)

    const nearestCollections = useSelector(getNearesCollections)
    const newsCollections = useSelector(getNewsCollections)
    const nearestStories = useSelector(getNearestStories)
    const recentStories = useSelector(getRecentStories)

    const languages = useSelector(getDataLanguagesCodes)
    const interests = useSelector(getInterests)
    const interestsFetchError = useSelector(getInterestsFetchError)
    const startPageAuthors = useSelector(getStartPageAuthors)
    const userCoordinates = useSelector(getUserCoordinates)

    const selectedInterests = interests
        .filter((interest: any) => interest.subscribed)
        .map((interest: any) => interest.name)
        .join(',')

    useEffect(() => {
        dispatch(actionsAuthor.getStartPageAuthors(languages))
    }, [languages])

    useEffect(() => {
        if (
            nearestCollections.error ||
            newsCollections.error ||
            nearestStories.error ||
            recentStories.error ||
            interestsFetchError ||
            startPageAuthors.error
        ) {
            throw new Error('Error fetching data for Home Page')
        }
    }, [
        nearestCollections.error,
        newsCollections.error,
        nearestStories.error,
        recentStories.error,
        interestsFetchError,
        startPageAuthors.error,
    ])

    const fetchNearesCollections = (data: requestsScrollingData) =>
        actionsCollection.getNearestCollections({
            languages,
            ...data,
        })
    const fetchNewsCollections = (data: requestsScrollingData) =>
        actionsCollection.getCollectionsAsync({
            languages,
            ...data,
        })
    const fetchNearestStories = (data: requestsScrollingData) =>
        actionsStories.getNearestStories({
            languages,
            ...data,
        })
    const fetchRecentStories = (data: requestsScrollingData) =>
        actionsStories.getRecentStories({
            languages,
            ...data,
        })

    const handleTabChange = (newValue: number) => {
        setTab(newValue)
    }
    const handleTypeChange = (newValue: number) => {
        setType(newValue)
    }

    const handleCategoryClick = (slugName: string) => {
        history.push(`/interest/${slugName}`)
    }

    const title = 'Reveal World ' + translate('#NewsPageCaption')

    const homePageContent = (
        <>
            {tab === 0 && type === 0 && (
                <InfinityScrollList
                    key={`${selectedInterests}tab${tab}type${type}`}
                    data={newsCollections}
                    languages={languages}
                    RenderComponent={CollectionList}
                    componentDataProp="collections"
                    renderComponentProps={{
                        showDonateCard: false,
                        authorsList: startPageAuthors.data,
                    }}
                    fetchData={fetchNewsCollections}
                    clearData={actionsCollection.clearCollectionsData('newsCollections')}
                />
            )}
            {tab === 0 && type === 1 && (
                <InfinityScrollList
                    key={`${selectedInterests}tab${tab}type${type}`}
                    data={nearestCollections}
                    languages={languages}
                    RenderComponent={CollectionList}
                    componentDataProp="collections"
                    renderComponentProps={{
                        showDonateCard: false,
                        authorsList: startPageAuthors.data,
                    }}
                    fetchData={fetchNearesCollections}
                    clearData={actionsCollection.clearCollectionsData('nearestCollections')}
                />
            )}
            {tab === 1 && type === 0 && (
                <InfinityScrollList
                    key={`${selectedInterests}tab${tab}type${type}`}
                    data={recentStories}
                    languages={languages}
                    RenderComponent={StoriesList}
                    renderComponentProps={{
                        showDonateCard: false,
                    }}
                    componentDataProp="stories"
                    fetchData={fetchRecentStories}
                    clearData={actionsStories.clearStories('recentStories')}
                />
            )}
            {tab === 1 && type === 1 && (
                <InfinityScrollList
                    key={`${selectedInterests}tab${tab}type${type}`}
                    data={nearestStories}
                    languages={languages}
                    RenderComponent={StoriesList}
                    renderComponentProps={{
                        showDonateCard: false,
                    }}
                    componentDataProp="stories"
                    fetchData={fetchNearestStories}
                    clearData={actionsStories.clearStories('nearestStories')}
                />
            )}
        </>
    )

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={title} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={title} />
                <meta property="fb:app_id" content="418921822030203" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={title} />
            </Helmet>
            {type === 1 && <PermissionsDriver />}
            <PageLayout greyBody>
                <InterestsBox data={interests} handleInputChange={handleCategoryClick} />
            </PageLayout>
            <PageLayout>
                <FilledTabs
                    onTabChange={handleTabChange}
                    onTypeChange={handleTypeChange}
                    showTypes
                    showRecentType
                />
                {type === 1 ? (
                    userCoordinates.isSet ? (
                        homePageContent
                    ) : (
                        <Spinner />
                    )
                ) : (
                    homePageContent
                )}
            </PageLayout>
        </>
    )
}

export default HomePage
