import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useScroll } from '@/core/hooks/useScroll'
import { useTranslate } from '@/core/hooks/useTranslate'

import CollectionList from '@/components/CollectionList'
import NoPhoto from '@/assets/no_photo.png'

import { actionsAuthor, getAuthorData, getAuthorDataFetchError } from '@/core/store/author'
import { actionsCollection, getAuthorCollections } from '@/core/store/collection'

import { requestsScrollingData } from '@/core/types/types'

import { useStyles } from './styles'

const AuthorPage: FC = () => {
    const childRef = useRef<any>()
    const dispatch = useDispatch()
    const translate = useTranslate()
    const styles = useStyles()
    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        dispatch(actionsAuthor.getAuthorData(id))
        return () => {
            dispatch(actionsAuthor.clearAuthorData())
            dispatch(actionsCollection.clearCollectionsData('authorCollections'))
        }
    }, [])

    const collections = useSelector(getAuthorCollections)
    const authorData = useSelector(getAuthorData)
    const authorDataFetchError = useSelector(getAuthorDataFetchError)

    useEffect(() => {
        if (authorDataFetchError || collections.error) {
            throw new Error('Error fetching data for Author Page')
        }
    }, [authorDataFetchError, collections.error])

    const fetchData = (data: requestsScrollingData) =>
        actionsCollection.getCollectionByAuthor({
            startNum: data.page,
            maxAmount: data.limit,
            author: authorData?.uuid,
        })
    useScroll({
        parentRef: null,
        childRef,
        loading: collections.loading || collections.loaded || !authorData?.uuid,
        callback: fetchData,
    })

    return (
        <>
            <div className={styles.darkContainer}>
                {!!authorData && (
                    <>
                        <div className={styles.userInfoWrapper}>
                            <img
                                src={authorData.avatar || NoPhoto}
                                alt="photo"
                                className={styles.photoUser}
                            />
                            <div>
                                <h1 className={styles.noMargin}>{authorData.username}</h1>
                                <div className={styles.authorInfoWrapper}>
                                    <div className={styles.authorAdditionalInfo}>
                                        <div>
                                            <b>{authorData.followersCount}</b>
                                        </div>
                                        <div>{translate('#FollowersLabel')}</div>
                                    </div>
                                    <div className={styles.authorAdditionalInfo}>
                                        <div>
                                            <b>{authorData.storiesCount}</b>
                                        </div>
                                        <div>{translate('#StoriesLabel')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {authorData.bio && <div>{authorData.bio}</div>}
                        {authorData.website && (
                            <div>
                                {translate('#WebsiteText')}:{' '}
                                <a
                                    href={authorData.website}
                                    target="_blank"
                                    className={styles.link}
                                >
                                    {authorData.website}
                                </a>
                            </div>
                        )}
                    </>
                )}
            </div>
            <CollectionList collections={collections.data} showDonateCard />
            <div ref={childRef} style={{ height: '30px' }} />
        </>
    )
}

export default AuthorPage
