import { RedirectFromType } from '@/core/types/search'
import {
    requestsScrollingData,
    ResponseSearchItemType,
    SearchResponseTypes,
} from '@/core/types/types'

export const actionsSearch = {
    getSearchDataAsync: (payload: requestsScrollingData) =>
        ({
            type: '@/GET_SEARCH_DATA_APP_ASYNC',
            payload,
        } as const),

    startSearchDataApp: () =>
        ({
            type: '@/START_SEARCH_DATA_APP_',
        } as const),

    stopSearchDataApp: () =>
        ({
            type: '@/STOP_SEARCH_DATA_APP_',
        } as const),

    fillSearchDataAppSuccess: (
        searchValue: string,
        data: Array<SearchResponseTypes<ResponseSearchItemType>>,
    ) =>
        ({
            type: '@/FILL_SEARCH_DATA_APP_SUCCESS',
            payload: { data, searchValue },
        } as const),

    fillSearchDataAppError: (payload: string) =>
        ({
            type: '@/FILL_SEARCH_DATA_APP_Error',
            payload,
        } as const),

    setRefirectedFrom: (payload: RedirectFromType) =>
        ({
            type: '@/SET_REDIRECTED_FROM_SEARCH_PAGE',
            payload,
        } as const),

    clearSearchDataApp: () =>
        ({
            type: '@/CLEAR_SEARCH_DATA_APP_',
        } as const),
}
