// Core
import { combineReducers } from 'redux'
// Reducers
import { reducer as authReducer } from '../store/auth/reducer'
import { reducer as generalReducer } from '../store/general/reducer'
import { reducer as searchReducer } from '../store/search/reducer'
import { reducer as favouritesReducer } from '../store/favourites/reducer'
import { reducer as interestsReducer } from '../store/interests/reducer'
import { reducer as authorReducer } from '../store/author/reducer'
import { reducer as storiesReducer } from '../store/stories/reducer'
import { reducer as mapReducer } from '../store/map/reducer'
import { reducer as collectionReducer } from '../store/collection/reducer'
import { reducer as subscriptionsReducer } from '../store/subscriptions/reducer'
import { reducer as languageReducer } from './language/reducer'
import { reducer as magicLinksReducer } from '../store/magicLinks/reducer'

export const rootReducer = combineReducers({
    authReducer,
    generalReducer,
    searchReducer,
    favouritesReducer,
    interestsReducer,
    authorReducer,
    storiesReducer,
    mapReducer,
    collectionReducer,
    subscriptionsReducer,
    languageReducer,
    magicLinksReducer,
})

export type AppState = ReturnType<typeof rootReducer>
