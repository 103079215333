import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        centerDiv: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        activityIndicator: {
            margin: 10,
            textAlign: 'center',
        },
        errorLabel: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#909090',
        },
    }),
)
