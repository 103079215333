import React, { FC, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MapIcon from '@material-ui/icons/LocationOnOutlined'
import ListIcon from '@material-ui/icons/ViewList'

import { actionsGeneral, getMapView } from '@/core/store/general'
import colors from '@/core/theme/colors'

const ViewSwitcher: FC = (): ReactElement => {
    const isMapView = useSelector(getMapView)
    const dispatch = useDispatch()

    const onModeToggle = () => dispatch(actionsGeneral.setMapView(!isMapView))

    return isMapView ? (
        <ListIcon
            onClick={onModeToggle}
            fontSize="medium"
            style={{ cursor: 'pointer', color: colors.darkGrey }}
        />
    ) : (
        <MapIcon
            onClick={onModeToggle}
            fontSize="medium"
            style={{ cursor: 'pointer', color: colors.darkGrey }}
        />
    )
}

export default ViewSwitcher
