export const subscribeMapper = (state: any, id: string, like: boolean) => {
    return state.map((item: any) => {
        if (item.author.id === id) {
            return {
                ...item,
                author: {
                    ...item.author,
                    subscribed: like,
                },
            }
        }
        return item
    })
}

export const reducerSubscribeMapper = (state: any, id: string, like: boolean) => ({
    ...state,
    data: subscribeMapper(state.data, id, like),
})
