import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    tab: {
        borderBottom: `2px solid ${theme.palette.text.hint}`,
        color: theme.palette.secondary.main,
        padding: '16px 0',
    },
    notFoundText: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
    },
}))
