import React, { ReactElement, useCallback } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'

import { Button } from '@/components/controls/Button'

import { useStyles } from './styles'

const PaidCollectionCard = (): ReactElement => {
    const translate = useTranslate()
    const styles = useStyles()
    const onProjectSupportClick = useCallback(() => {
        window.open('https://www.patreon.com/RevealWorld', '_blank')
    }, [])

    const onAuthorSupportClick = useCallback(() => {
        window.open('https://site.reveal.world/donate', '_blank')
    }, [])

    return (
        <div className={styles.paidCardContainer}>
            <div className={styles.title}>{translate('#PaidCollectionTitle')}</div>
            <div className={styles.description}>{translate('#PaidCollectionText')}</div>
            <Button
                type="filled"
                color="green"
                title={translate('#ThankAuthorBtnTitle')}
                size="md"
                fullWidth
                onClick={onAuthorSupportClick}
            />
            <div className={styles.dividerText}>{translate('#And/Or')}</div>
            <Button
                type="filled"
                color="green"
                title={translate('#SupportRevealBtnTitle')}
                size="md"
                fullWidth
                onClick={onProjectSupportClick}
            />
        </div>
    )
}

export default PaidCollectionCard
