import { ChangeEvent, useState } from 'react'

const useCheckbox = (initialValue: boolean) => {
    const [Value, setValue] = useState(initialValue)
    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked)
    }

    return { Value, handleCheckboxChange }
}

export { useCheckbox }
