export const CENTER_RIGHT = 8

export const DirectionType: any = {
    0: 'PointSet',
    1: 'WALKING',
    2: 'DRIVING',
    3: 'BICYCLE',
}

export const defaultUserPosition = {
    lat: 59.33258,
    lng: 18.0649,
    timestamp: new Date().getTime(),
}
