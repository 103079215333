import { ReturnAnyActions } from '../../types/types'
import { actionsSubscriptions } from './actions'
import { AuthorFollowTypes } from '@/core/types/subscriptionsTypes/subscriptionTypes'

export const initialState = {
    authorsSubscriptions: [] as Array<AuthorFollowTypes>,
    error: null as string | null,
    loading: false,
}

export type State = typeof initialState

export type SubscriptionsActions = ReturnAnyActions<typeof actionsSubscriptions>

export const reducer = (state: State = initialState, action: SubscriptionsActions): State => {
    switch (action.type) {
        case '@/GET_AUTHORS_SUBSCRIPTIONS': {
            return {
                ...state,
            }
        }
        case '@/SET_AUTHORS_SUBSCRIPTIONS': {
            return {
                ...state,
                error: null,
                authorsSubscriptions: action.payload,
            }
        }
        case '@/START_LOADING_AUTHORS_SUBSCRIPTIONS': {
            return {
                ...state,
                loading: true,
            }
        }
        case '@/STOP_LOADING_AUTHORS_SUBSCRIPTIONS': {
            return {
                ...state,
                loading: false,
            }
        }
        case '@/SET_AUTHORS_SUBSCRIPTIONS_ERROR':
            return {
                ...state,
                error: action.payload,
            }
        default: {
            return state
        }
    }
}
