import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getGeneralState = (state: AppState): typeof initialState => state.generalReducer

export const appInstallRequestedFlag = createSelector(
    getGeneralState,
    state => state.appInstallRequestedFlag,
)

export const getModalType = createSelector(getGeneralState, (state: any) => state.modalType)
export const getModalProps = createSelector(getGeneralState, (state: any) => state.modalProps)

export const getModalData = createSelector(
    getModalType,
    getModalProps,
    (type: string, props: Record<string, unknown>) => ({ type, props }),
)

export const getMapView = createSelector(getGeneralState, (state: any) => state.isMapView)
