import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
} from '@material-ui/core'
import { useStyles } from './styles'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import { useMediaQuery } from 'react-responsive'
import { AddToHomeModalPropsType } from '@/core/constants/modal'
import { FormattedMessage } from 'react-intl'
import { CloseButton } from '@/components/controls/CloseButton'

export const AddToHomeModal = (props: AddToHomeModalPropsType) => {
    const styles = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const { hideModal } = useGlobalModalContext()
    const closeModal = () => {
        hideModal()
        props.afterCloseModal.call(this)
    }
    const okClick = () => {
        hideModal()
        props.onInstallClick()
    }

    return (
        <Dialog
            onClose={closeModal}
            open
            classes={{ paper: isMobile ? styles.paperMobile : styles.paper }}
            disablePortal
            maxWidth="sm"
            scroll="paper"
            aria-labelledby="customized-dialog-title"
            aria-describedby="customized-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography variant="h4" color="textPrimary">
                        <FormattedMessage id="AddToHomeHeader" />
                    </Typography>
                    <CloseButton onClick={closeModal} />
                </div>
            </DialogTitle>
            <DialogContent style={{ padding: '16px 24px' }}>
                <Typography>
                    <FormattedMessage id="AddToHomeText" />
                </Typography>
            </DialogContent>
            <DialogActions style={{ padding: '16px 24px' }}>
                <Button onClick={okClick} className={styles.button}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
