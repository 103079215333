import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getSearchState = (state: AppState): typeof initialState => state.searchReducer

export const getSearchValue = createSelector(getSearchState, state => state.searchValue)
export const getSearchDataResponse = createSelector(getSearchState, state => state.searchData)
export const getLoadingSearch = createSelector(getSearchState, state => state.loadingSearchData)
export const getSearchError = createSelector(getSearchState, state => state.error)
export const getRedirectFromSearch = createSelector(getSearchState, state => state.redirectedFrom)
