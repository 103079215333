import React from 'react'

import UserIcon from '@material-ui/icons/AccountCircle'

import { useStyles } from './styles'

const UserMarker = ({ _ }: any) => {
    const styles = useStyles()

    return <UserIcon className={styles.marker} />
}

export default UserMarker
