import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './styles'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useMediaQuery } from 'react-responsive'
import { WizardContent } from './WizardContent'
import { WizardModalPropsType } from '@/core/constants/modal'

const WizardModal = (props: WizardModalPropsType) => {
    const styles = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const { hideModal } = useGlobalModalContext()
    const handleModalToggle = () => {
        hideModal()
        props.afterCloseModal.call(this)
    }

    return (
        <Dialog
            onClose={handleModalToggle}
            open={true}
            disableEnforceFocus
            disablePortal
            classes={{ paper: isMobile ? styles.paperMobile : styles.paper }}
            maxWidth="md"
        >
            <CloseIcon className={styles.closeButton} onClick={handleModalToggle} />
            <DialogContent style={{ padding: '0px 0px' }}>
                <WizardContent
                    handleSkipClick={handleModalToggle}
                    handleAlreadyRegisteredClick={props.alreadyRegisteredClicked}
                />
            </DialogContent>
        </Dialog>
    )
}

export { WizardModal }
