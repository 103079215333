import colors from '@/core/theme/colors'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        darkContainer: {
            display: 'flex',
            flexDirection: 'column',
            background: colors.darkGrey,
            flexWrap: 'wrap',
            color: colors.white,
            width: '100%',
            marginBottom: '24px',
            padding: '24px',
            borderRadius: '24px',
            boxShadow: '0px 4px 13px rgb(0 0 0 / 8%)',
            gap: '16px',
        },
        collectionName: {
            margin: 0,
        },
        collectionTypeContainer: {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
        },
        collectionDescription: {
            fontSize: '14px',
            fontWeight: 400,
        },
        collectionIcon: {
            marginRight: '8px',
        },
        storiesCountTitle: {
            fontFamily: 'Roboto, sans-serif',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            margin: '24px 0',
        },
        map: {
            height: '260px',
            width: '100%',
            minHeight: '260px',
        },
    }),
)
