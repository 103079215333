import React, { ReactElement } from 'react'
import { Route, Switch, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import LocationChangeDispatcher from '@/components/LocationChangeDispatcher'
import Header from '@/components/Header'
import MainMap from '@/components/MainMap'
import SearchPage from '@/pages/SearchPage'
import PageLayout from '@/components/PageLayout'
import ErrorBoundary from './components/ErrorBoundary'
import HomePage from '@/pages/HomePage'
import CollectionPage from '@/pages/CollectionPage'
import StoryPage from '@/pages/StoryPage'
import InterestPage from '@/pages/InterestPage'
import AuthorPage from '@/pages/AuthorPage'
import SignInCommitContaier from './containers/SignInCommitContainer'
import RegistrationCommitContainer from './containers/RegistrationCommitContainer'
import ActivationCommitContainer from './containers/ActivationCommitContainer'
import ResetPasswordCommitContainer from './containers/ResetPasswordCommitContainer'
import AddToHomeScreenDriver from './components/AddToHomeScreenDriver'

import { getMapView } from '@/core/store/general'

const PageLayoutRoute = (routeProps: RouteProps) => (
    <PageLayout>
        <Route {...routeProps} />
    </PageLayout>
)

export default (): ReactElement => {
    const isMapView = useSelector(getMapView)

    return (
        <>
            <LocationChangeDispatcher />
            <AddToHomeScreenDriver />
            <Header />
            <MainMap />
            <div style={{ display: isMapView ? 'none' : 'block' }}>
                <ErrorBoundary>
                    <Switch>
                        <Route
                            path="/auth/signin/confirm/:token"
                            component={SignInCommitContaier}
                        />
                        <Route
                            path="/auth/signup/confirm/:token"
                            component={RegistrationCommitContainer}
                        />
                        <Route path="/auth/activate/:token" component={ActivationCommitContainer} />
                        <Route
                            path="/auth/password/reset/:token"
                            component={ResetPasswordCommitContainer}
                        />
                        <Route path="/" exact component={HomePage} />
                        <PageLayoutRoute path="/collection/:slugName" component={CollectionPage} />
                        <PageLayoutRoute path="/story/:slugName" component={StoryPage} />
                        <Route path="/interest/:slugName" component={InterestPage} />
                        <PageLayoutRoute path="/author/:id" component={AuthorPage} />
                        <PageLayoutRoute path="/search" component={SearchPage} />
                    </Switch>
                </ErrorBoundary>
            </div>
        </>
    )
}
