enum expiresStatusUser {
    neverExpires,
}

export type DefaultUserTypes = {
    uuid: string
    expiresOn: expiresStatusUser.neverExpires
}

export type sigInInRequestTypes = {
    email: string
    password: string
    rememberMe: boolean
}

export type SendMagicLinkType = {
    email: string
    currentUrl: string
}

export type ServerResponseType = {
    message: string
    success: boolean
}

export type MagicLinkVerificationResponseType = {
    message: string
    success: boolean
    redirectUrl: string
}

export enum UserRole {
    Default = 'Default',
    SimpleUser = 'SimpleUser',
    Writer = 'Writer',
    Moderator = 'Moderator',
    Administrator = 'Administrator',
}

export type ProfileTypes = {
    username: string
    avatar: string
    name: string
    website: string
    bio: string
    role: UserRole
    creationDt: number
    nearNotifications: boolean
    pushNotifications: boolean
}

/**
 * @param token - UUID token value
 */
export type UserActivationType = {
    token: string
}

/**
 * Type for credentials registration
 */
export type SignUpType = {
    email: string
    username: string
    password: string
}

export enum RegistrationResponseEnumType {
    Success = 'Success',
    NotValidEmail = 'NotValidEmail',
    EmailOccupied = 'EmailOccupied',
    UsernameOccupied = 'UsernameOccupied',
    InvalidLink = 'InvalidLink',
}

export type RegistrationResponseType = {
    responseType: RegistrationResponseEnumType
    message: string
    success: boolean
}

export type ResetPasswordType = {
    token: string
    password: string
    confirm: string
}

export enum ResetPasswordResponseEnumType {
    Success = 'Success',
    ShortPassword = 'ShortPassword',
    PasswordNotMatch = 'PasswordNotMatch',
    InvalidLink = 'InvalidLink',
}

export type ResetPasswordResponseType = {
    responseType: ResetPasswordResponseEnumType
    message: string
    success: boolean
}

export enum ForgotPasswordResponseEnumType {
    Success = 'Success',
    NotValidEmail = 'NotValidEmail',
    UserNotExists = 'UserNotExists',
}

export type ForgotPasswordResponseType = {
    responseType: ForgotPasswordResponseEnumType
    message: string
    success: boolean
}
