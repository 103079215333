import { GetMarkersByIdsPayload, PointerType, SetMarkersByIdsPayload } from '@/core/types/map'

export const actionsMap = {
    setMapView: (view: string) =>
        ({
            type: '@/SET_MAP_VIEW',
            payload: view,
        } as const),
    unsetMapView: () =>
        ({
            type: '@/UNSET_MAP_VIEW',
        } as const),

    setCurrentPosition: (data: any) =>
        ({
            type: '@/SET_CURRENT_POSITION',
            payload: data,
        } as const),

    setUserPositionRequested: (data: boolean) =>
        ({
            type: '@/SET_USER_POSITION_REQUESTED',
            payload: data,
        } as const),
    setUserPosition: (data: any) =>
        ({
            type: '@/SET_USER_POSITION',
            payload: data,
        } as const),

    getAllMarkers: () =>
        ({
            type: '@/GET_ALL_MARKERS',
        } as const),
    setAllMarkers: (payload: Array<PointerType>) =>
        ({
            type: '@/SET_ALL_MARKERS',
            payload,
        } as const),

    getMarkersByIds: (payload: GetMarkersByIdsPayload) =>
        ({
            type: '@/GET_MARKERS_BY_IDS',
            payload,
        } as const),
    setMarkersByIds: (payload: SetMarkersByIdsPayload) =>
        ({
            type: '@/SET_MARKERS_BY_IDS',
            payload,
        } as const),
}
