import { ChangeEvent, useState } from 'react'

const useInput = (initialValue: string) => {
    const [inputValue, setValue] = useState(initialValue)

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }

    return { inputValue, handleInputChange, setValue }
}

export { useInput }
