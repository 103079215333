import React, { FC, ReactElement } from 'react'
import { useMediaQuery } from 'react-responsive'

import { Link } from 'react-router-dom'
import LogoSrc from '@/assets/RevealLogoIcon.svg'

import { useStyles } from './styles'

const Logo: FC = (): ReactElement => {
    const styles = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 600 })
    return (
        <Link to="/" className={styles.logoContainer}>
            <img src={LogoSrc} alt="Logo" width="28px" height="28px" />
            {!isMobile && <div>Reveal</div>}
        </Link>
    )
}

export default Logo
