import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import { useTranslate } from '@/core/hooks/useTranslate'

import ModalWindow from '@/components/controls/ModalWindow'
import { Button } from '@/components/controls/Button'

import {
    actionsLanguage,
    getDataLanguagesCodes,
    getInterfaceLanguageCode,
} from '@/core/store/language'

import { LanguageType } from '@/core/types/languages'
import { LANGUAGES } from '@/core/constants/languages'

import { useStyles } from './styles'

const LanguageModal = (): ReactElement => {
    const styles = useStyles()
    const dispatch = useDispatch()
    const translate = useTranslate()
    const { hideModal } = useGlobalModalContext()

    const interfaceLanguageCode = useSelector(getInterfaceLanguageCode)
    const dataLanguagesCodes = useSelector(getDataLanguagesCodes)

    const [selectedInterfaceLanguage, setInterfaceSelectedLanguage] = useState<string>(
        interfaceLanguageCode || 'en',
    )
    const [selectedDataLanguages, setDataSelectedLanguages] = useState<Array<string>>(
        dataLanguagesCodes || ['en'],
    )
    const [contentLanguagesError, setContentLanguagesError] = useState(false)

    const handleInterfaceLanguageChange = (code: string) => {
        setInterfaceSelectedLanguage(code)
    }
    const handleDataLanguageChange = (code: string) => {
        const newLanguages = selectedDataLanguages.includes(code)
            ? selectedDataLanguages.filter(e => e !== code)
            : selectedDataLanguages.concat(code)
        setDataSelectedLanguages(newLanguages)

        setContentLanguagesError(!newLanguages.length)
    }

    const handleLanguageSave = () => {
        let shouldRefreshPage = false

        if (interfaceLanguageCode !== selectedInterfaceLanguage) {
            const { resource } = LANGUAGES[selectedInterfaceLanguage]

            dispatch(actionsLanguage.setInterfaceLanguageCode(selectedInterfaceLanguage))
            dispatch(actionsLanguage.getInterfaceLanguageData(resource))
            shouldRefreshPage = true
        }

        if (JSON.stringify(dataLanguagesCodes) !== JSON.stringify(selectedDataLanguages)) {
            dispatch(actionsLanguage.setDataLanguagesCodes(selectedDataLanguages))
            shouldRefreshPage = true
        }

        hideModal()
        shouldRefreshPage && (window.location.href = '/')
    }

    return (
        <ModalWindow onClose={hideModal} open>
            <div className={styles.root}>
                <h1 className={styles.title}>{translate('#LanguagesText')}</h1>
                <div>
                    <h2 className={styles.subTitle}>{translate('#InterfaceLanguage')}</h2>
                    <div className={styles.languagesList}>
                        {Object.values(LANGUAGES).map((language: LanguageType) => {
                            const { code, name } = language
                            const isSelected = code === selectedInterfaceLanguage

                            return (
                                <Button
                                    key={code}
                                    title={name}
                                    onClick={() => handleInterfaceLanguageChange(code)}
                                    type={isSelected ? 'filled' : 'outlined'}
                                    color="green"
                                    noHover
                                />
                            )
                        })}
                    </div>
                </div>
                <div>
                    <h2 className={styles.subTitle}>{translate('#ContentLanguage')}</h2>
                    <div className={styles.languagesList}>
                        {Object.values(LANGUAGES).map((language: LanguageType) => {
                            const { code, name } = language
                            const isSelected = selectedDataLanguages.includes(code)

                            return (
                                <Button
                                    key={code}
                                    title={name}
                                    onClick={() => handleDataLanguageChange(code)}
                                    type={isSelected ? 'filled' : 'outlined'}
                                    color="green"
                                    noHover
                                />
                            )
                        })}
                    </div>
                    {contentLanguagesError && (
                        <div className={styles.errorText}>
                            {translate('#ContentLanguagesErrorText')}
                        </div>
                    )}
                </div>
                <Button
                    title={translate('#SaveButtonText')}
                    onClick={handleLanguageSave}
                    fullWidth
                    disabled={contentLanguagesError}
                />
            </div>
        </ModalWindow>
    )
}

export { LanguageModal }
