// Core
import { all } from 'redux-saga/effects'

// Watchers
import { watchAuth } from '@/core/store/auth/saga'
import { watchGeneral } from '@/core/store/general/saga'
import { watchSearch } from '@/core/store/search/saga'
import { watchFavourites } from '@/core/store/favourites/saga'
import { watchInterests } from '@/core/store/interests/saga'
import { watchAuthor } from '@/core/store/author/saga'
import { watchStories } from '@/core/store/stories/saga'
import { watchCollection } from '@/core/store/collection/saga'
import { watchMap } from '@/core/store/map/saga'
import { watchSubscriptions } from '@/core/store/subscriptions/saga'
import { watchLanguage } from '@/core/store/language/saga'
import { watchMagicLinks } from '@/core/store/magicLinks/saga'

export function* rootSaga(): Generator {
    yield all([
        watchAuth(),
        watchGeneral(),
        watchSearch(),
        watchFavourites(),
        watchInterests(),
        watchAuthor(),
        watchStories(),
        watchCollection(),
        watchMap(),
        watchSubscriptions(),
        watchLanguage(),
        watchMagicLinks(),
    ])
}
