import { put, takeLatest } from 'redux-saga/effects'
import { actionsGeneral } from '@/core/store/general/actions'
import { LocalStorage } from '@/core/constants/localStorage'

function* initFlags(): Generator {
    const appInstallFlag = JSON.parse(
        localStorage.getItem(LocalStorage.AppInstallRequested) || 'false',
    )
    const continoueAnonymouslyFlag = JSON.parse(
        localStorage.getItem(LocalStorage.ContinueAnonymouslyConfirmed) || 'false',
    )

    yield put(actionsGeneral.setAppInstallRequestedFlag(appInstallFlag))
    yield put(actionsGeneral.setContinueAnonymouslyFlag(continoueAnonymouslyFlag))
}

function* offMapView(): Generator {
    yield put(actionsGeneral.setMapView(false))
}

export function* watchGeneral(): Generator {
    yield takeLatest('@/APP_INIT', initFlags)
    yield takeLatest('@/LOCATION_CHANGE', offMapView)
}
