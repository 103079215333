import { PointerType } from '@/core/types/map'
import { BaseAPI } from '../index'

export class MapAPI<T> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchAllPoints(): Promise<Array<PointerType>> {
        try {
            return await this.requestService.get(`/rest/pointers/all`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchPointersByIds(): Promise<Array<PointerType>> {
        try {
            const res = await this.requestService.post(`/rest/pointers`, this.data)

            return res
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
