import colors from '@/core/theme/colors'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        pageLayout: {
            display: 'flex',
            justifyContent: 'center',
        },
        pageBody: {
            maxWidth: '670px',
            width: '100%',
            padding: '24px 16px',
        },
        greyBody: {
            background: colors.lightGrey2,
        },
    }),
)
