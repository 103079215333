import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(({ spacing }: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: spacing(8),
        },
        authorBlock: {
            display: 'flex',
            alignItems: 'center',
        },
        photoUser: {
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            marginRight: '16px',
        },
        authorName: {
            display: 'inline-block',
            width: '70%',
        },
        link: {
            textDecoration: 'none',
        },
    }),
)
