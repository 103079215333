import colors from '@/core/theme/colors'
import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
        },
        languagesList: {
            display: 'flex',
            gap: '16px',
            marginTop: '16px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
            lineHeight: '28px',
            margin: '0',
            color: colors.black,
        },
        subTitle: {
            fontSize: '18px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: '150%',
            color: colors.black,
            margin: '0',
        },
        errorText: {
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: '150%',
            color: colors.errorText,
            margin: '0',
        },
    }),
)
