import { makeStyles, createStyles } from '@material-ui/core'
import colors from '@/core/theme/colors'

export const useStyles = makeStyles(() =>
    createStyles({
        logoContainer: {
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            color: colors.green,
            fontSize: '20px',
            fontWeight: 600,
            textDecoration: 'none',
        },
    }),
)
