import React from 'react'
import { useStyles } from './styles'

const Spinner = () => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <div className={styles.skeletonHead} />
            <div className={styles.skeletonAddress} />
            <div className={styles.skeletonImage} />
            <div className={styles.skeletonContent} />
            <div className={styles.skeletonContent} />
            <div className={styles.skeletonContent} />
        </div>
    )
}

export default Spinner
