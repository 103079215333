import React from 'react'
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { useDispatch } from 'react-redux'
import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import TabWrapper from '@/components/FavouritesModal/TabWrapper/TabWrapper'
import { CloseButton } from '@/components/controls/CloseButton'
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage } from 'react-intl'

const FavouritesModal = () => {
    const styles = useStyles()
    const { hideModal } = useGlobalModalContext()
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const handleModalToggle = () => {
        hideModal()
    }

    return (
        <Dialog
            onClose={handleModalToggle}
            open={true}
            disablePortal
            disableBackdropClick
            disableEnforceFocus
            classes={{ paper: isMobile ? styles.paperMobile : styles.paper, root: styles.root }}
            BackdropProps={{
                classes: { root: styles.root },
            }}
            style={{ position: isMobile ? 'fixed' : 'absolute' }}
            maxWidth="sm"
            scroll="paper"
            aria-labelledby="customized-dialog-title"
            aria-describedby="customized-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4">
                        <FormattedMessage id="FavouritesText" />
                    </Typography>
                    <CloseButton onClick={handleModalToggle} />
                </div>
            </DialogTitle>
            <DialogContent className={isMobile ? styles.rootContentMobile : styles.rootContent}>
                <TabWrapper />
            </DialogContent>
        </Dialog>
    )
}

export default FavouritesModal
