import React from 'react'
import { useStyles } from './styles'
import { Props } from './types'
import clsx from 'clsx'

const Loader = ({ className }: Props): React.ReactElement => {
    const classes = useStyles()
    return <div className={clsx(classes.loader, className)} />
}

export { Loader }
