import React from 'react'

import { Link } from 'react-router-dom'

import { generateSlugName } from '@/core/helpers/generateSlugName'

import { useStyles } from './styles'

const TagsWrapper = ({ tags, onClick }: any) => {
    const styles = useStyles()
    return (
        <div className={styles.root} onClick={onClick}>
            {tags.map((tag: any) => (
                <Link
                    key={tag.id}
                    to={`/interest/${generateSlugName(tag.name)}`}
                    className={styles.link}
                >
                    #{tag.name}
                </Link>
            ))}
        </div>
    )
}

export default TagsWrapper
