import { takeLatest, put, call } from 'redux-saga/effects'
import { requestAnyAction, requestsScrollingData } from '@/core/types/types'
import { actionsSearch } from '@/core/store/search/actions'
import { actionsMap } from '@/core/store/map/actions'
import { SearchAPI } from '@/core/api/searchAPI/searchAPI'
import { groupSearchResultData } from '@/core/helpers/groupSearchResultData'

export function* getSearchDataApp({
    payload: { searchFragment, languages },
}: requestAnyAction<requestsScrollingData>): Generator {
    const body = {
        searchRequest: searchFragment,
        languages: languages,
        startNum: 0,
        maxAmount: 20,
        timestamp: Date.now(),
    }
    const fetcher = new SearchAPI(body)
    const fetch = fetcher.fetchSearchDataInReveal.bind(fetcher)

    try {
        yield put(actionsSearch.startSearchDataApp())
        const searchResults: any = yield call(fetch)
        yield put(actionsSearch.fillSearchDataAppSuccess(searchFragment || '', searchResults))

        const groupedSearchResult = groupSearchResultData(searchResults)
        const requestData = {
            collections: groupedSearchResult.collections.map(e => e.itemId),
            stories: groupedSearchResult.stories.map(e => e.itemId),
        }
        yield put(actionsMap.getMarkersByIds({ target: 'searchResultMarkers', requestData }))
    } catch (e) {
        if (e instanceof Error) {
            yield put(actionsSearch.fillSearchDataAppError(e.message))
        }
    } finally {
        yield put(actionsSearch.stopSearchDataApp())
    }
}

export function* watchSearch(): Generator {
    yield takeLatest('@/GET_SEARCH_DATA_APP_ASYNC', getSearchDataApp)
}
