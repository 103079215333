import React, { FC, ReactElement } from 'react'

import PageLayout from '@/components/PageLayout'
import Logo from '@/components/Logo'
import SearchForm from '@/components/SearchForm'
import ViewSwitcher from '@/components/ViewSwitcher'
import Menu from '@/components/Menu'

import { useStyles } from './styles'

const Header: FC = (): ReactElement => {
    const styles = useStyles()

    return (
        <PageLayout>
            <div className={styles.headerContainer}>
                <Logo />
                <SearchForm />
                <ViewSwitcher />
                <Menu />
            </div>
        </PageLayout>
    )
}

export default Header
