import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            width: '100%',
            marginBottom: '24px',
            padding: '16px',
            borderRadius: '10px',
            boxShadow: '0px 4px 13px rgb(0 0 0 / 8%)',
        },
        storyName: {
            margin: '0',
        },

        map: {
            height: '260px',
            width: '100%',
            minHeight: '260px',
            marginTop: '16px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '16px',
        },
    }),
)
