import { takeLatest } from 'redux-saga/effects'
import { AuthAPI } from '@/core/api/authAPI/authAPI'
import { requestAnyAction, StringMessageType } from '@/core/types/types'
import { AxiosResponse } from 'axios'
import { actionsMagicLinks } from '@/core/store/magicLinks/actions'
import {
    ServerResponseType,
    UserActivationType,
    RegistrationResponseType,
    ResetPasswordResponseType,
    ResetPasswordType,
    SendMagicLinkType,
    MagicLinkVerificationResponseType,
} from '@/core/types/auth/authTypes'
import { makeRequestWithSpinner, OptionsType } from '../sagaWorkers/saga'

function* fetchMagicLinkLoginConfirm({ payload }: requestAnyAction<string>): Generator {
    const data: UserActivationType = {
        token: payload,
    }
    const fetcher = new AuthAPI(data)
    const options: OptionsType<AxiosResponse<MagicLinkVerificationResponseType>> = {
        fetcher: fetcher.magicLinkLoginConfirm.bind(fetcher),
        startFetching: actionsMagicLinks.startVerifyLoginLink,
        stopFetching: actionsMagicLinks.stopVerifyLoginLink,
        fill: actionsMagicLinks.verifyLoginLinkSuccess,
        setErrorAction: actionsMagicLinks.verifyLoginLinkFail,
    }
    yield makeRequestWithSpinner<AxiosResponse<MagicLinkVerificationResponseType>>(options)
}

function* fetchMagicLinkRegistrationConfirm({ payload }: requestAnyAction<string>): Generator {
    const data: UserActivationType = {
        token: payload,
    }
    const fetcher = new AuthAPI(data)
    const options: OptionsType<AxiosResponse<MagicLinkVerificationResponseType>> = {
        fetcher: fetcher.magicLinkRegistrationConfirm.bind(fetcher),
        startFetching: actionsMagicLinks.startVerifyRegistrationLink,
        stopFetching: actionsMagicLinks.stopVerifyRegistrationLink,
        fill: actionsMagicLinks.verifyRegistrationLinkSuccess,
        setErrorAction: actionsMagicLinks.verifyRegistrationLinkFail,
    }
    yield makeRequestWithSpinner<AxiosResponse<MagicLinkVerificationResponseType>>(options)
}

function* fetchRegistrationConfirm({ payload }: requestAnyAction<string>): Generator {
    const data: UserActivationType = {
        token: payload,
    }
    const fetcher = new AuthAPI(data)
    const options: OptionsType<AxiosResponse<RegistrationResponseType>> = {
        fetcher: fetcher.credentialsActivation.bind(fetcher),
        startFetching: actionsMagicLinks.startVerifyRegistrationPasswordLink,
        stopFetching: actionsMagicLinks.stopVerifyRegistrationPasswordLink,
        fill: actionsMagicLinks.verifyRegistrationPasswordLinkSuccess,
        setErrorAction: actionsMagicLinks.verifyRegistrationPasswordLinkFail,
    }
    yield makeRequestWithSpinner<AxiosResponse<RegistrationResponseType>>(options)
}

function* fetchResetPassword({ payload }: requestAnyAction<ResetPasswordType>): Generator {
    const fetcher = new AuthAPI(payload)
    const options: OptionsType<AxiosResponse<ResetPasswordResponseType>> = {
        fetcher: fetcher.resetPassword.bind(fetcher),
        startFetching: actionsMagicLinks.startResetPassword,
        stopFetching: actionsMagicLinks.stopResetPassword,
        fill: actionsMagicLinks.resetPasswordSuccess,
        setErrorAction: actionsMagicLinks.resetPasswordFail,
    }
    yield makeRequestWithSpinner<AxiosResponse<ResetPasswordResponseType>>(options)
}

function* fetchSendAuthLink({ payload }: requestAnyAction<SendMagicLinkType>): Generator {
    const data: StringMessageType = {
        value: payload.email,
        redirectUrl: payload.currentUrl,
    }
    const fetcher = new AuthAPI(data)
    const options: OptionsType<AxiosResponse<ServerResponseType>> = {
        fetcher: fetcher.sendAuthMagicLink.bind(fetcher),
        startFetching: actionsMagicLinks.startSendAuthLink,
        stopFetching: actionsMagicLinks.stopSendAuthLink,
        fill: actionsMagicLinks.sendAuthLinkSuccess,
        setErrorAction: actionsMagicLinks.sendAuthLinkFail,
    }
    yield makeRequestWithSpinner<AxiosResponse<ServerResponseType>>(options)
}

export function* watchMagicLinks(): Generator {
    yield takeLatest('@/VERIFY_LOGIN_LINK', fetchMagicLinkLoginConfirm)
    yield takeLatest('@/VERIFY_REGISTRATION_LINK', fetchMagicLinkRegistrationConfirm)
    yield takeLatest('@/VERIFY_REGISTRATION_PASSWORD_LINK', fetchRegistrationConfirm)
    yield takeLatest('@/RESET_PASSWORD', fetchResetPassword)
    yield takeLatest('@/SEND_AUTH_LINK', fetchSendAuthLink)
}
