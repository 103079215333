import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '368px',
            backgroundColor: theme.palette.secondary.main,
        },
        paperMobile: {
            margin: 0,
            maxHeight: '100%',
            backgroundColor: theme.palette.secondary.main,
            height: '100%',
            width: '100%',
        },
        backDrop: {
            position: 'absolute',
        },
        root: {
            position: 'absolute',
        },
        subscriptionsHeader: {
            display: 'inline-block',
            width: '100%',
            paddingBottom: '8px',
            borderBottom: '1px gray solid',
        },
        closeButton: {
            position: 'absolute',
            top: '18px',
            right: '18px',
            cursor: 'pointer',
            color: '#fff',
        },
        contentContainer: {
            padding: '16px 24px',
        },
    }),
)
