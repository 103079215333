import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { ModalTypes, AddToHomeModalPropsType } from '@/core/constants/modal'

import { actionsGeneral, getModalType, appInstallRequestedFlag } from '@/core/store/general'

import { IBeforeInstallPromptEvent } from '@/core/types/types'
const AddToHomeScreenDriver = (): null => {
    const dispatch = useDispatch()

    const [installPrompt, setInstallPrompt] = useState<IBeforeInstallPromptEvent | null>(null)
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const currModalType = useSelector(getModalType)
    const isAppInstallRequestd = useSelector(appInstallRequestedFlag)

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e: any) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault()
            // Stash the event so it can be triggered later.
            setInstallPrompt(e)
        })
    }, [])

    useEffect(() => {
        let timer
        if (currModalType || !installPrompt || isAppInstallRequestd || !isMobile) {
            clearTimeout(timer)
        } else {
            timer = setTimeout(() => {
                const installAppModalOptions: AddToHomeModalPropsType = {
                    onInstallClick: async () => {
                        installPrompt?.prompt()
                        setInstallPrompt(null)
                    },
                    afterCloseModal: () => {
                        setInstallPrompt(null)
                    },
                }

                dispatch(
                    actionsGeneral.showModal(ModalTypes.ADD_TO_HOME_MODAL, installAppModalOptions),
                )
                dispatch(actionsGeneral.setAppInstallRequestedFlag(true))
            }, 30000)
        }
    }, [currModalType, installPrompt, isAppInstallRequestd, isMobile])

    return null
}

export default AddToHomeScreenDriver
