import {
    DefaultUserTypes,
    ProfileTypes,
    sigInInRequestTypes,
    ServerResponseType,
} from '@/core/types/auth/authTypes'
import { AxiosResponse } from 'axios'

export const actionsAuth = {
    checkToken: () =>
        ({
            type: '@/CHECK_TOKEN',
        } as const),
    setTokenExists: (payload: boolean) =>
        ({
            type: '@/SET_TOKEN_EXISTS',
            payload,
        } as const),
    setTokenChecked: (payload: boolean) =>
        ({
            type: '@/SET_TOKEN_CHECKED',
            payload,
        } as const),
    logOutUserResponseSuccess: (payload: unknown) =>
        ({
            type: '@/LOG_OUT_USER_RESPONSE_SUCCESS',
            payload,
        } as const),
    logOutUserAsync: () =>
        ({
            type: '@/LOG_OUT_USER_ASYNC',
        } as const),
    startLoading: () =>
        ({
            type: '@/START_LOADING',
        } as const),
    stopLoading: () =>
        ({
            type: '@/STOP_LOADING',
        } as const),
    fillDefaultUserResponseSuccess: (payload: AxiosResponse<DefaultUserTypes>) =>
        ({
            type: '@/FILL_DEFAULT_USER_RESPONSE_SUCCESS',
            payload,
        } as const),
    fillDefaultUserResponseFall: (payload: string) =>
        ({
            type: '@/FILL_DEFAULT_USER_RESPONSE_FALL',
            payload,
        } as const),
    signUPDefaultUserAsync: () =>
        ({
            type: '@/GET_SIGN_UP_DEFAULT_USER_ASYNC',
        } as const),

    fillSignInCredentialsSuccess: (payload: AxiosResponse<ServerResponseType>) =>
        ({
            type: '@/FILL_SIGN_IN_SUCCESS',
            payload: payload,
        } as const),

    fillGetProfileResponseSuccess: (payload: ProfileTypes) =>
        ({
            type: '@/FILL_PROFILE_RESPONSE_SUCCESS',
            payload: payload,
        } as const),
    signInCredentialsAsync: (payload: sigInInRequestTypes) =>
        ({
            type: '@/GET_SIGN_IN_USER_ASYNC',
            payload,
        } as const),
    getUserProfile: () =>
        ({
            type: '@/GET_PROFILE',
        } as const),
}
