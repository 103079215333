import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getCollectionState = (state: AppState): typeof initialState => state.collectionReducer

export const getCollection = createSelector(getCollectionState, state => state.collection)
export const getCollectionFetchError = createSelector(
    getCollectionState,
    state => state.collectionFetchError,
)

export const getCollectionData = createSelector(getCollectionState, state => state.collection)
export const getNewsCollections = createSelector(getCollectionState, state => state.newsCollections)
export const getNearesCollections = createSelector(
    getCollectionState,
    state => state.nearestCollections,
)
export const getAuthorCollections = createSelector(
    getCollectionState,
    state => state.authorCollections,
)
export const getRelatedCollections = createSelector(
    getCollectionState,
    state => state.relatedCollections,
)
