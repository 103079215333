import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
    getDataLocationAndPayload,
    makeRequestWithSpinner,
    OptionsType,
    CollectionFillType,
} from '../sagaWorkers/saga'
import { requestAnyAction, RequestDataByAuthor, requestsScrollingData } from '@/core/types/types'
import { CollectionAPI } from '@/core/api/collectionAPI/collectionAPI'
import { actionsCollection, actionsLoadingCollections } from '@/core/store/collection/actions'
import { CollectionsSiteBarAPI } from '@/core/api/collectionAPI/newsCollectionAPI'
import {
    newCollectionsPreviewTypes,
    RelatedCollectionsActionType,
} from '@/core/types/CollectionsTypes'
import { LikeCollection, BuyCollection } from '@/core/types/collection/collection'
import { actionsFavourites } from '@/core/store/favourites'
import { actionsGeneral } from '@/core/store/general'
import { getSearchDataApp } from '@/core/store/search/saga'
import { AppState } from '@/core/store/rootReducer'
import { ModalTypes } from '@/core/constants/modal'

function* getCollection({ payload }: requestAnyAction<number>): Generator {
    try {
        const fetcher = new CollectionAPI(payload)
        const request = fetcher.fetchCollectionByIdOrSlug.bind(fetcher)
        const response: any = yield call(request)
        yield put(actionsCollection.setCollection(response))
    } catch (e: any) {
        console.error('Catched Error in getCollection', e)
        yield put(actionsCollection.errorFetchingSingleCollection(e.message))
    }
}

function* getCollectionByAuthor({ payload }: requestAnyAction<RequestDataByAuthor>): Generator {
    const fetcher = new CollectionAPI(payload)

    const options: OptionsType<Array<newCollectionsPreviewTypes>> = {
        fetcher: fetcher.fetchCollectionByAuthor.bind(fetcher),
        startFetching: actionsLoadingCollections.startLoading('authorCollections'),
        stopFetching: actionsLoadingCollections.stopLoading('authorCollections'),
        fill: actionsLoadingCollections.responseSuccess('authorCollections'),
        setErrorAction: actionsLoadingCollections.errorLoading('authorCollections'),
    }

    yield makeRequestWithSpinner<Array<newCollectionsPreviewTypes>>(options)
}

function* getCollections({ payload }: requestAnyAction<requestsScrollingData>): Generator {
    const { lat, lng, timestamp }: any = yield select(
        (state: AppState) => state.mapReducer.userCoordinates,
    )
    const body = {
        latitude: lat,
        longitude: lng,
        timestamp,
        startNum: payload.page,
        maxAmount: payload.limit,
        languages: payload.languages,
    }
    const fetcher = new CollectionsSiteBarAPI(body)
    const options: OptionsType<Array<newCollectionsPreviewTypes>> = {
        fetcher: fetcher.fetchCollectionData.bind(fetcher),
        startFetching: actionsLoadingCollections.startLoading('newsCollections'),
        stopFetching: actionsLoadingCollections.stopLoading('newsCollections'),
        fill: actionsLoadingCollections.responseSuccess('newsCollections'),
        setErrorAction: actionsLoadingCollections.errorLoading('newsCollections'),
    }

    yield makeRequestWithSpinner<Array<newCollectionsPreviewTypes>>(options)
}

function* likeCollection({ payload }: requestAnyAction<LikeCollection>): Generator {
    try {
        const userProfile: any = yield select((state: AppState) => state.authReducer.profile)
        const continueAnonymouslyConfirmed: any = yield select(
            (state: AppState) => state.generalReducer.continueAnonymouslyConfirmed,
        )

        if (!continueAnonymouslyConfirmed && !userProfile) {
            yield put(
                actionsGeneral.showModal(ModalTypes.MY_ACCOUNT_MODAL, {
                    showAnonymousOption: true,
                }),
            )
        }

        const fetcher = new CollectionAPI<number>(payload.id)
        const request = payload.like
            ? fetcher.likeCollection.bind(fetcher)
            : fetcher.unlikeCollection.bind(fetcher)
        yield call(request)
        yield put(actionsCollection.setLikedCollection(payload.id, payload.like))
        yield put(actionsFavourites.setFavouritesLikedCollection(payload.id, payload.like))
    } catch (e) {
        console.error('Catched Error in likeCollection', e)
    }
}

function* getNearestCollectionsData(
    { payload }: requestAnyAction<requestsScrollingData>,
    fill: CollectionFillType<Array<newCollectionsPreviewTypes>>,
): Generator {
    const body: any = yield call(getDataLocationAndPayload, payload)

    if (!payload.searchFragment || payload.searchFragment?.includes('interest:"')) {
        const fetcher = new CollectionAPI(body)
        const options: OptionsType<Array<newCollectionsPreviewTypes>> = {
            fetcher: fetcher.fetchNearestCollections.bind(fetcher),
            startFetching: actionsLoadingCollections.startLoading('nearestCollections'),
            stopFetching: actionsLoadingCollections.stopLoading('nearestCollections'),
            fill,
            setErrorAction: actionsLoadingCollections.errorLoading('nearestCollections'),
        }
        yield makeRequestWithSpinner<Array<newCollectionsPreviewTypes>>(options)
    } else {
        yield call(getSearchDataApp, {
            type: '',
            payload,
        })
    }
}

function* getNearestCollections(payload: requestAnyAction<requestsScrollingData>): Generator {
    yield call(
        getNearestCollectionsData,
        payload,
        actionsLoadingCollections.responseSuccess('nearestCollections'),
    )
}

function* getNearestCollectionsWithChangeSearchFragment(
    payload: requestAnyAction<requestsScrollingData>,
): Generator {
    yield call(
        getNearestCollectionsData,
        payload,
        actionsLoadingCollections.responseSuccess('nearestCollections'),
    )
}

function* getRelatedCollectionsData({
    payload,
}: requestAnyAction<RelatedCollectionsActionType>): Generator {
    const body: any = yield call(getDataLocationAndPayload, {
        ...payload,
        searchFragment: `interest:"${payload.interests?.[0]}"`,
    })

    const fetcher = new CollectionAPI(body)
    const options: OptionsType<Array<newCollectionsPreviewTypes>> = {
        fetcher: fetcher.fetchNearestCollections.bind(fetcher),
        startFetching: actionsLoadingCollections.startLoading('relatedCollections'),
        stopFetching: actionsLoadingCollections.stopLoading('relatedCollections'),
        fill: actionsLoadingCollections.responseSuccess('relatedCollections'),
        setErrorAction: actionsLoadingCollections.errorLoading('relatedCollections'),
    }

    yield makeRequestWithSpinner<Array<newCollectionsPreviewTypes>>(options)
}

function* buyCollection({ payload }: requestAnyAction<BuyCollection>): Generator {
    const fetcher = new CollectionAPI<number>(payload.id)
    const request = fetcher.buyCollection.bind(fetcher)
    yield call(request)
    yield put(actionsCollection.setBoughtCollection(payload.id))
}

export function* watchCollection(): Generator {
    yield takeLatest('@/GET_COLLECTION', getCollection)
    yield takeLatest('@/GET_COLLECTION_BY_AUTHOR', getCollectionByAuthor)
    yield takeLatest('@/GET_COLLECTIONS_DATA_ASYNC', getCollections)
    yield takeLatest('@/LIKE_COLLECTION', likeCollection)
    yield takeLatest('@/BUY_COLLECTION', buyCollection)
    yield takeLatest('@/GET_NEAREST_COLLECTIONS', getNearestCollections)
    yield takeLatest(
        '@/GET_NEAREST_COLLECTIONS_WITH_CHANGE_SEARCH_FRAGMENT',
        getNearestCollectionsWithChangeSearchFragment,
    )
    yield takeLatest('@/GET_RELATED_COLLECTIONS', getRelatedCollectionsData)
}
