import { AppState } from '../rootReducer'
import { createSelector } from 'reselect'
import { initialState } from './reducer'

const getMagicLinksState = (state: AppState): typeof initialState => state.magicLinksReducer

export const getLoading = createSelector(getMagicLinksState, state => state.loading)
export const getSuccess = createSelector(getMagicLinksState, state => state.success)
export const getErrorOccured = createSelector(getMagicLinksState, state => state.errorOccured)
export const getErrorText = createSelector(getMagicLinksState, state => state.errorText)
export const getRedirectUrl = createSelector(getMagicLinksState, state => state.redirectUrl)

export const getRegistrationErrorType = createSelector(
    getMagicLinksState,
    state => state.registrationErrorType,
)
export const getRegistrationErrorText = createSelector(
    getMagicLinksState,
    state => state.registrationErrorText,
)
export const getResetPasswordErrorType = createSelector(
    getMagicLinksState,
    state => state.resetPasswordErrorType,
)
