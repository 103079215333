import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslate } from '@/core/hooks/useTranslate'
import clsx from 'clsx'

import { Select, MenuItem } from '@material-ui/core'

import { useStyles } from './styles'

type FilledTabsProps = {
    defaultTab?: number
    defaultType?: number
    onTabChange: (tabId: number) => void
    onTypeChange?: (typeId: number) => void
    showTypes?: boolean
    showRecentType?: boolean
}

const FilledTabs = ({
    defaultTab = 0,
    defaultType = 0,
    onTabChange,
    onTypeChange,
    showTypes = false,
    showRecentType = false,
}: FilledTabsProps): ReactElement => {
    const styles = useStyles()
    const translate = useTranslate()

    const [selectedTab, setSelectedTab] = useState(defaultTab)
    const [selectedType, setSelectedType] = useState(defaultType)

    const tabs = [
        { id: 0, title: translate('#CollectionsText') },
        { id: 1, title: translate('#StoriesText') },
    ]

    const onTabClick = useCallback(
        tabId => {
            if (selectedTab === tabId) return
            if (tabId === 0 && !showRecentType) setSelectedType(1)
            setSelectedTab(tabId)
            onTabChange(tabId)
        },
        [selectedTab, onTabChange],
    )

    const changeType = useCallback(
        e => {
            const typeId = e.target.value
            setSelectedType(typeId)
            onTypeChange?.(typeId)
        },
        [selectedType, onTypeChange],
    )

    const slectedIndex = tabs.findIndex(tab => tab.id === selectedTab)
    const bgWidth = 100 / tabs.length
    const leftShift = bgWidth * slectedIndex

    const showRecetnOption = (selectedTab === 0 && showRecentType) || selectedTab === 1

    return (
        <>
            <div role="tabpanel" className={styles.tabsContainer}>
                {tabs.map(tab => (
                    <div
                        key={tab.id}
                        className={clsx(styles.tab, {
                            [styles.selectedTab]: selectedTab === tab.id,
                        })}
                        onClick={() => onTabClick(tab.id)}
                    >
                        {tab.title}
                    </div>
                ))}
                <div
                    className={styles.selectedTabBg}
                    style={{
                        width: `calc(${bgWidth}% - 4px)`,
                        left: `${leftShift ? `${leftShift}%` : '4px'}`,
                    }}
                />
            </div>
            {showTypes && (
                <div className={styles.typesContainer}>
                    <h3>{translate('#ShowText')}</h3>
                    <Select
                        id="demo-simple-select-standard"
                        value={selectedType}
                        onChange={changeType}
                    >
                        {showRecetnOption && (
                            <MenuItem value={0}>{translate('#RecentText')}</MenuItem>
                        )}
                        <MenuItem value={1}>{translate('#NearestText')}</MenuItem>
                    </Select>
                </div>
            )}
        </>
    )
}

export default FilledTabs
