import React, { ReactElement } from 'react'
import { Dialog, DialogContent, DialogTitle, FormGroup, Typography } from '@material-ui/core'

import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import { useStyles } from './styles'
import { CloseButton } from '@/components/controls/CloseButton'
import { TextInput } from '@/components/controls/TextInput'
import { Button } from '@/components/controls/Button'
import { useMyAccount } from '@/components/MyAccountModal/MyAccountHook'
import { useInput } from '@/components/controls/TextInput/useInputHook'
import NoPhoto from '@/assets/no_photo.png'
import { useMediaQuery } from 'react-responsive'
import { useTranslate } from '@/core/hooks/useTranslate'

const MyAccountModal = (): ReactElement => {
    const { hideModal } = useGlobalModalContext()
    const { handleModalToggle, profile, handleFile, handleFileInputChange, errorsLocalState } =
        useMyAccount(hideModal)
    const styles = useStyles()
    const translate = useTranslate()
    const name = useInput(profile?.name || '')
    const userName = useInput(profile?.username || '')
    const webSite = useInput(profile?.website || '')
    const bio = useInput(profile?.bio || '')
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 })

    return (
        <Dialog
            onClose={handleModalToggle}
            open={true}
            disableEnforceFocus
            disableBackdropClick
            disablePortal
            classes={{ paper: isMobile ? styles.paperMobile : styles.paper, root: styles.root }}
            BackdropProps={{
                classes: { root: styles.root },
            }}
            style={{ position: isMobile ? 'fixed' : 'absolute' }}
            maxWidth="sm"
            aria-labelledby="customized-dialog-title"
            aria-describedby="customized-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant={'h5'} align={'center'} style={{ color: 'white' }}>
                        {translate('#MyAccountText')}
                    </Typography>
                    <CloseButton onClick={handleModalToggle} />
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <div className={styles.formGroupWrapper}>
                        <div className={styles.photoWrapper}>
                            <img
                                src={(handleFile as string) || profile?.avatar || NoPhoto}
                                alt="photo"
                                className={styles.photoUser}
                            />
                            <Typography variant={'body2'}>
                                {translate('#ChangeProfilePhotoText')}
                                <input
                                    title=""
                                    className={styles.addPhoto}
                                    accept=".png,.jpg,.jpeg"
                                    type="file"
                                    onChange={handleFileInputChange}
                                />
                            </Typography>

                            {errorsLocalState.errorsPhoto && (
                                <div className={styles.errorText}>
                                    {errorsLocalState.errorsPhoto}
                                </div>
                            )}
                        </div>
                        <TextInput
                            placeholder={translate('#NameText')}
                            value={name.inputValue}
                            handleInputChange={name.handleInputChange}
                        />
                        <TextInput
                            placeholder={translate('#UsernameBigFirstText')}
                            value={userName.inputValue}
                            handleInputChange={userName.handleInputChange}
                            error={
                                !userName.inputValue
                                    ? 'Username cannot be empty, please fill'
                                    : undefined
                            }
                        />
                        <TextInput
                            placeholder={translate('#WebsiteText')}
                            value={webSite.inputValue}
                            handleInputChange={webSite.handleInputChange}
                        />
                        <TextInput
                            placeholder={translate('#BioText')}
                            value={bio.inputValue}
                            handleInputChange={bio.handleInputChange}
                        />

                        <Button
                            title={translate('#SaveButtonText')}
                            className={styles.buttonStyle}
                            disabled={!userName.inputValue}
                        />
                    </div>
                </FormGroup>
            </DialogContent>
        </Dialog>
    )
}

export { MyAccountModal }
