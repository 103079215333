import { TranslateResponseTypes } from '@/core/types/languages'

export const actionsLanguage = {
    initLanguage: () =>
        ({
            type: '@/INIT_INTERFACE_LANGUAGE',
        } as const),
    getInterfaceLanguageData: (payload: string) =>
        ({
            type: '@/GET_INTERFACE_LANGUAGE_DATA',
            payload,
        } as const),
    setInterfaceLanguageData: (payload: TranslateResponseTypes) =>
        ({
            type: '@/SET_INTERFACE_LANGUAGE_DATA',
            payload,
        } as const),
    setInterfaceLanguageCode: (payload: string) =>
        ({
            type: '@/SET_INTERFACE_LANGUAGE_CODE',
            payload,
        } as const),
    setDataLanguagesCodes: (payload: Array<string>) =>
        ({
            type: '@/SET_DATA_LANGUAGES_CODES',
            payload,
        } as const),
}
