import { BaseAPI } from '../index'

export class AuthorAPI<T extends { [key: string]: unknown }> extends BaseAPI<T> {
    constructor(public readonly data: T) {
        super(data)
        this.data = data
    }

    public async fetchAuthorData() {
        try {
            return await this.requestService.get(`/rest/author/${this.data}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async subscribeAuthor() {
        try {
            return await this.requestService.get(`/subscribe/author/${this.data.id}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async unsubscribeAuthor() {
        try {
            return await this.requestService.get(`/unsubscribe/author/${this.data.id}`)
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }

    public async fetchStartPageAuthors() {
        console.log(this.data)
        try {
            return await this.requestService.post(`/rest/authors`, {
                languages: this.data.payload,
            })
        } catch (e) {
            throw Reflect.construct(Error, [e])
        }
    }
}
