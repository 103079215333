import React, { ReactElement } from 'react'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useGlobalModalContext } from '@/containers/GlobalModal/GlobalModal'
import { ReactComponent as DownloadIcon } from '@/assets/DownloadIcon.svg'
import { ReactComponent as AppStoreIcon } from '@/assets/AppStoreIcon.svg'
import { ReactComponent as PlayStoreIcon } from '@/assets/PlayStoreIcon.svg'
import { GetMobileAppModalProps } from '@/core/constants/modal'

import { useStyles } from './styles'
import { APP_STORE_LINK, PLAY_STORE_LINK } from '@/core/constants/linkMobileApp'

const GetMobileAppModal = (props: GetMobileAppModalProps): ReactElement => {
    const styles = useStyles()
    const { hideModal } = useGlobalModalContext()

    const handleModalToggle = () => {
        const { showButtonAfterCloseModal } = props

        hideModal()
        showButtonAfterCloseModal()
    }

    return (
        <Dialog onClose={handleModalToggle} open={true} classes={{ paper: styles.root }}>
            <CloseIcon className={styles.closeButton} onClick={handleModalToggle} />
            <DownloadIcon />
            <span>Download mobile app</span>
            <span>Full features are available in the mobile app</span>

            <a className={styles.link} href={APP_STORE_LINK} target="_blank">
                <span>Download on App Store</span>
                <AppStoreIcon />
            </a>
            <a className={styles.link} href={PLAY_STORE_LINK} target="_blank">
                <span>Download on Play Market</span>
                <PlayStoreIcon />
            </a>
        </Dialog>
    )
}

export { GetMobileAppModal }
