import { useDispatch, useSelector } from 'react-redux'
import { actionsAuth, getUserProfile } from '@/core/store/auth'
import { ChangeEvent, useCallback, useState } from 'react'

const MAX_SIZE = 2

const useMyAccount = (hideModal: () => void) => {
    const formatTypeFiles = ['image/png', 'image/jpeg']
    const [errorsLocalState, setErrorsLocalState] = useState({
        errorsPhoto: '',
    })

    const [handleFile, setFieldValuePhoto] = useState<string | ArrayBuffer | null | undefined>(
        undefined,
    )

    const dispatch = useDispatch()
    const profile = useSelector(getUserProfile)

    const handleModalToggle = () => {
        hideModal()
    }

    const validatePhoto = (data: FileList | null): void | boolean => {
        if (data?.length === 1) {
            const size = data[0].size / 1024 / 1024
            if (size > MAX_SIZE) {
                setErrorsLocalState({
                    errorsPhoto: `file is ${size} mb,max size ${MAX_SIZE} mb`,
                })
                return true
            }
            if (!formatTypeFiles.includes(data[0].type)) {
                setErrorsLocalState({
                    errorsPhoto: `format ${data[0].type} is not valid, only .png, .jpeg`,
                })
                return true
            } else {
                return false
            }
        }
    }

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        const { files } = event.target
        if (!validatePhoto(files)) {
            const reader = new FileReader()
            reader.onloadend = (): void => {
                setFieldValuePhoto(reader.result)
                setErrorsLocalState({ errorsPhoto: '' })
            }
            if (files && files.length) {
                reader.readAsDataURL(files[0])
            }
        }
    }

    const signInCredentials =
        (email: string, password: string, rememberMe = false) =>
        () => {
            dispatch(actionsAuth.signInCredentialsAsync({ email, password, rememberMe }))
            handleModalToggle()
        }

    return {
        signInCredentials,
        handleModalToggle,
        profile,
        handleFile,
        handleFileInputChange,
        errorsLocalState,
    }
}

export { useMyAccount }
