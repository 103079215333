import React, { FC, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '@/core/hooks/useTranslate'
import { useParams } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import { Typography } from '@material-ui/core'
import StoriesList from '@/components/StoriesList'
import { ActionButtons } from '@/components/ActionsButtons'
import { MemoizedMapContainer } from '@/containers/MapContainer/MapContainer'
import TagsWrapper from '@/components/TagsWrapper'
import { Loader } from '@/components/controls/Loader'
import InfinityScrollList from '@/components/InfinityScrollList'
import CollectionList from '@/components/CollectionList'
import SocialIcons from '@/components/SocialIcons'

import { actionsStories, getCollectionStories } from '@/core/store/stories'
import {
    actionsCollection,
    getCollection,
    getRelatedCollections,
    getCollectionFetchError,
} from '@/core/store/collection'
import { actionsMap, getPageMarkers } from '@/core/store/map'
import { getDataLanguagesCodes } from '@/core/store/language'

import { COLLECTION_TYPE_ICON_MAP, COLLLECTION_TYPE_TITLE_MAP } from '@/core/constants/collection'
import { CategoriesTypes, RelatedCollectionsActionType } from '@/core/types/CollectionsTypes'

import { useStyles } from './styles'

const CollectionPage: FC = () => {
    const styles = useStyles()
    const translate = useTranslate()
    const dispatch = useDispatch()
    const { slugName } = useParams<{ slugName: string }>()

    const storiesData = useSelector(getCollectionStories, shallowEqual)
    const pageMarkers = useSelector(getPageMarkers, shallowEqual)
    const collectionData = useSelector(getCollection, shallowEqual)
    const collectionFetchError = useSelector(getCollectionFetchError)
    const languages = useSelector(getDataLanguagesCodes)
    const relatedCollections = useSelector(getRelatedCollections)

    useEffect(() => {
        if (![collectionData?.slugName, collectionData?.id.toString()].includes(slugName)) {
            dispatch(actionsCollection.clearCollection())
            dispatch(actionsCollection.getCollection(slugName))
        }
    }, [collectionData, slugName])

    // Fetch collection stories in case loaded stories related to another collection
    useEffect(() => {
        if (collectionData?.id && storiesData.collectionId !== collectionData?.id) {
            dispatch(actionsStories.clearStories('collectionStories'))
            dispatch(actionsStories.getStoriesByCollection(collectionData.id))
        }
    }, [collectionData?.id, storiesData.collectionId])

    // Fetch Page Markers for current collection
    useEffect(() => {
        if (collectionData?.id) {
            dispatch(
                actionsMap.getMarkersByIds({
                    target: 'pageMarkers',
                    requestData: { collections: [collectionData.id], stories: [] },
                }),
            )
        }
        return () => {
            dispatch(actionsMap.setMarkersByIds({ target: 'pageMarkers', data: [] }))
        }
    }, [collectionData?.id])

    useEffect(() => {
        if (collectionFetchError || storiesData.error || relatedCollections.error) {
            throw new Error('Error fetching data for Collection Page')
        }
    }, [collectionFetchError, storiesData.error, relatedCollections.error])

    const fetchRelatedCollections = (data: RelatedCollectionsActionType) =>
        actionsCollection.getRelatedCollections({
            languages,
            ...data,
            interests: collectionData?.categories.map(({ name }: CategoriesTypes) => name),
        })

    const descriptionExists = !!collectionData?.description?.length
    const pictureExists = !!collectionData?.picture?.length

    const CollectionTypeIcon =
        collectionData?.collectionType !== undefined
            ? COLLECTION_TYPE_ICON_MAP[collectionData?.collectionType]
            : null
    const collectionTypeTitle =
        collectionData?.collectionType !== undefined
            ? COLLLECTION_TYPE_TITLE_MAP.map(title => translate(title))[
                  collectionData?.collectionType
              ]
            : null

    return (
        <>
            <Helmet>
                <title>{collectionData?.name}</title>
                <meta name="title" content={collectionData?.name} />
                {descriptionExists && (
                    <meta name="description" content={collectionData.description} />
                )}

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={collectionData?.name} />
                {descriptionExists && (
                    <meta property="og:description" content={collectionData.description} />
                )}
                {pictureExists && <meta property="og:image" content={collectionData.picture[0]} />}
                <meta property="fb:app_id" content="418921822030203" />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={collectionData?.name} />
                {descriptionExists && (
                    <meta property="twitter:description" content={collectionData.description} />
                )}
                {pictureExists && (
                    <meta property="twitter:image" content={collectionData.picture[0]} />
                )}
            </Helmet>
            {collectionData ? (
                <>
                    <div className={styles.darkContainer}>
                        <div>
                            <h1 className={styles.collectionName}>{collectionData.name}</h1>
                            {!!CollectionTypeIcon && (
                                <div className={styles.collectionTypeContainer}>
                                    <CollectionTypeIcon className={styles.collectionIcon} />{' '}
                                    {collectionTypeTitle}
                                </div>
                            )}
                        </div>
                        <p className={styles.collectionName}>{collectionData.description}</p>
                        <TagsWrapper tags={collectionData.categories} />
                        <div className={styles.map}>
                            <MemoizedMapContainer markers={pageMarkers} defaultZoom={18} />
                        </div>
                        <ActionButtons
                            photoUrl={collectionData.author.avatar}
                            authorName={collectionData.author.name}
                            authorId={collectionData.author.id}
                            type="collection"
                            id={collectionData.id}
                            slugName={collectionData.slugName}
                            liked={collectionData.liked}
                            subscribed={collectionData.author.subscribed}
                            lightMode
                        />
                    </div>
                    {!storiesData.loading && (
                        <Typography className={styles.storiesCountTitle} color="secondary">
                            {`${storiesData.data.length} ${translate('#StoriesLabel')}`}
                        </Typography>
                    )}
                    <StoriesList
                        isLoading={storiesData.loading}
                        stories={storiesData.data}
                        showPaidCollectionCard={!!collectionData?.price}
                    />
                    <SocialIcons />
                    <InfinityScrollList
                        title={translate('#RelatedCollectionsTitle')}
                        data={relatedCollections}
                        languages={languages}
                        RenderComponent={CollectionList}
                        renderComponentProps={{ showDonateCard: true }}
                        componentDataProp="collections"
                        fetchData={fetchRelatedCollections}
                        clearData={actionsCollection.clearCollectionsData('relatedCollections')}
                    />
                </>
            ) : (
                <Loader />
            )}
        </>
    )
}

export default CollectionPage
