import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { requestsScrollingData } from '@/core/types/types'
import { useDispatch } from 'react-redux'

type useScrollTypes = {
    parentRef: MutableRefObject<HTMLDivElement> | null
    childRef: MutableRefObject<HTMLDivElement>
    loading: boolean
    callback: (payload: requestsScrollingData) => void
    searchFragment?: string
}

type useScrollReturnType = {
    flushPageNumber: () => void
}

const useScroll = (
    { loading, callback, searchFragment, childRef, parentRef }: useScrollTypes,
    limitSize = 10,
): useScrollReturnType => {
    const dispatch = useDispatch()
    const [pageNumber, setPageNumber] = useState(0)

    const fetchData = () => {
        if (loading) return

        dispatch(
            // eslint-disable-next-line node/no-callback-literal
            callback({
                page: pageNumber,
                limit: limitSize,
                searchFragment,
            }),
        )
        setPageNumber(page => page + limitSize)
    }

    const observer = useRef<IntersectionObserver>()

    useEffect(() => {
        const options = {
            root: parentRef?.current,
            rootMargin: '0px',
            threshold: 0,
        }
        observer.current = new IntersectionObserver(([target]) => {
            if (target.isIntersecting) {
                fetchData()
            }
        }, options)

        observer.current.observe(childRef.current as Element)

        return () => {
            if (observer?.current) {
                observer.current?.disconnect()
            }
        }
    }, [searchFragment, pageNumber, limitSize, loading, parentRef])

    const flushPageNumber = useCallback(() => {
        setPageNumber(0)
    }, [])

    return { flushPageNumber }
}

export { useScroll }
