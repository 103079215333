import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '368px',
            backgroundColor: theme.palette.secondary.main,
        },
        paperMobile: {
            backgroundColor: theme.palette.secondary.main,
        },
        root: {
            position: 'absolute',
        },
        closeButton: {
            position: 'absolute',
            top: '18px',
            right: '18px',
            cursor: 'pointer',
            color: '#fff',
        },
        button: {
            backgroundColor: theme.palette.success.main,
            width: '100%',
            '&:hover': {
                backgroundColor: theme.palette.success.dark,
            },
        },
    }),
)
